<template>
    <Section :title="$t('title.autonomyProposal')">
        <div class="autonomy-proposal-description">
            <p v-html="$t('communityPage.communityGovernance')"></p>
        </div>
        <div class="autinimy-proposal-wrapper">
            <div class="autonomy-proposal-img">
                <img src="../../assets/images/communityPageImg/vote-text.svg" alt="autonomy-proposal-img">
            </div>
            <div class="autonomy-proposal-content">
                <ModeCards :cardsData="dataList" :text="$t('communityPage.viewConditions')"
                    :title="$t('communityPage.conditionalApproval')" />

                    <div class="agt-wrapper">
                        <div class="agt-title">
                            <h4>{{ $t('communityPage.agt') }}</h4>
                        </div>
                        <div class="agt-text">
                            <p>{{ $t('communityPage.fullNameofAGT') }}</p>
                            <p>{{ $t('communityPage.receiveAGTdistributed') }}</p>
                        </div>
                    </div>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent, computed } from 'vue';
import Section from '../../components/common/section.vue';
import ModeCards from '../common/modeCards.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'AutonomyProposalSection',
    components: {
        Section,
        ModeCards
    },
    setup() {

        const { t } = useI18n();

        const cardsData = [
            { number: '1', text: 'automaticDistribution' },
            { number: '2', text: 'dualDistribution' },
        ];


        const dataList = computed(() => {
            return cardsData.map(item => ({
                ...item,
                text: t(`ecosystemPage.${item.text}`),
            }));
        });






        return {
            dataList,

        };
    },
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/communityPage/autonomyProposal.scss';
</style>