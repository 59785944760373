import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store'; 
import '@/assets/style/main.scss';
import i18n from './i18n/config';
import { createPinia } from 'pinia';
// Vuetify
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createMetaManager } from 'vue-meta';
import VueLazyLoad from 'vue3-lazyload';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { chainList as _chainList } from './api/blockchain/wallet/supportedChains';
import VChart from 'vue-echarts';

const globalChainOptions = [{
  label: 'All Network',
  value: null 
}];

_chainList.forEach(item => {
  globalChainOptions.push({
    label: item.short_name,
    value: item.atmId
  });
});

const app = createApp(App);

app.config.globalProperties.$chainOptions = globalChainOptions;
app.config.globalProperties.$getChainName = (id) => {
  const chain = _chainList.find(item => item.atmId == id);
  return chain ? chain.short_name : '- -';
};


const vuetify = createVuetify({
  components,
  directives,
});

const pinia = createPinia();

app.use(ElementPlus);
app.use(i18n);
app.use(router);
app.use(store);
app.use(pinia);
app.use(vuetify);
app.use(VueLazyLoad);
app.use(createMetaManager());
app.component('v-chart', VChart);



app.mount('#app');
