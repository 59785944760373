import { onMounted, ref } from 'vue';

export default function useScrollAnimation(elementSelector, threshold = 0.5) {
  const elementRef = ref(null);

  onMounted(() => {
    const options = {
      root: null,
      threshold: threshold
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show-animate');
        }
        //  else {
        //   entry.target.classList.remove('show-animate');
        // }
      });
    }, options);

    const element = document.querySelector(elementSelector);
    if (element) {
      observer.observe(element);
      elementRef.value = element;
    }
  });

  return {
    elementRef
  };
}