<template>
    <FirstScreen :imageSrc="require('@/assets/images/helpPageImg/main-help.webp')"
        :imageSrcMobile="require('@/assets/images/helpPageImg/main-help-mob.webp')"
        :titleStart="$t('helpPage.wealthOfResources')" :titleSpan="$t('helpPage.conquerTheIntricacies')" />
    <UsageGuide />
    <ATMconnect />

    <Multiverse />
    <FAQ/>
    <Connect />
    <DownloadSection />
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import UsageGuide from '../components/helpPage/usageGuide.vue';
import ATMconnect from '../components/helpPage/atmConnect.vue';
import Multiverse from '../components/helpPage/multiverse.vue';
import Connect from '../components/helpPage/connect.vue';
import FAQ from '../components/helpPage/faq.vue';
export default {
    name: 'HelpPage',
    components: {
        FirstScreen,
        DownloadSection,
        UsageGuide,
        ATMconnect,
        Multiverse,
        Connect,
        FAQ
    },
}
</script>
