 <template>
    <div class="contact-us-form">
        <form @submit.prevent="submitForm" id="contactForm" class="uniForm">
            <div class="form-elem">
                <input  v-model="formData.name" required :placeholder="$t('name')">
            </div>
            <div class="form-elem">
                <input v-model="formData.walletAddress"  :placeholder="$t('walletAddress')">
            </div>
            <div class="form-elem">
                <input  v-model="formData.email" required :placeholder="$t('emailAddress')">
            </div>
            <div class="form-elem">
                <textarea v-model="formData.message" required :placeholder="$t('message')"></textarea>
            </div>

            <button class="active-button" style="align-self: center;">
                <p>{{ $t('submit') }}</p>
            </button>

        </form>
    </div>
</template>
  

<script>
export default {
    data() {
        return {
            formData: {
                name: "",
                walletAddress: "",
                email: "",
                message: "",
            },
        };
    },
    methods: {
        submitForm() {
            if (!this.isValidEmail(this.formData.email)) {
                alert("Please enter a valid email address.");
                return;
            }
            
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${process.env.VUE_APP_SENDGRID_API_KEY}`);

            const raw = JSON.stringify({
                personalizations: [
                    {
                        to: [
                            {
                                email: "info@kodelab.io",
                            },
                        ],
                        subject: "New Contact Form Submission",
                    },
                ],
                from: {
                    email: "charlieone@kodelab.io",
                    name: "info",
                },
                content: [
                    {
                        type: "text/plain",
                        value: `name: ${this.formData.name}, email: ${this.formData.email}, message:${this.formData.message}`,
                    },
                ],
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch("https://svtest-web.kodelab.io/mail", requestOptions)
                .then((response) => response.text())
                .then((result) => {
                  
                    this.formData.name = "";
                    this.formData.walletAddress = "";
                    this.formData.email = "";
                    this.formData.message = "";

                    console.log(result);
              
                    alert("Form submitted successfully!");
                })
                .catch((error) => {
                    console.error("Error submitting form:", error);
                   
                    alert("Error submitting form. Please try again later.");
                });
        },
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        }
    },
};
</script>
