<template>
    <div class="luca-cards">
        <LucaConnectionCard v-for="(cardData, index) in localizedCardDataList" :key="index" :data="cardData" />
    </div>
</template>


<script>
import LucaConnectionCard from './lucaConnectionCard.vue';
import { getOverviewData } from '@/api/api';
import useDarkMode from '@/darkMode';

export default {
    name: 'LucaConnectionSection',
    data() {
        return {
            cardDataList: [
                { key: 'lucaConnections', number: 0, imageUrl: this.getImageUrl('luca-remaining') },
                { key: 'lucaStaked', number: 0, imageUrl: this.getImageUrl('luca-stacked') },
                { key: 'totalSupply', number: 0, imageUrl: this.getImageUrl('total-supply') },
            ],
            myData: null,
        }
    },
    components: {
        LucaConnectionCard
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        localizedCardDataList() {
            return this.cardDataList.map(item => ({
                ...item,
                title: this.$t(`homePage.${item.key}`),
            }));
        },
    },
    methods: {
        async getOverviewData() {
            try {
                const myData = await getOverviewData();

                const data = [
                    { key: 'lucaConnections', number: myData.contractCount, imageUrl: this.getImageUrl('luca-remaining') },
                    { key: 'lucaStaked', number: myData.contractTotalAmount, imageUrl: this.getImageUrl('luca-stacked') },
                    { key: 'totalSupply', number: myData.issuanceTotal, imageUrl: this.getImageUrl('total-supply') },
                ];

                this.cardDataList = data;
                this.updateImageUrls();
            } catch (error) {
                console.error(error);
            }
        },
        getImageUrl(imageUrl) {

            const imageKey = imageUrl.replace(/^images\/homePage\/(.*?)(-dark)?\.svg$/, '$1');
            return this.isDark
                ? `images/homePage/${imageKey}-dark.svg`
                : `images/homePage/${imageKey}.svg`;
        },

        updateImageUrls() {
            this.cardDataList.forEach(item => {
                item.imageUrl = this.getImageUrl(item.imageUrl);
                // console.log(`Updated image URL for key ${item.key}: ${item.imageUrl}`);
            });


        },
    },
    watch: {
        isDark: 'updateImageUrls',
    },
    mounted() {
        this.getOverviewData();

        if (this.isDark) {
            this.updateImageUrls();
        }
    },
}
</script>