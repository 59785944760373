<template>
    <div class="ordinary-container">
      <div class="ordinary-container-img" v-if="imageSrc">
        <img :src="imageSrc" alt="Image" />
      </div>
      <div class="content-container">
        <div class="content-text">
          <p>{{ text }}</p>
        </div>
        <div class="content-title" v-if="title">
          <h4>{{ title }}</h4>
        </div>
        <div class="content-desc" v-if="description">
          <p v-html="description"></p>
        </div>
        <a v-if="showButton" :href="linkUrl" target="_blank" class="origin-btn">
          <p>{{ buttonText }}</p>
        </a>
        <a v-if="showActiveButton" :href="linkUrl" target="_blank" class="active-button">
          <p>{{ buttonText }}</p>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrdinaryContainer',
    props: {
      imageSrc: {
        type: String,
        required: false,
        default: ''
      },
      text: {
        type: String,
        required: false,
        default: ''
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      description: {
        type: String,
        required: false,
        default: ''
      },
      buttonText: {
        type: String,
        required: false,
        default: ''
      },
      linkUrl: {
        type: String,
        required: false,
        default: ''
      },
      showButton: {
        type: Boolean,
        required: false,
        default: false
      },
      showActiveButton: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/style/common/ordinaryContainer.scss';
  </style>
  
 