<template>
    <img :src="computedSrc" :alt="altText" />
  </template>
  
  <script>
  export default {
    props: {
      imageSrc: String,
      imageSrcMobile: String,
      altText: String
    },
    data() {
      return {
        windowWidth: window.innerWidth
      };
    },
    computed: {
      computedSrc() {
        return this.windowWidth > 900 ? this.imageSrc : this.imageSrcMobile;
      }
    },
    created() {
      this.debouncedHandleResize = this.debounce(this.handleResize, 10);
    },
    mounted() {
      window.addEventListener('resize', this.debouncedHandleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.debouncedHandleResize);
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      debounce(func, wait, immediate) {
        let timeout;
        return function() {
          const context = this, args = arguments;
          const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
          };
          const callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) func.apply(context, args);
        };
      },
    }
  }
  </script>
  