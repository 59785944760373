<template>
    <div>
        <a :href="button.href" class="origin-btn" target="_blank">
            <img :src="button.image" alt="dappBadge">
            <p>{{ button.label }}</p>
        </a>
    </div>
</template>

<script>
export default {
    name: 'DappButton',
    props: {
        button: {
            type: Object,
            required: true
        }
    }
};
</script>

