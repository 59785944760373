<template>
    <div class="buttons-wrapper">
      <div class="button-left"  @click="switchElement('left')"  :class="{ 'button-disabled': disableLeft }"
           :disabled="disableLeft" > 
    
        <img v-if="!disableLeft" src="../../assets/images/icons/array-right.svg" alt="Active Image" />
        <img v-else src="../../assets/images/icons/array-right-disabled.svg" alt="Inactive Image" />
      </div>
  
      <div class="button-right" @click="switchElement('right')"  :class="{ 'button-disabled': disableRight }"
           :disabled="disableRight">
        <img v-if="!disableRight" src="../../assets/images/icons/array-right.svg" alt="Active Image" />
        <img v-else src="../../assets/images/icons/array-right-disabled.svg" alt="Inactive Image" />
      </div>
    </div>
  </template>
<script>
export default {
    name: 'controlButtons',
    props: {
      disableLeft: Boolean,
      disableRight: Boolean

    },
    methods: {
        switchElement(direction) {

            this.$emit('switch-element', direction);
        },
    },
}
</script>



