<template>
    <Section :title="$t('title.thirdPartyEcosystem')">
        <div class="third-party-container">

            <div class="third-party-desc">
                <p>{{ $t('ecosystemPage.embracingIntegration') }}</p>
            </div>

            <div class="third-party-cards">

                <div class="third-party-card" v-for="card in dataList" :key="card.title">
                    <div class="third-party-card-img">
                        <img :src="card.imageUrl" alt="card-img">
                    </div>
                    <div class="third-party-card-content">
                        <h3>{{ card.title }}</h3>
                        <p class="third-party-text">{{ card.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';


export default defineComponent({
    name: 'ThirdPartySection',
    components: {
        Section,
    },
    data() {
        return {
            cardsData: [
            { title: 'binance', text: 'utilizingBUSD', imageUrl: 'images/ecologyPage/third-party/binance.svg' },
                { title: 'bitGo', text: 'WBTCpayments', imageUrl: 'images/ecologyPage/third-party/bitgo.svg' },
                { title: 'chainlink', text: 'chainlinkOracle', imageUrl: 'images/ecologyPage/third-party/chainlink.svg' },
                { title: 'ETH', text: 'ETHissuance', imageUrl: 'images/ecologyPage/third-party/eth.svg' },
                { title: 'pancake', text: 'stakingRewards', imageUrl: 'images/ecologyPage/third-party/pancake.svg' }
            ]
        }
    },
    computed: {
        dataList() {
            return this.cardsData.map(item => ({
                ...item,
                title: this.$t(`ecosystemPage.${item.title}`),
                text: this.$t(`ecosystemPage.${item.text}`),
            }));
        },
    }
});
</script>
<style lang="scss">
@import '@/assets/style/ecosystemPage/thirdPartySection.scss';
</style>
