<template>
  <div class="container">
    <!-- <div style="padding-bottom: 20px;">
      <ul style="display: flex; flex-direction: row;">
        <li class="status-category-chip" v-for="(category, index) in categoriesIds" :key="index">
          {{ getCategoryName(category) }}
        </li>
      </ul>
    </div> -->
    <div class="game-overview">
      <div class="game-overview-title">
        <p>{{ 'Overview' }}</p>
      </div>
      <div class="game-overview-description">

        <div id="viewEditor" style="font-family: 'Inter'">
          <div class="quillWrapper">
            <div class="quill-container">
              <div class="ql-editor ql-blank">
                <div v-html="overview"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="viewEditor" style="font-family: 'Inter'">
          <div class="quillWrapper">
            <div class="quill-container">
              <div class="ql-editor ql-blank">
                <div v-html="gamePlay"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="videoUrl" class="video-wrapper">
        <video :src="videoUrl" class="video" controls></video>
      </div>

    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-quill-editor'

export default {
  name: 'GameOverview',
  props: {
    overview: {
      type: String, // Assuming overview is a text
      default: ''
    },
    videoUrl: {
      type: String, // Assuming videoUrl is a URL
      default: ''
    },
    gamePlay: {
      type: String, // Assuming videoUrl is a URL
      default: ''
    },

    categoriesIds: {
      type: Array,
      default: () => []
    },
    isAdmin: {
      type: Boolean, // Assuming videoUrl is a URL
      default: false
    },
    isEdit: {
      type: Boolean, // Assuming videoUrl is a URL
      default: false
    }
  },
  data() {
    return {
      category: [
        { "id": "1", "name": "Action", "order": 1 },
        { "id": "2", "name": "Adventure", "order": 2 },
        { "id": "3", "name": "Arcade", "order": 3 },
        { "id": "4", "name": "Battle Royale", "order": 4 },
        { "id": "5", "name": "Board Games", "order": 5 },
        { "id": "6", "name": "Card Games", "order": 6 },
        { "id": "7", "name": "Casual", "order": 7 },
        { "id": "8", "name": "Fighting", "order": 8 },
        { "id": "9", "name": "Horror", "order": 9 },
        { "id": "10", "name": "Idle Games", "order": 10 },
        { "id": "11", "name": "MMORPG", "order": 11 },
        { "id": "12", "name": "MOBA", "order": 12 },
        { "id": "13", "name": "Music and Rhythm", "order": 13 },
        { "id": "14", "name": "Party Games", "order": 14 },
        { "id": "15", "name": "Platformers", "order": 15 },
        { "id": "16", "name": "Puzzle", "order": 16 },
        { "id": "17", "name": "Racing", "order": 17 },
        { "id": "18", "name": "Roguelike", "order": 18 },
        { "id": "19", "name": "RPG", "order": 19 },
        { "id": "20", "name": "Sandbox", "order": 20 },
        { "id": "21", "name": "Shooter", "order": 21 },
        { "id": "22", "name": "Simulation", "order": 22 },
        { "id": "23", "name": "Sports", "order": 23 },
        { "id": "24", "name": "Stealth", "order": 24 },
        { "id": "25", "name": "Strategy", "order": 25 },
        { "id": "26", "name": "Survival", "order": 26 },
        { "id": "27", "name": "Tower Defense", "order": 27 },
        { "id": "28", "name": "Trivia", "order": 28 },
        { "id": "29", "name": "Tycoon", "order": 29 },
        { "id": "30", "name": "Visual Novels", "order": 30 }
      ],
      // Your existing data properties
      gamePlayToolbar: [

      ],

    };
  },
  components: {
    VueEditor
    // Your existing components
  },
  computed: {
    // Your existing computed properties
  },
  mounted() {
    // Your existing mounted lifecycle hook
  },
  beforeMount() {
    // Your existing beforeMount lifecycle hook
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('resize', this.arrangeCardList);
  },
  methods: {
    // Your existing methods

    getCategoryName(categoriesModel) {

      const category = this.category.find(cat => cat.id === categoriesModel.categoryId);
      return category ? category.name : null;
    }
  }
}
</script>

<style scoped>
/* Remove the border of the editor */


.container {
  width: 100%;
  /* Ensure the container takes the full width */
  padding: 20px 20px;


  .status-category-chip {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;

    margin-right: 10px;
    border-radius: 40px;
    align-content: center;
    background-color: #F0FCF7;
    color: #1C1C1C;
    font-size: 14px;
    margin-bottom: 10px;

    @media (max-width: 550px) {
      font-size: 12px;
    }
  }

  .game-overview {
    width: 100%;
    /* Ensure game-overview takes the full width */
    max-width: 1200px;
    /* Optional max-width to limit the width on larger screens */
    margin: 0 auto;
    /* Center the content horizontally */



    .game-overview-title {
      p {
        color: var(--Primary-colour, #2EA8AF);
        font-family: "Bebas Neue";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      @media (max-width: 550px) {
        p {
          font-size: 28px;
        }
      }
    }

    .game-overview-description {

      p {
        color: #000;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-wrap: wrap;
      }

      .game-overview-subtitle {
        color: #000;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-wrap: wrap;
        padding-bottom: 10px;
      }

    }


    .video-wrapper {
      width: 100%;
      /* Ensure video-wrapper takes the full width */
      height: 450px;
      /* Fixed height for the video */
      overflow: hidden;
      border-radius: 15px;
      /* Rounded corners */
      margin-top: 20px;

      video {
        pointer-events: auto !important;
        width: 100%;
        max-width: none !important;

        /* Ensure video stretches to the full width of the wrapper */
        height: 100%;
        /* Ensure video stretches to the full height of the wrapper */
        object-fit: cover;
        /* Cover the wrapper area */
        border-radius: 15px;
        /* Match the rounded corners of the wrapper */
      }
    }
  }
}

/* Optional padding for better layout on smaller screens */
@media (max-width: 550px) {
  .container {
    padding: 10px 0px;
  }

  .ql-editor {
    padding: 0 0 5px;
  }

  .game-overview {
    padding-right: 0px;
  }

}
</style>
