<template>
    <div>
        <FirstScreen />
        <LucaConnectionSection />
        <NewsSection />
        <GalaxySection />
        <FeaturesSection :sectionTitle="$t('features')" :cardDataList="featuresCards" :initialIsListAtStart="true" :initialIsListAtEnd="false"/>


        <CommunitySection />
        <EcosystemSection />
        <RoadmapSection />
        <ContactSection />
        <DownloadSection />
    </div>
</template>
<script>
import FirstScreen from '../components/homePage/firstScreen.vue';
import LucaConnectionSection from '../components/homePage/lucaConnectionSec.vue';
import NewsSection from '../components/homePage/newsSection.vue';
import GalaxySection from '../components/homePage/galaxySection.vue';
import FeaturesSection from '../components/homePage/featuresSection.vue';
import CommunitySection from '../components/homePage/communitySection.vue';
import EcosystemSection from '../components/homePage/ecosystemSection.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import ContactSection from '../components/homePage/contactSection.vue';
import RoadmapSection from '../components/homePage/roadmapSection.vue';



export default {
    name: 'HomePage',

    components: {
        FirstScreen,
        LucaConnectionSection,
        NewsSection,
        GalaxySection,
        FeaturesSection,
        CommunitySection,
        EcosystemSection,
        DownloadSection,
        ContactSection,
        RoadmapSection
    },
    data() {
        return {
            featuresCards: [
                { key: 'digitalSelf', imageUrl: 'images/homePage/avatar.png', gifUrl: 'images/homePage/avatar-card.mp4' },
                { key: 'earnRewards', imageUrl: 'images/homePage/revards.png', gifUrl: 'images/homePage/luca-card.mp4', backgroundColor: 'linear-gradient(0deg, #FFBF19 5.65%, #FFDB7F 31.58%, #FFF7E0 59.56%, #FFF7E0 77.74%)' },
                { key: 'connectFriend', imageUrl: 'images/homePage/connect.svg', gifUrl: 'images/homePage/connect-card.mp4', backgroundColor: 'linear-gradient(0deg, #1EB5BF 5.65%, #81D6DB 31.58%, #E2F6F7 59.56%, #E8F8F9 77.74%)' },
                { key: 'aiSupport', imageUrl: 'images/homePage/ai.svg', gifUrl: 'images/homePage/pandora-card.mp4', backgroundColor: 'linear-gradient(0deg, #7E50FF 5.65%, #C2ACFF 31.58%, #EBE3FF 59.56%, #F1ECFF 77.74%)' },
                { key: 'messagingPlatform', imageUrl: 'images/homePage/messgingPlatform.png', gifUrl: 'images/homePage/message-card.mp4', backgroundColor: 'linear-gradient(0deg, #1EB5BF 5.65%, #81D6DB 31.58%, #E2F6F7 59.56%, #E8F8F9 77.74%)' },
                { key: 'socialExperience', imageUrl: 'images/homePage/social.png', gifUrl: 'images/homePage/social-card.mp4', backgroundColor: 'linear-gradient(0deg, #1EB5BF 5.65%, #81D6DB 31.58%, #E2F6F7 59.56%, #E8F8F9 77.74%)' },
            ]
        };
    }


}
</script>
