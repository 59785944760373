<template>
    <div class="firstscreen-container">
        <div class="firstscreen-image">
            <img :src="currentImageSrc" alt="homePageImg" loading="lazy">
        </div>
        <div class="firstscreen-content">
            <h1>{{ titleStart }}<span>{{ titleSpan }}</span>{{ titleEnd }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Firstscreen',
    props: {
        imageSrc: {
            type: String,
            required: false,
            default: ''
        },
        imageSrcMobile: {
            type: String,
            required: false,
            default: ''
        },
        titleStart: {
            type: String,
            required: false,
            default: ''
        },
        titleSpan: {
            type: String,
            required: false,
            default: ''
        },
        titleEnd: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            currentImageSrc: this.imageSrc
        };
    },
    mounted() {
        this.updateImageSrc();
        window.addEventListener('resize', this.updateImageSrc);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateImageSrc);
    },
    methods: {
        updateImageSrc() {
            if (window.matchMedia("(max-width: 550px)").matches) {
                this.currentImageSrc = this.imageSrcMobile;
            } else {
                this.currentImageSrc = this.imageSrc;
            }
        }
    }
}
</script>


<style>
@import '@/assets/style/common/firstscreenSection.scss';
</style>
