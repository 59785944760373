<template>
    <div class="news-page">
        <FirstScreen :imageSrc="require('@/assets/images/newsPageImg/main-news.webp')"
            :imageSrcMobile="require('@/assets/images/newsPageImg/main-news-mob.webp')"
            :titleStart="$t('newsPage.stayAhead')" :titleSpan="$t('newsPage.innovationNews')" />
            <NewsMainSection/>
        <DownloadSection />
    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import NewsMainSection from '../components/newsPage/newsMainSection.vue'
import DownloadSection from '../components/homePage/downloadSection.vue';
export default {
    name: 'NewsPage',
    data() { return {} },
    components: {
        FirstScreen,
        NewsMainSection,
        DownloadSection
    },
}
</script>