<template>
   
        <Section :title="$t('title.messagingPlatform')">
            <div class="msg-platform-section">
            <OrdinaryContainer imageSrc="images/communityPage/msg-platfrom.webp" :text="$t('comingSoon')"
                :title="$t('communityPage.seamlessInteractions')"
                :description="$t('communityPage.engageDynamically')" /></div>
        </Section>
  
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'

export default defineComponent({
    name: 'MessagingPlatformsSection',
    components: {
        Section,
        OrdinaryContainer
    }
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/communityPage/messagingPlatforms.scss';
</style>