<template>
    <Section :title="$t('title.theTechnology')" class="technology-section">
        <OrdinaryContainer 
            imageSrc="images/technologyPage/tokens.svg"
            :title="$t('technologyPage.systemIntegration')"
            :description="$t('technologyPage.systemIntegrationDesc')"
            :buttonText="$t('learnMore')"
            :linkUrl="'https://en.wikipedia.org/wiki/PageRank'"
            :showButton="true" 
        />
        <div class="technology-card-container">
            <div class="text-content">
                <div class="content-title">
                    <h3>{{ $t('technologyPage.smartContracts') }}</h3>
                </div>
                <div class="content-desc">
                    <p v-html="$t('technologyPage.smartContractsDesc')"></p>
                </div>
            </div>
            <div class="cards-buttons">
                <ControlButtons
                    v-show="mobile"
                    :disableLeft="isListAtStart"
                    :disableRight="isListAtEnd"
                    @switch-element="switchElement"
                />
            </div>
            <div class="cards-outer" ref="technologyCars" @scroll="checkListPosition">
                <div class="technology-cars">
                    <div class="technology-card" v-for="card in dataList" :key="card.title">
                        <div class="technology-card-img">
                            <img :src="card.imageUrl" alt="card-img">
                        </div>
                        <div class="technology-card-content">
                            <h3>{{ card.title }}</h3>
                            <p class="technology-text">{{ card.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <a href="https://github.com/ATM-Developer/atm-contract" target="_blank" class="origin-btn" style="margin-bottom: 80px;">
                <p>{{ $t('technologyPage.aboutOpenSource') }}</p>
            </a>
        </div>
         <!-- <OrdinaryContainer imageSrc="images/technologyPage/messagingplatform.svg"
            :title="$t('technologyPage.messagingPlatform')" :description="$t('technologyPage.messagingPlatformDesc')"
            :showButton="false" style="flex-direction: row-reverse;" /> -->
        <!-- 
        <OrdinaryContainer imageSrc="images/technologyPage/socialplatform.svg"
            :title="$t('technologyPage.socialPlatform')" :description="$t('technologyPage.socialPlatformDesc')"
            :showButton="false" /> -->
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue';
import ControlButtons from '../homePage/controlButtons.vue';
import useDarkMode from '@/darkMode';

export default defineComponent({
    name: 'CommunitySection',
    components: {
        OrdinaryContainer,
        Section,
        ControlButtons,
    },
    data() {
        return {
            isListAtStart: true,
            isListAtEnd: false,
            mobile: null,
            cardsData: [
                { title: 'LUCAContract', text: 'LUCAContractDesc', imageUrl: 'images/technologyPage/smartContractIcons/contract.svg' },
                { title: 'factoryContract', text: 'factoryContractDesc', imageUrl: 'images/technologyPage/smartContractIcons/factory.svg' },
                { title: 'stakerContract', text: 'stakerContractDesc', imageUrl: 'images/technologyPage/smartContractIcons/stake-contract.svg' },
                { title: 'incentiveDistributionContract', text: 'incentiveDistributionContractDesc', imageUrl: 'images/technologyPage/smartContractIcons/incentive.svg' },
                { title: 'investmentLUCAcontract', text: 'investmentLUCAcontractDesc', imageUrl: 'images/technologyPage/smartContractIcons/investment.svg' },
                { title: 'LUCApromotion', text: 'LUCApromotionDesc', imageUrl: 'images/technologyPage/smartContractIcons/luca-promotion.svg' },
                { title: 'crossChain', text: 'crossChainDesc', imageUrl: 'images/technologyPage/smartContractIcons/cross-chain.svg' },
                { title: 'wormholeContract', text: 'wormholeContractDesc', imageUrl: 'images/technologyPage/smartContractIcons/wormhole.svg' }
            ]
        };
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        dataList() {
            return this.cardsData.map(item => ({
                ...item,
                title: this.$t(`technologyPage.${item.title}`),
                text: this.$t(`technologyPage.${item.text}`),
            }));
        },
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    methods: {
        checkScreen() {
            this.windowWidth = window.innerWidth;
            this.mobile = this.windowWidth <= 1200;
        },
        checkListPosition() {
            const technologyCars = this.$refs.technologyCars;
            this.isListAtStart = technologyCars.scrollLeft === 0;
            this.isListAtEnd = technologyCars.scrollLeft + technologyCars.clientWidth >= technologyCars.scrollWidth;
        },
        switchElement(direction) {
            if (direction === 'left') {
                this.scrollLeft();
            } else {
                this.scrollRight();
            }
        },
        scrollLeft() {
            const technologyCars = this.$refs.technologyCars;
            technologyCars.scrollBy({
                left: -1000,
                behavior: 'smooth',
            });
            setTimeout(this.checkListPosition, 300); 
        },
        scrollRight() {
            const technologyCars = this.$refs.technologyCars;
            technologyCars.scrollBy({
                left: 1000,
                behavior: 'smooth',
            });
            setTimeout(this.checkListPosition, 300); 
        },
        getImageUrl(imageUrl) {
            const imageKey = imageUrl.replace(/^images\/technologyPage\/smartContractIcons\/(.*?)(-dark)?\.svg$/, '$1');
            return this.isDark
                ? `images/technologyPage/smartContractIcons/${imageKey}-dark.svg`
                : `images/technologyPage/smartContractIcons/${imageKey}.svg`;
        },
        updateImageUrls() {
            this.cardsData.forEach(item => {
                item.imageUrl = this.getImageUrl(item.imageUrl);
            });
        },
    },
    watch: {
        isDark: 'updateImageUrls',
    },
    mounted() {
        if (this.isDark) {
            this.updateImageUrls();
        }
        this.checkListPosition();
    },
});
</script>

<style scoped>
@import '@/assets/style/technologyPage/technologySection.scss';
</style>
