<template>
    <Section :title="$t('title.avatarsAI')" class="avatars-section">
        <div class="brain-image">
            <img :src=imageSource alt="brainImg" loading="lazy">
        </div>
        <div class="avatars-section-description">
            <p v-html="$t('communityPage.transformingInteractions')"></p>
        </div>

        <OrdinaryContainer imageSrc="images/communityPage/lucy-chatgif.gif" :title="$t('communityPage.hiLucy')"
            :description="$t('communityPage.meetLucy')" :buttonText="$t('communityPage.chatLUCY')"
            :linkUrl="'https://atm.network/v1/#/shareLink/lucy?sharekey=GHBXZbMJN3lL-6cJB9CquxN2p0nRu1W7spR--ozp4H4'"
            :showActiveButton="true" />

        <VideoSection />
        <div class="avatars-tabs">
            <TabSlider :slides="localizedTabs" />
        </div>
    </Section>

</template>

<script>
import { defineComponent } from 'vue';
import VideoSection from './videoSection.vue'
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'
import TabSlider from '../../components/common/tabSlider.vue';
import useDarkMode from '@/darkMode';
export default defineComponent({
    name: 'AvatarsSection',
    components: {
        Section,
        OrdinaryContainer,
        TabSlider,
        VideoSection
    },
    data() {
        return {
            tabs: [
                {
                    id: 0,
                    buttonText: 'about',
                    title: 'whatAvatar',
                    description: 'consciousnessExtension',
                    video: 'images/communityPage/avatars.mp4',
                    link: 'https://example.com/tab1',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 1,
                    buttonText: 'create',
                    title: 'craftAvatar',
                    description: 'creationEmpowered',
                    video: 'images/communityPage/creationAvatar.mp4',
                    link: 'https://example.com/tab2',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 2,
                    buttonText: 'train',
                    title: 'trainAvatar',
                    description: 'dynamicEvolution',
                    video: 'images/communityPage/trainAvatar.mp4',
                    link: 'https://example.com/tab3',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 3,
                    buttonText: 'share',
                    title: 'shareAvatar',
                    description: 'avatarNetworking',
                    video: 'images/communityPage/shareAvatar.mp4',
                    link: 'https://example.com/tab1',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
            ],
        };
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        localizedTabs() {
            return this.tabs.map(tab => ({
                ...tab,
                buttonText: this.$t(tab.buttonText),
                title: this.$t(`communityPage.${tab.title}`),
                description: this.$t(`communityPage.${tab.description}`),
                walletAdress: this.$t(`${tab.walletAdress}`),
            }));
        },
        imageSource() {
            return this.isDark
                ? '/images/communityPage/brain-dark.svg'
                : '/images/communityPage/brain-img.svg';
        }
    }

});
</script>
<style lang="scss" scoped>
@import '@/assets/style/communityPage/avatarsSection.scss';
</style>
