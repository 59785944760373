<template>
    <div class="features-section">
        <div class="features-section-top">
            <div class="title-buttons-wrapper">
                <div class="section-title">
                    {{ sectionTitle }}
                </div>
                <ControlButtons @switch-element="switchElement" :disableLeft="isListAtStart"
                    :disableRight="isListAtEnd" />
            </div>


        </div>

        <div class="content-wrapper">
            <div v-if="optionalText" class="medium-body-text">
                    <p>{{ optionalText }}</p>
                </div>
            <div class="cards-container" ref="cardDataList">
                <FeatureCard v-for="(cardData, index) in localizedCardDataList" :key="index" :data="cardData"
                    :style="{ transform: cardData.transform }" />
            </div>
        </div>
    </div>
</template>

<script>
import ControlButtons from './controlButtons.vue';
import FeatureCard from './featureCard.vue';

export default {
    name: 'FeaturesSection',
    props: {
        cardDataList: {
            type: Array,
            default: () => []
        },
        initialIsListAtStart: {
            type: Boolean,
            default: true
        },
        initialIsListAtEnd: {
            type: Boolean,
            default: false
        },
        optionalText: {
            type: String,
            default: null
        },
        sectionTitle: {
            type: String,
            default: null
        },
        localizationBase: {
        type: String,
        default: 'homePage'
    }
    },
    data() {
        return {
            isListAtStart: this.initialIsListAtStart,
            isListAtEnd: this.initialIsListAtEnd,
            currentCardIndex: 0,
            cardWidth: 330
        };
    },
    components: {
        ControlButtons,
        FeatureCard
    },
    computed: {
    localizedCardDataList() {
        return this.cardDataList.map(item => ({
            ...item,
            title: this.$t(`${this.localizationBase}.${item.key}`),
        }));
    },
},

    methods: {
        setCard(index) {
            if (!this.isTransitioning) {
                this.isTransitioning = true;
                this.currentCardIndex = index;
                this.arrangeCardList();
                this.$nextTick(() => {
                    this.isTransitioning = false;
                });
            }
        },
        arrangeCardList() {
            this.cardWidth = window.innerWidth < 900 ? 330 : 470;
            this.cardDataList.forEach((cardData, index) => {
                cardData.transform = `translateX(${(index - this.currentCardIndex) * this.cardWidth}px)`;
            });
        },
        switchElement(direction) {
            if (direction === 'left') {
                this.scrollLeft();
            } else {
                this.scrollRight();
            }
        },
        scrollLeft() {
            const cardDataList = this.$refs.cardDataList;
            cardDataList.scrollBy({
                left: -500,
                behavior: 'smooth'
            });
        },
        scrollRight() {
            const cardDataList = this.$refs.cardDataList;
            cardDataList.scrollBy({
                left: 500,
                behavior: 'smooth'
            });
        },
        checkListPosition() {
            const cardDataList = this.$refs.cardDataList;
            this.isListAtStart = cardDataList.scrollLeft === 0;
            this.isListAtEnd = cardDataList.scrollLeft + cardDataList.clientWidth >= cardDataList.scrollWidth;
        },
    },
    mounted() {
        this.setCard(0);
        window.addEventListener('resize', this.arrangeCardList);
        const cardDataList = this.$refs.cardDataList;
        cardDataList.addEventListener('scroll', this.checkListPosition);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.arrangeCardList);
        const cardDataList = this.$refs.cardDataList;
        cardDataList.removeEventListener('scroll', this.checkListPosition);
    },
};
</script>
