<template>
    <div class="container">
        <div class="apply-container">
            <div class="apply-container-img">
                <img :src=imageSource alt="brainImg" loading="lazy">
            </div>
            <div class="apply-text">
                {{ $t('ecosystemPage.thirdParty') }}<span class="apply-span"> {{ $t('ecosystemPage.joinTheATM') }}</span>{{ $t('ecosystemPage.utilizeOurATM') }}
            </div>
            <a href="https://atm.network/v1/#/joinATM" target="_blank" class="active-button"><p>{{ $t('apply') }}</p></a>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';
import useDarkMode from '@/darkMode';

export default defineComponent({
    name: 'ApplySection',
    components: {
        Section,
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        imageSource() {
            return this.isDark
            ? 'images/ecologyPage/logo-bg-dark.webp'
            : 'images/ecologyPage/logo-bg.webp';
        }
    }
});
</script>
<style>
@import '@/assets/style/ecosystemPage/applySection.scss';
</style>
