<template>
    <div class="social-platfom-section">
        <Section :title="$t('title.socialPlatform')">
            <OrdinaryContainer style="flex-direction: row-reverse;" imageSrc="images/communityPage/social-platfrom.webp"
                :text="$t('comingSoon')" :title="$t('communityPage.discoverConnectEngage')"
                :description="$t('communityPage.connectInformatively')" />
        </Section>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'

export default defineComponent({
    name: 'SocialPlatformSection',
    components: {
        Section,
        OrdinaryContainer
    }
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/communityPage/socialPlatform.scss';
</style>