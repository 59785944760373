<template>
  <div class="container">
    <div class="game-overview">
       <!-- <div class="slider" v-if="images.length">
        <div class="slider-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
          <div v-for="(image, index) in images" :key="index" class="slider-slide"
            :class="{ active: index === currentIndex }"  >
            <div class="image-container">
              <div class="image-wrapper left prev-image">
                <img :src="getPreviousImage(onSlideDirectionClick(1))" :alt="'Image ' + (index - 1)" class="prev-image"   @click="onSlideClick($event)"/>
              </div>
              <div class="image-wrapper center">
                <img :src="image.link" :alt="'Image ' + (index + 1)" class="main-image"  @click="onSlideClick($event)" />
              </div>
              <div class="image-wrapper right next-image">
                <img :src="getNextImage(onSlideDirectionClick(-1))" :alt="'Image ' + (index + 2)" class="next-image"  @click="onSlideClick($event)"/>
              </div>
            </div>

          </div>
        </div>
      </div>
      <p v-else>No images available for this game.</p> -->
      <!-- <carousel-3d>
    <slide v-for="(slide, i) in images" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.link">
        </template>
    </slide>
</carousel-3d> -->
      <carousel-3d :perspective="35" :space="300" :display="4">
        <slide v-for="(slide, index) in slides" :key="index" :index="index" class="image-container">
          <img :src="slide.link" :alt="'Image ' + (index + 1)" class="main-image" />
        </slide>
      </carousel-3d>

    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from './../../../lib/carousel-3d/index';

export default {
  name: "GameImageSlider",
  props: {
    slides: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      images: [],
      autoSlideInterval: null,
      // slides: [
      //   { link: 'https://monkeyspace.blob.core.windows.net/cssg/CSSG333f9e7bb574eea47694f7723eac507a.png' },
      //   { link: 'https://monkeyspace.blob.core.windows.net/cssg/CSSG08e3e595cf0be3fc8517939ddd1f0eb1.png' },
      //   { link: 'https://monkeyspace.blob.core.windows.net/cssg/CSSG08e3e595cf0be3fc8517939ddd1f0eb1.png' }
      // ]
    };
  },
  components: {
    Carousel3d,
    Slide
  },
  mounted() {
    const initiateDetail = JSON.parse(
      localStorage.getItem("selectedGameProposal")
    );
    if (initiateDetail && initiateDetail.gamesMediaModelList) {
      this.images = initiateDetail.gamesMediaModelList.filter(media => media.type === 1);
      // console.log(this.images)
      const repeatCount = 1;

      const repeatedArray = Array(repeatCount).fill(this.images).flat();
      this.images = repeatedArray;
      // console.log(this.images)

      if (this.images.length) {
        // this.startAutDoSlide(); // Start the auto-slide loop
      }
    }
  },
  beforeDestroy() {
    this.stopAutoSlide(); // Stop the auto-slide loop when the component is destroyed
  },
  methods: {
    getNextImage(index) {
      if (index == this.images.length) {
        console.log(" max out")
        return this.images[0].link;
      }
      if (index < 0) {
        return this.images[this.images.length - 1].link;
      }
      return this.images[index].link;


    },
    getPreviousImage(index) {
      console.log(" getPreviousImage", index)
      console.log(" ---XX--");
      if (index == this.images.length) {
        return this.images[0].link;
      }
      if (index < 0) {
        return this.images[this.images.length - 1].link;
      }
      return this.images[index].link;

    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevSlide() {
      this.currentIndex =
        this.currentIndex === 0
          ? this.images.length - 1
          : this.currentIndex - 1;
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.nextSlide();
      }, 5000); // Change slide every 3 seconds
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    },
    onSlideClick(event) {
      const slideWidth = event.currentTarget.clientWidth;
      const clickPosition = event.offsetX;
      if (clickPosition < slideWidth / 2) {
        this.prevSlide();
      } else {
        this.nextSlide();
      }
    },
    onSlideDirectionClick(direction) {
      if (direction < 0) {
        // Move left by 2
        return this.currentIndex === 0 || this.currentIndex === 1
          ? this.currentIndex - 1 // Wrap to the last or second-to-last image
          : this.currentIndex - 1; // Move two images left
      } else if (direction >= 0) {
        // Move right by 2
        return (this.currentIndex + 1) >= this.images.length
          ? (this.currentIndex + 1) // Wrap to the first image if exceeding array length
          : this.currentIndex + 1; // Move two images right
      }
    }
  },
};

</script>

<style scoped>
.image-container {
  width: 100%;
  text-align: center;
  border-radius: 30px;
}

.main-image {
  max-width: 100%;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  padding: 20px;
}

.game-overview {
  width: 100%;
}

/* Base styles for slider */
.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.slider-wrapper {
  display: flex;
}

.slider-slide {
  position: relative;
  min-width: 100%;
  height: 300px;
  /* Set a default height for desktop */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {}

.image-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  z-index: 1;
  /* Ensure it’s behind the center image */
}

.left {
  left: 200px;
  z-index: 1;
}

.right {
  right: 200px;
  z-index: 1;
}

.prev-image,
.next-image {
  width: 200px;
  /* Adjust size */
  height: 200px;
  /* Adjust size */
  opacity: 0.7;
  /* Slightly visible */
  border-radius: 10px;
  object-fit: cover;
  border-radius: 30px;
  overflow-y: hidden;
  /* Maintain aspect ratio */
}

.center {
  z-index: 2;
  /* Ensure it’s on top */
}

.main-image {
  width: 600px;
  /* Adjust size for desktop */

  /* Adjust size for desktop */
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Mobile view adjustments */
@media (max-width: 600px) {


  .slider-wrapper {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }

  .slider-slide {
    height: auto;
    /* Adjust height automatically */
    width: 100%;
    flex-direction: column;
    /* Stack images vertically */
  }

  .main-image {
    /* height: auto; */
    /* Adjust height automatically */
    width: 100%;
    flex-direction: column;
  }

  .image-wrapper {
    position: static;
    /* Reset position for mobile */
    transform: none;
    /* Reset transform for mobile */
    margin: 0;
    /* Reset margins for mobile */
  }


  .prev-image,
  .next-image .left,
  .right {
    display: none;
    /* Hide side images on mobile */
  }
}

@media (max-width: 550px) {
  .container {
    padding: 10px 10px 0;
  }
}
</style>