<template>


        <Section :title="$t('community')"  class="community-section">
            <TextComponent :topText="$t('homePage.unleashInfluence')" :mainText="$t('homePage.voiceHoldsPower')"
                :mainSpan="$t('homePage.becomePartCommunity')" :mainTail="$t('homePage.leadTheWay')"
                :bottomText="$t('homePage.stepIntoWorldInfluence')" />
        </Section>
 
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';
import TextComponent from '../../components/common/textComponent.vue';

export default defineComponent({
    name: 'CommunitySection',
    components: {
        TextComponent,
        Section,
    },

    computed: {
        localizedTabs() {
            return this.tabs.map(tab => ({
                ...tab,
                buttonText: this.$t(tab.buttonText),
                title: this.$t(`prAlgorithm.${tab.title}`),
                description: this.$t(`prAlgorithm.${tab.description}`),
                walletAdress: this.$t(`${tab.walletAdress}`),
            }));
        },
    },
});
</script>

<style>
@import '@/assets/style/homePageStyle/communitySection.scss';
</style>