<template>
    <Section :title="$t('travel')">
        <div class="ecosystem-container">
            <div class="ecosystem-image">
                <img src="../../assets/images/ecosystemPageImg/luca-travel.webp" alt="imageEcosystem">
            </div>
            <div class="content-ecosystem">
                <div class="ecosystem-text-content">
                    <p>{{ $t('ecosystemPage.travelRevolutionized') }}</p>
                    <p>{{ $t('ecosystemPage.seamlessTravel') }}</p>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';


export default defineComponent({
    name: 'TravelSection',
    components: {
        Section,
    }
});
</script>
<style>
@import '@/assets/style/ecosystemPage/gamesFundSection.scss';
</style>
