<template>
    <Section :title="$t('title.communityFoundation')">
        <div class="foundation-section">
        <OrdinaryContainer style="flex-direction: row-reverse;" :imageSrc= imageSource
             :description="$t('communityPage.autonomousGovernance')"
            />
            </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'
import useDarkMode from '@/darkMode';
export default defineComponent({
    name: 'CommunityFoundationSection',
    components: {
        Section,
        OrdinaryContainer
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed:{
        imageSource() {
          return this.isDark
            ? 'images/communityPage/arrow-up-dark.svg'
            : 'images/communityPage/arrow-up.svg';
        }
    }
});
</script>