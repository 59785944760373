<template>
  <div class="applicationProposal">

    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
      <div class="title">{{ initialDetail.title }}</div>
      <!-- <div :class="statusChipClass">{{ statusText() }}</div> -->
    </div>
    <div>

      <GameImageSlider :slides="initialDetail.gamesMediaModelList"/>
      <GameCategoryView :categoriesIds="initialDetail.gameCategoriesModelList" />

      <GameOverview :overview="initialDetail.overview" :gamePlay="initialDetail.gameplay" :videoUrl="getVideoUrl()" />
      <!-- <GameMilestonesView :milestones="initialDetail.milestones" /> -->
      <GameContactDetailsViewSection :contactDetails="initialDetail.contactDetails" />
      <GameNotes :notes="initialDetail.notes" v-if="initialDetail.status == '2' && checkIsAdmin()" />
    </div>
  </div>
</template>

<script>

import GameOverview from './gameProposalComponents/gameOverview.vue';
import GameMilestonesView from './gameProposalComponents/gameMilestonesView.vue';
import GameImageSlider from './gameProposalComponents/gameImageSlider.vue';
import GameNotes from "./gameProposalComponents/gameNotes.vue";

import GameCategoryView from "./gameProposalComponents/gameCategoryView.vue";

import GameContactDetailsViewSection from './gameProposalComponents/gameContactDetailsViewSection.vue';

export default {
  name: "applicationProposal",
  data() {
    return {
      initialDetail: ''
    }
  },
  created() {
    this.getInitialDetails()
  },
  components: {
    GameOverview,
    GameMilestonesView,
    GameImageSlider,
    GameNotes,
    GameContactDetailsViewSection,
    GameCategoryView
  },
  computed: {
    isAdmin() {
      return this.checkIsAdmin();
    },
    statusChipClass() {
      switch (this.initialDetail.status) {
        case 0:
          return 'status-chip status-default';
        case 1:
          return 'status-chip status-in-progress';
        case 2:
          return 'status-chip status-warning';
        case 3:
          return 'status-chip status-success';
        case 4:
          return 'status-chip status-error';
        case 5:
          return 'status-chip status-in-contribution';
        case 6:
          return 'status-chip status-release';
        default:
          return 'status-chip status-default';
      }
    }
  },
  methods: {
    checkIsAdmin() {
      let agfAdminWallets = process.env.VUE_APP_AGF_ADMIN_TEST_WALLET;
      if (agfAdminWallets && this.$store.state.user && this.$store.state.user.walletAddress) {
        const userWalletAddress = this.$store.state.user.walletAddress;
        if (userWalletAddress && agfAdminWallets.includes(userWalletAddress)) {
          return true;
        }
      }
      return false;
    },

    getVideoUrl() {
      let videoArray = this.initialDetail.gamesMediaModelList.filter(media => media.type === 2);
      return videoArray.length > 0 ? videoArray[0].link : null;
    },

    handleBackClick() {
      localStorage.removeItem("selectedGameProposal");
      this.$router.push('/user/userCenter?index=4');
    },

    statusText() {
      const type = this.initialDetail.status;
      const listOfStatus = [
        { id: '0', name: 'Inactive' },
        { id: '1', name: 'Under review' },
        { id: '2', name: 'Changes required' },
        { id: '3', name: 'Approved' },
        { id: '4', name: 'Rejected' },
        { id: '5', name: 'Contribution stage' },
        { id: '6', name: 'Released' }
      ];
      if (this.initialDetail.typeOfProposal == "3") {
        const status = listOfStatus.find(statusItem => statusItem.id === type.toString());
        return status ? status.name : 'Unknown Status';
      } else {
        switch (type) {
          case 1: return this.$t('communityProposal2.text3');
          case 2: return this.$t('communityProposal2.text4');
          case 3: return this.$t('communityProposal2.text5');
          case 4: return this.$t('communityProposal2.text6');
          case 5: return this.$t('communityProposal2.text7');
          case 6: return this.$t('communityProposal2.text8');
          default: return 'Unknown Status';
        }
      }
    },

    getInitialDetails() {
      this.initialDetail = JSON.parse(localStorage.getItem("selectedGameProposal"));
    }
  }
}
</script>


<style type="less"  scoped>
.status-chip {
  display: flex;
  padding: 6px 15px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
}

.status-success {
  background-color: #D4FCDE;
}


.status-release {
  background-color: #C6FBEB;
}

.status-in-progress {
  background-color: #FFECDA;
  ;
}

.status-in-contribution {
  background-color: #E0EEFC;
}

.status-warning {
  background-color: #EFE5FF;
}

.status-error {
  background-color: #FDD5D5;
  ;
}

.status-default {
  background-color: #EAEAEA;
}

.applicationProposal {



  .back {
    font-size: 18px;
    color: #2ea8af;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100px;
    cursor: pointer;

    .icon {
      display: inline-block;
      width: 6px;
      height: 10px;
      background-size: contain;
      margin-right: 16px;
    }
  }

  .title {
    color: #01CC90;
    font-family: Inter;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    text-transform: uppercase;
    @media (max-width: 500px) {
      line-height: normal;
      font-size: 24px;
    }
  }

  .main {

    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 400px) {
      display: block;
      width: 100%;
    }

    .form {
      width: 760px;

      @media (max-width: 400px) {
        width: 100%;
      }

      .time {
        color: #2ea8af;
        font-size: 16px;
        padding-bottom: 20px;
      }

      .tit {
        font-size: 14px;
        line-height: 1.5;
        color: #222 !important;
        padding-bottom: 60px;

        @media (max-width: 400px) {
          padding-bottom: 30px;
        }
      }

      .info-tit {
        font-size: 14px;
        line-height: 14px;
        color: #000;
        padding-left: 6px;
        border-left: 1px solid #2ea8af;
      }

      .input-box {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        color: #000;
        text-align: left;
        display: block;
        border-bottom: 1px solid #e8f1f2;
        margin: 10px auto 40px;

        input {
          display: block;
          width: 100%;
          height: 100%;

          &.active {
            border-bottom: 1px solid #2ea8af;
          }
        }
      }

      .tit-two {
        font-size: 16px;
        color: #222;
        padding: 10px 0 50px;
        font-family: 'Artifakt_Bold';

        @media (max-width: 400px) {
          padding-bottom: 30px;
        }

        .em-text {
          font-family: 'Artifakt';
          font-weight: 300;
          color: #b3b3b3;
        }
      }

      .image-list {
        width: 100%;

        .item-li {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          margin-bottom: 20px;

          .cursor {
            cursor: pointer;
          }

          .img {
            width: 60px;
            height: 100%;
            border-radius: 6px;
            position: relative;
            background-color: #fbfbfb;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .del {
              position: absolute;
              right: -10px;
              top: -10px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background-size: contain;
            }

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
              border-radius: 6px;
            }
          }

          .upload_view_img {
            font-size: 14px;
            color: #000;
            width: 680px;
            padding: 0 20px;
            background-color: #fbfbfb;
            text-align: left;
            margin-left: 20px;
            border-radius: 4px;
            position: relative;
            height: 60px;

            @media (max-width: 400px) {
              width: auto;
              flex: 1;
            }

            &::before {
              z-index: 100;
              content: '';
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              width: 8px;
              height: 100%;
              background-color: #CCC;
              border-radius: 4px;
            }

            &.a-m {
              &::before {
                display: none;
              }
            }

            &.active {
              /*background-color: #bbf6f9;*/
              position: relative;

              .my-checked {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: #BBF6F9;
              }

              &:after {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                content: '';
                width: 14px;
                height: 11px;
                background-size: contain;
              }
            }

            &.active-m {
              z-index: 200;
              background-color: rgba(187, 246, 249, .3);
            }

            &.active-2 {
              &::before {
                background-color: #2EA8AF;
              }
            }

            .f-m {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: space-between;
              z-index: 10;
              left: 0;
              width: 100%;
              height: 100%;
              padding: 0 50px 0 20px;
            }

            .bg {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              background-color: #ebebeb;

              &.isMe {
                background-color: #bbf6f9;
              }
            }
          }

          .em_img {
            width: 466px;
            height: 100%;
            box-sizing: content-box;
            box-shadow: 0 1px 0px 0 #2ea8af;

            input {
              width: 100%;
              display: inline-block;
              height: 100%;
              font-size: 14px;
              color: #000;
            }
          }

          .del-icon {
            width: 48px;
            height: 48px;
            background-color: #f2f2f2;
            background-size: contain;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }

      .submit {
        width: 100%;
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        background-color: #2ea8af;
        text-align: center;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        margin: 80px auto 120px;
      }
    }

    .em-r {
      width: 360px;
      height: 520px;
      border-radius: 4px;
      color: #222;

      @media (max-width: 400px) {
        display: none;
      }

      .tit {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: 'Artifakt_Bold';
        line-height: 1.5;
        font-size: 16px;
        padding-bottom: 20px;
      }

      p {
        font-size: 14px;
        color: #222222;
        opacity: .9;
        line-height: 1.5;
        padding-bottom: 20px;
      }

      .list-p {
        padding: 10px 0;
        border-bottom: 1px solid #eef8f9;
        cursor: pointer;
      }

      .more {
        color: #2ea8af;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  .maskShow {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    align-items: center;
    justify-content: center;

    &.maskShow-big {
      flex-direction: column;
    }

    .content {
      width: 500px;
      min-height: 400px;
      border-radius: 8px;
      background-color: #fff;
      padding: 40px;

      @media (max-width: 400px) {
        width: 90%;
        padding: 20px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: 'Artifakt_Bold';
        padding-top: 0;
        padding-bottom: 40px;
      }

      .clear {
        width: 14px;
        height: 14px;
        /* background: url("~@img/communityProposal/close-2.svg") no-repeat center; */
        background-size: contain;
        cursor: pointer;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -20px;
          top: -20px;
          width: 50px;
          height: 50px;
        }
      }

      .tit {
        font-family: 14px;
        color: #222;
      }

      .input {
        width: 100%;
        height: 60px;
        line-height: 60px;
        color: rgba(200, 200, 200, 1);
        box-shadow: 0 1px 0 0 rgba(237, 244, 245, 1);
        margin-top: 40px;

        @media (max-width: 400px) {
          height: 45px;
          line-height: 45px;
          border: 1px solid rgba(77, 77, 77, .1);
          padding-left: 10px;
        }

        input {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      .em {
        font-size: 14px;
        color: #2ea8af;
        padding-top: 10px;
      }

      .btn {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background-color: #2ea8af;
        font-size: 14px;
        color: #fff;
        border-radius: 24px;
        margin-top: 50px;
        cursor: pointer;
      }
    }

    .img-m {
      img {
        max-width: 800px;
        max-height: calc(100vh - 200px);
      }
    }

    .close {
      margin-top: 30px;

      img {
        cursor: pointer;
      }
    }
  }
}
</style>
