<template>
    <div class="modes-container">
        <div class="rewards-title">
            <p>{{ title }}</p>
        </div>
        <div class="rewards-text" v-show="!mobile">
            <p>{{ text }}</p>
        </div>

        <div class="modes-wrap" v-show="!mobile">
            <transition-group name="slide">
                <div class="mode-card" v-for="card in dataList" :key="card.number">
                    <div class="card-number" :class="{ open: visibleText === card.number }"
                        @click="toggleText(card.number)">
                        <p>{{ card.number }}</p>
                    </div>
                    <div class="mode-text" v-if="visibleText === card.number">
                        <p>{{ card.text }}</p>
                    </div>
                </div>
            </transition-group>
        </div>

        <div class="modes-wrap" v-show="mobile">
            <div class="mode-card" v-for="card in dataList" :key="card.number">
                <div class="card-number-mob">
                    <p>{{ card.number }}</p>
                </div>
                <div class="mode-text">
                    <p>{{ card.text }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

export default {
    name: 'ModeCards',
    props: {
        cardsData: {
            type: Array,
            required: true
        },
        text: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
    },
    setup(props) {
        const visibleText = ref('1');
        const isAnimating = ref(false);
        const mobile = ref(false);

        const toggleText = (number) => {
            isAnimating.value = true;
            setTimeout(() => {
                visibleText.value = number;
                isAnimating.value = false;
            }, 100);
        };

        const dataList = computed(() => {
            return props.cardsData;
        });

        const checkScreen = () => {
            mobile.value = window.innerWidth <= 900;
        };

        onMounted(() => {
            checkScreen();
            window.addEventListener('resize', checkScreen);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkScreen);
        });

        return {
            visibleText,
            isAnimating,
            toggleText,
            dataList,
            mobile
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/common/modeCards.scss';
</style>