<template>
    <div>
        <ul v-show="!mobile" class="navigation-list">

            <li><router-link :to="{ name: 'technology' }" class="nav-item"
                    :class="{ 'active': $route.name === 'technology' }">{{ $t('technology') }}</router-link>
            </li>

            <li><router-link :to="{ name: 'ecosystem' }" class="nav-item"
                    :class="{ 'active': $route.name === 'ecosystem' }">{{ $t('ecosystem') }}</router-link>
            </li>

            <li><router-link :to="{ name: 'community' }" class="nav-item"
                    :class="{ 'active': $route.name === 'community' }">{{ $t('community') }}</router-link>
            </li>


            <li><router-link :to="{ name: 'explorer' }" class="nav-item"
                    :class="{ 'active': $route.path.startsWith('/explorer') }">{{ $t('explorer') }}</router-link></li>


            <li><router-link :to="{ name: 'news' }" class="nav-item" :class="{ 'active': $route.name === 'news' }">{{
                $t('news') }}</router-link>
            </li>

            <li><router-link :to="{ name: 'help' }" class="nav-item" :class="{ 'active': $route.name === 'help' }">{{
                $t('help') }}</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'gameManager', params: { id: 5 } }" class="nav-item">
                    {{ 'Games' }}
                </router-link>
            </li>
        </ul>


        <div class="icon">
            <div @click="toggleMobleNav" v-show="mobile" class="burger" :class="{ 'icon-active': mobileNav }">
                <img :src="BurgerSource" alt="burger">
            </div>

            <transition name="mobile-nav">
                <div class="mob-navigation-mask" @click="toggleMobleNav" v-show="mobileNav">
                    <div class="dropdown-menu" @click.stop="">
                        <div class="mob-mode">
                            <ModeSelector></ModeSelector>
                        </div>
                        <div class="mob-menu" @click="toggleMobleNav">
                            <ul>
                                <li><router-link :to="{ name: 'home' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'home' }">{{ $t('home')
                                        }}</router-link>
                                </li>
                                <li><router-link :to="{ name: 'technology' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'technology' }">{{ $t('technology')
                                        }}</router-link>
                                </li>

                                <li><router-link :to="{ name: 'ecosystem' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'ecosystem' }">{{ $t('ecosystem')
                                        }}</router-link>
                                </li>
                                <li><router-link :to="{ name: 'community' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'community' }">{{ $t('community')
                                        }}</router-link>
                                </li>

                                <li><router-link :to="{ name: 'explorer' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'explorer' }">{{ $t('explorer') }}</router-link>
                                </li>

                                <li><router-link :to="{ name: 'news' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'news' }">{{ $t('news')
                                        }}</router-link>
                                </li>
                                <li><router-link :to="{ name: 'help' }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'help' }">{{
                                            $t('help') }}</router-link>
                                </li>
                                <li><router-link :to="{ name: 'gameManager', params: { id: 5 } }" class="nav-mob-item"
                                        :class="{ 'active': $route.name === 'game' }">{{
                                            "Games" }}</router-link>
                                </li>
                            </ul>
                            <div class="mob-lang">
                                <LangSelector></LangSelector>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import useDarkMode from '@/darkMode';
import ModeSelector from '@/components/common/modeSelector.vue';
import LangSelector from '@/components/common/langSelector.vue';
export default {
    name: 'HeaderNavList',
    props: ['currentRoute'],
    components: {
        LangSelector,
        ModeSelector
    },
    data() {
        return {
            mobile: null,
            mobileNav: null,
            windowWidth: null,
            ecosystemSubmenu: false,
            communitySubmenu: false,
        }
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        BurgerSource() {
            return this.isDark
                ? '/images/icons/burger-icon-dark.svg'
                : '/images/icons/burger-icon.svg';
        },
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    methods: {
        //open mob menu start
        toggleMobleNav() {
            this.mobileNav = !this.mobileNav;
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 1150) {
                this.mobile = true;
                return;
            }
            this.mobileNav = false;
            this.mobile = false;
            return;
        },
        //open mob menu end

    }


}
</script>
