<template>
    <div class="feature-card" ref="cardContainer" :style="{ backgroundImage: data.backgroundColor }">
      <div class="card-title">
        <h4>{{ data.title }}</h4>
      </div>
      <div class="card-img">
        <video lazy="loading" ref="videoElement" autoplay loop muted :src="data.gifUrl" alt="" playsinline></video>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FeatureCard',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isMouseOver: false,
      };
    },
    methods: {
      playVideo() {
        const video = this.$refs.videoElement;
  
        if (video && (video.paused || video.ended)) {
          video.play().catch(error => {
            console.error('Error playing video:', error);
          });
        }
      },
      pauseVideo() {
        const video = this.$refs.videoElement;
        if (video) {
          video.pause();
        }
      },
      handleScreenSize() {
        const video = this.$refs.videoElement;
  
        if (video) {
          if (window.innerWidth < 900) {
            this.playVideo();
          } else {
            this.pauseVideo();
          }
        }
      },
    },
    mounted() {
      const cardContainer = this.$refs.cardContainer;
  
      cardContainer.addEventListener('mouseover', () => {
        this.isMouseOver = true;
        this.playVideo();
      });
  
      cardContainer.addEventListener('mouseout', () => {
        this.isMouseOver = false;
        this.pauseVideo();
      });
  
      window.addEventListener('resize', this.handleScreenSize);
      this.handleScreenSize();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleScreenSize);
    },
  };
  </script>
  
  <style scoped>
  @import '@/assets/style/homePageStyle/featuresSection.scss';
  </style>
  
  