<template>
    <Section :title="$t('ecologi')">
        <div class="ecosystem-ecology-container">
            <div class="content-ecosystem">
                <div class="ecosystem-text-content">
                    <p>{{ $t('ecosystemPage.ecologyContent') }}</p>
                    <p style="font-size: 18px;">{{ $t('ecologiWallet') }}<span style="word-break: break-all;">0xb6c83fA7Bb9B5C23e96130CDEFD70977460031B9</span></p>
                </div>
            </div>
            <div class="ecosystem-image">
                <img src="../../assets/images/ecosystemPageImg/ecologi.webp" alt="imageEcosystem">
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';


export default defineComponent({
    name: 'EcologySection',
    components: {
        Section,
    }
});
</script>
<style>
@import '@/assets/style/ecosystemPage/gamesFundSection.scss';
</style>
