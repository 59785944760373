<template>
    <div class="ecosystem-section">
        <div class="section-title">
            {{ $t('ecosystem') }}
        </div>
        <TabSlider :slides="localizedTabs" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import TabSlider from '../common/tabSlider.vue';

export default defineComponent({
    name: 'EcosystemSection',
    components: {
        TabSlider,
    },
    data() {
        return {
            tabs: [
                {
                    id: 0,
                    buttonText: 'gaming',
                    title: 'gaming',
                    description: 'gamingDescription',
                    video: 'images/homePage/particles.mp4',
                    link: 'https://example.com/tab1',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 1,
                    buttonText: 'ecologi',
                    title: 'ecologi',
                    description: 'ecologiDescription',
                    video: 'images/homePage/trees-planting.mp4',
                    link: 'https://example.com/tab2',
                    linkText: 'Learn more',
                    walletAdress: 'ecologiWallet',
                    wallet: '0xb6c83fA7Bb9B5C23e96130CDEFD70977460031B9',
                },
                {
                    id: 2,
                    buttonText: 'traveling',
                    title: 'traveling',
                    description: 'travelingDescription',
                    video: 'images/homePage/airballoons.mp4',
                    link: 'https://example.com/tab3',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
            ],
        };
    },
    computed: {
        localizedTabs() {
            return this.tabs.map(tab => ({
                ...tab,
                buttonText: this.$t(tab.buttonText),
                title: this.$t(`${tab.title}`),
                description: this.$t(`homePage.${tab.description}`),
                walletAdress: this.$t(`${tab.walletAdress}`),
            }));
        },
    },
});
</script>

<style scoped>
@import '../../assets/style/homePageStyle/newsSlider.scss';
</style>
