<template>
    <Section :title="$t('title.consensusConnection')">

        <div class="consensus-container">
            <div class="text-image-start">
                <img src="../../assets/images/technologyPageImg/what-text.svg" alt="">
            </div>
            <div class="text-content">
                <div class="content-title">
                    <h3>{{ $t('technologyPage.consensusTitle') }}</h3>
                </div>
                <div class="content-desc">
                    <p>{{ $t('technologyPage.consensusDesc') }}</p>
                </div>
            </div>
            <div class="content-image">
                  <img lazy="loading"  class="content-image" :src="imageSource">
            </div>
        </div>


        <div class="consensus-container">
            <div class="create-text-image">
                <img src="../../assets/images/technologyPageImg/how-text.svg" alt="">
            </div>

            <div class="create-text-content">
                <div class="content-title">
                    <h3>{{ $t('technologyPage.createConsensusConnection') }}</h3>
                </div>
                <div class="content-desc">
                    <p>{{ $t('technologyPage.createConsensusConnectionDesc') }}</p>
                </div>
                <div class="content-cards-wrapper">
                    <p>{{ $t('technologyPage.usersNeedConfirm') }}</p>
                </div>


                <div class="consensus-card" v-for="card in dataList" :key="card.title">
                    <div class="consensus-card-img">
                        <img :src="card.imageUrl" alt="card-img">
                    </div>
                    <div class="consensus-card-content">
                        <h3>{{ card.title }}</h3>
                        <p class="consensus-text">{{ card.text }}</p>
                    </div>
                </div>

                <a :href="buttonLink" class="origin-btn">
                    <p>{{ $t('technologyPage.viewMyConnection') }}</p>
                </a>
            </div>

        </div>



        <div class="consensus-container">
            <div class="text-image-last">
                <img src="../../assets/images/technologyPageImg/revenue-text.svg" alt="">
            </div>

            <div class="content-image-last">
                <img class="content-image" src="../../assets/images/technologyPageImg/wallet-img.svg" alt="">
            </div>

            <div class="text-content">
                <div class="content-title">
                    <h3>{{ $t('technologyPage.consensusIncomeTitle') }}</h3>
                </div>
                <div class="content-desc">
                    <p v-html="$t('technologyPage.consensusIncomeDesc')"></p>
                </div>
                <a :href="buttonLink" class="origin-btn">
                    <p>{{ $t('technologyPage.viewMyIncome') }}</p>
                </a>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import useDarkMode from '@/darkMode';

export default defineComponent({
    name: 'ConsensusSection',
    components: {
        Section,
    },
    data() {
        return {
            cardsData: [
                { title: 'lockedToken', text: 'lockedTokenDesc', imageUrl: 'images/technologyPage/consensusIcons/locked-token.svg' },
                { title: 'investmentAmount', text: 'investmentAmountDesc', imageUrl: 'images/technologyPage/consensusIcons/investment-amount.svg' },
                { title: 'lockUpTime', text: 'lockUpTimeDesc', imageUrl: 'images/technologyPage/consensusIcons/lock-up-time.svg' },
                { title: 'contractCancellation', text: 'contractCancellationDesc', imageUrl: 'images/technologyPage/consensusIcons/contract-cancellation.svg' }
            ]
        }
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        dataList() {
            return this.cardsData.map(item => ({
                ...item,
                title: this.$t(`technologyPage.${item.title}`),
                text: this.$t(`technologyPage.${item.text}`),
            }));
        },

        hasToken() {
            return localStorage.getItem('token') !== null;
        },
      
        buttonLink() {
            return this.hasToken
                ? '/v1/#/user/userCenter?index=1'
                : 'v1/#/';
        },
        imageSource() {
          return this.isDark
            ? 'images/technologyPage/handshaking-dark.svg'
            : 'images/technologyPage/handshaking.svg';
        },
    },
    methods: {

        getImageUrl(imageUrl) {
            const imageKey = imageUrl.replace(/^images\/technologyPage\/consensusIcons\/(.*?)(-dark)?\.svg$/, '$1');
            return this.isDark
                ? `images/technologyPage/consensusIcons/${imageKey}-dark.svg`
                : `images/technologyPage/consensusIcons/${imageKey}.svg`;
        },
        updateImageUrls() {
            this.cardsData.forEach(item => {
                item.imageUrl = this.getImageUrl(item.imageUrl);
            });
        },
    },
    watch: {
        isDark: 'updateImageUrls',
    },
    mounted() {
        if (this.isDark) {
            this.updateImageUrls();
        }
    }

});
</script>

<style>
@import '@/assets/style/technologyPage/consensusSection.scss';
</style>
