<template>
    <div class="gaming-page">
        <FirstScreen :imageSrc="require('@/assets/images/gamingPageImg/gaming-bg.gif')"
            :imageSrcMobile="require('@/assets/images/gamingPageImg/gaming-bg-mob.gif')" />
        <Description />
        <GameDev/>
        <GamingLaunch />
        <FeaturesSection :sectionTitle="$t('rewards')" :cardDataList="featuresCards" :localizationBase="'gamingPage'"
            :initialIsListAtStart="true" :initialIsListAtEnd="false" :optionalText="$t('gamingPage.getRewardsBy')" />
        <PrRank />
        <DownloadSection />
    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import Description from '../components/gamingPage/description.vue';
import GamingLaunch from '../components/gamingPage/gamingLaunch.vue';
import FeaturesSection from '../components/homePage/featuresSection.vue';
import PrRank from '../components/gamingPage/prRank.vue';
import GameDev from '../components/gamingPage/gameDev.vue';

export default {
    name: 'GamingPage',
    components: {
        FirstScreen,
        Description,
        GamingLaunch,
        DownloadSection,
        FeaturesSection,
        PrRank,
        GameDev
    },
    data() {
        return {
            featuresCards: [
                { key: 'connectionsWithGames', gifUrl: 'images/gamingPage/connectgame.mp4' },
                { key: 'playingGames', gifUrl: 'images/gamingPage/playgames.mp4' },
                { key: 'investingInGames', gifUrl: 'images/gamingPage/investgamescard.mp4' },
                { key: 'receivingRewards', gifUrl: 'images/gamingPage/starpool.mp4' },

            ]
        };
    }

}
</script>