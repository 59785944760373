const supportedChains = [
	{
		name: "Ethereum Mainnet",
		short_name: "eth",
		chain: "ETH",
		network: "Mainnet",
		chain_id: 1,
		network_id: 1,
		rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Ethereum Ropsten",
		short_name: "rop",
		chain: "ETH",
		network: "Ropsten",
		chain_id: 3,
		network_id: 3,
		rpc_url: "https://ropsten.infura.io/v3/%API_KEY%",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Ethereum Rinkeby",
		short_name: "rin",
		chain: "ETH",
		network: "Rinkeby",
		chain_id: 4,
		network_id: 4,
		rpc_url: "https://rinkeby.infura.io/v3/%API_KEY%",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Ethereum Görli",
		short_name: "gor",
		chain: "ETH",
		network: "Goerli",
		chain_id: 5,
		network_id: 5,
		rpc_url: "https://goerli.infura.io/v3/%API_KEY%",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "RSK Mainnet",
		short_name: "rsk",
		chain: "RSK",
		network: "mainnet",
		chain_id: 30,
		network_id: 30,
		rpc_url: "https://public-node.rsk.co",
		native_currency: {
			symbol: "RSK",
			name: "RSK",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Ethereum Kovan",
		short_name: "kov",
		chain: "ETH",
		network: "kovan",
		chain_id: 42,
		network_id: 42,
		rpc_url: "https://kovan.infura.io/v3/%API_KEY%",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Ethereum Classic Mainnet",
		short_name: "etc",
		chain: "ETC",
		network: "mainnet",
		chain_id: 61,
		network_id: 1,
		rpc_url: "https://ethereumclassic.network",
		native_currency: {
			symbol: "ETH",
			name: "Ethereum",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "POA Network Sokol",
		short_name: "poa",
		chain: "POA",
		network: "sokol",
		chain_id: 77,
		network_id: 77,
		rpc_url: "https://sokol.poa.network",
		native_currency: {
			symbol: "POA",
			name: "POA",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "POA Network Core",
		short_name: "skl",
		chain: "POA",
		network: "core",
		chain_id: 99,
		network_id: 99,
		rpc_url: "https://core.poa.network",
		native_currency: {
			symbol: "POA",
			name: "POA",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "xDAI Chain",
		short_name: "xdai",
		chain: "POA",
		network: "dai",
		chain_id: 100,
		network_id: 100,
		rpc_url: "https://dai.poa.network",
		native_currency: {
			symbol: "xDAI",
			name: "xDAI",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Callisto Mainnet",
		short_name: "clo",
		chain: "callisto",
		network: "mainnet",
		chain_id: 820,
		network_id: 1,
		rpc_url: "https://clo-geth.0xinfra.com/",
		native_currency: {
			symbol: "CLO",
			name: "CLO",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	},
	{
		name: "Binance Smart Chain",
		short_name: "bsc",
		chain: "smartchain",
		network: "mainnet",
		chain_id: 56,
		network_id: 56,
		rpc_url: "https://bsc-dataseed1.defibit.io/",
		native_currency: {
			symbol: "BNB",
			name: "BNB",
			decimals: "18",
			contractAddress: "",
			balance: ""
		}
	}
];

const isProduction = process.env.VUE_APP_IS_DEV == 2
let chainList
if (isProduction) {
	chainList = [
		{
			chainId: 56,
			chainName: 'Binance Smart Chain Mainnet',
			short_name: 'BSC',
			atmId: 1,
			nativeCurrency: {
				name: 'BNB',
				symbol: 'BNB',
				decimals: 18
			},
			rpcUrls: ['https://bsc-dataseed1.binance.org/'],
			blockExplorerUrls: ['https://bscscan.com/']
		},
		{
			chainId: 137,
			chainName: 'Polygon Mainnet',
			short_name: 'Polygon',
			atmId: 2,
			nativeCurrency: {
				name: 'MATIC',
				symbol: 'MATIC',
				decimals: 18
			},
			rpcUrls: ['https://rpc-mainnet.matic.network'],
			blockExplorerUrls: ['https://polygonscan.com/']
		},
		{
			chainId: 1,
			chainName: 'Ethereum Mainnet',
			short_name: 'Ethereum',
			atmId: 4,
			nativeCurrency: {
				name: 'ETH',
				symbol: 'ETH',
				decimals: 18
			},
			rpcUrls: ['https://eth-mainnet.public.blastapi.io', 'https://rpc.ankr.com/eth'],
			blockExplorerUrls: ['https://etherscan.io/']
		},
		{
			chainId: 43114,
			chainName: 'Avalanche Mainnet',
			short_name: 'Avalanche',
			atmId: 3,
			nativeCurrency: {
				name: 'AVAX',
				symbol: 'AVAX',
				decimals: 18
			},
			rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
			blockExplorerUrls: ['https://snowtrace.io/']
		},
		{
			chainId: 96,
			chainName: 'Bitkub Mainnet',
			short_name: 'Bitkub',
			atmId: 6,
			nativeCurrency: {
				name: 'KUB',
				symbol: 'KUB',
				decimals: 18
			},
			rpcUrls: ['https://rpc.bitkubchain.io'],
			blockExplorerUrls: ['https://bkcscan.com']
		},
		// {
		// 	chainId: 211,
		// 	chainName: 'Secret Chain',
		// 	short_name: 'Secret',
		// 	atmId: 5,
		// 	nativeCurrency: {
		// 		name: 'GAS',
		// 		symbol: 'GAS',
		// 		decimals: 18
		// 	},
		// 	rpcUrls: ['https://api.secret.dev'],
		// 	blockExplorerUrls: ['https://explorer.secret.dev/']
		// },
]
} else {
	chainList = [
		{
			chainId: 97,
			chainName: 'Binance Smart Chain Testnet',
			short_name: 'BSC',
			atmId: 1,
			nativeCurrency: {
				name: 'BNB',
				symbol: 'tBNB',
				decimals: 18
			},
			rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
			blockExplorerUrls: ['https://testnet.bscscan.com/']
		},
		{
			chainId: 80001,
			chainName: 'Polygon Testnet',
			short_name: 'Polygon',
			atmId: 2,
			nativeCurrency: {
				name: 'MATIC',
				symbol: 'MATIC',
				decimals: 18
			},
			rpcUrls: ['https://polygon-mumbai.g.alchemy.com/v2/sFlNDjb4i2FLbRwxSDwtLo3rDmOMKMon'],
			blockExplorerUrls: ['https://mumbai.polygonscan.com/']
		},
		{
			chainId: 5,
			chainName: 'Ethereum (Goerli)',
			short_name: 'Ethereum',
			atmId: 4,
			nativeCurrency: {
				name: 'ETH',
				symbol: 'ETH',
				decimals: 18
			},
			rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
			blockExplorerUrls: ['https://goerli.etherscan.io/']
		},
		{
			chainId: 43113,
			chainName: 'Avalanche Testnet',
			short_name: 'Avalanche',
			atmId: 3,
			nativeCurrency: {
				name: 'AVAX',
				symbol: 'AVAX',
				decimals: 18
			},
			rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
			blockExplorerUrls: ['https://testnet.snowtrace.io/']
		},
		{
			chainId: 25925,
			chainName: 'Bitkub Testnet',
			short_name: 'Bitkub',
			atmId: 6,
			nativeCurrency: {
				name: 'KUB',
				symbol: 'KUB',
				decimals: 18
			},
			rpcUrls: ['https://rpc-testnet.bitkubchain.io'],
			blockExplorerUrls: ['https://testnet.bkcscan.com']
		},
		// {
		// 	chainId: 21610,
		// 	chainName: 'Secret Test Chain',
		// 	short_name: 'Secret',
		// 	atmId: 5,
		// 	nativeCurrency: {
		// 		name: 'GAS',
		// 		symbol: 'TGAS',
		// 		decimals: 18
		// 	},
		// 	rpcUrls: ['http://207.46.145.61:8545'],
		// 	blockExplorerUrls: ['http://207.46.145.61:4000', 'https://test-explorer.secret.dev/']
		// },
		// {
		// 	chainId: 211,
		// 	chainName: 'Secret Chain',
		// 	short_name: 'Secret',
		// 	atmId: 5,
		// 	nativeCurrency: {
		// 		name: 'GAS',
		// 		symbol: 'GAS',
		// 		decimals: 18
		// 	},
		// 	rpcUrls: ['https://api.secret.dev'],
		// 	blockExplorerUrls: ['https://explorer.secret.dev']
		// },
		
		
	]
}

export {chainList}

export default supportedChains
