<template>
    <div class="download-section">
        <div class="download-img">
            <img lazy="loading" src="../../assets/images/homePageImg/atm-banner.png" alt="download">
        </div>
        <div class="download-content">
            <div class="download-text">
                <p> {{ $t('downloadAtmApp') }}</p>
            </div>

            <div class="dapp-button-wrapper">
                <DappBadgeButton class="google-link" v-for="(badgeButton, index) in buttons" :key="index"
                    :button="badgeButton" />
            </div>




            <!-- <div class="link-wrap">
                <a href="http://">
                    <p>Learn more</p>
                </a>
                <img src="../../assets/images/icons/arrayRightWhite.svg" alt="">
            </div> -->

        </div>

    </div>
</template>
<script>
import DappBadgeButton from '@/components/common/dappBadge.vue'
export default {
    name: 'DownloadSection',
    data() {
        return {
            buttons: [
                { key: 'downloadAndroid', image: 'images/app/google-badge.png', href: 'https://play.google.com/store/apps/details?id=network.atm.atmconnect&hl=en_GB&gl=US' },
                { key: 'downloadIOS', image: 'images/app/apple-badge.svg', href: 'https://apps.apple.com/gb/app/atm-connect/id6463245714 ' }
            ],
        }
    },
    components: {
        DappBadgeButton
    },
}
</script>