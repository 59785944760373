<template>
  <div class="connect-btn-press">
    <!-- <div @click="onClickConnect" class="connect-btn">
      <p>{{ this.$t('connect') }}</p>
    </div> -->
    <div @click="onClickConnect" class="connect-btn" v-if="!hasToken">
        <p>{{ this.$t('connect') }}</p>
      </div>
    <a :href="buttonLink" class="connect-btn" v-else>
        <p>{{ this.$t('webApp') }}</p>
      </a>
  </div>
</template>
  
<script>
import { useBlockChainStore } from '@/store/blockchain'

export default {
  name: "ConnectButton",
  props: {
    web3Model: Object,
    authStore: Object,
    popUp: () => {
      return false
    },
  },
  data() {
    return {
      token: localStorage.getItem("token"), // Initialize with current token value
    };
  },
  computed: {
    hasToken() {
      return this.token !== null;
    },
    buttonLink() {
      return this.hasToken
        ? "/v1/#/user/userCenter?index=1"
        : "v1/#/";
    },
  },
  mounted() {
    // Listen for storage changes (e.g., in another tab)
    window.addEventListener("storage", this.updateTokenFromStorage);

    // Optionally, periodically check for token changes if localStorage is updated from same tab
    this.checkTokenInterval = setInterval(this.updateTokenFromStorage, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.updateTokenFromStorage);
    clearInterval(this.checkTokenInterval);
  },
  methods: {
    updateTokenFromStorage() {
      const token = localStorage.getItem("token");
      if (this.token !== token) {
        this.token = token; // Trigger reactivity
      }
    },
    onClickConnect() {

      const { open } = this.web3Model;
      open();
      if (this.popUp && typeof this.popUp === 'function') {
        this.popUp();
      }
      // const blockChainStore = useBlockChainStore();
      // blockChainStore.init();
    },
  },
};
</script>
  