<template>
    <Section :title="$t('title.usageGuide')" class="guide-section">
        <div class="guide-description">
            <div class="body-text">
                <p>{{ $t('helpPage.understandingTechnology') }}</p>
            </div>
        </div>

        <div class="atm-course">
            <div class="course-title">
                <div class="course-img">
                    <img src="../../assets/images/logo/logo.svg" alt="">
                </div>
                <div class="body-text">
                    <p>{{ $t('helpPage.ATMcourse') }}</p>
                </div>
            </div>
            <div class="small-body-text">
                <p>{{ $t('helpPage.courseContains') }}</p>
            </div>


            <div class="cources-list">
                <a v-for="(link, index) in localizedLinks" :key="index" :href="getHref(link)" target="_blank">
                    <div class="small-body-text">
                        <p>{{ link.text }}</p>
                    </div>
                </a>
            </div>

        </div>
    </Section>
</template>

<script>
import Section from '../../components/common/section.vue';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    name: 'UsageGuide',
    components: {
        Section,
    },
    setup() {
        const { t, locale } = useI18n();

        const links = ref([
            { text: 'connectMetaMask', href: '/pdf/atm-en/connectWallet.pdf', hrefCN: '/pdf/atm-cn/connectWallet.pdf' },
            { text: 'participateInPR', href: '/pdf/atm-en/participateInPR.pdf', hrefCN: '/pdf/atm-cn/participateInPR.pdf' },
            { text: 'howAddLiquidity', href: '/pdf/atm-en/howAddLiquidity.pdf', hrefCN: '/pdf/atm-cn/howAddLiquidity.pdf' },
            { text: 'createConsensusConnection', href: '/pdf/atm-en/createConsensusConnection.pdf', hrefCN: '/pdf/atm-cn/createConsensusConnection.pdf' },
            { text: 'queryConnectionByAddress', href: '/pdf/atm-en/queryConnectionByAddress.pdf', hrefCN: '/pdf/atm-cn/queryConnectionByAddress.pdf' },
            { text: 'checkIncome', href: '/pdf/atm-en/checkIncome.pdf', hrefCN: '/pdf/atm-cn/checkIncome.pdf' },
            { text: 'purchaseLUCA', href: '/pdf/atm-en/purchaseLUCA.pdf', hrefCN: '/pdf/atm-cn/purchaseLUCA.pdf' },
            { text: 'initiateCommunityProposal', href: '/pdf/atm-en/initiateCommunityProposal.pdf', hrefCN: '/pdf/atm-cn/initiateCommunityProposal.pdf' }
        ]);

        const getHref = (link) => {
            return locale.value === 'cn' ? link.hrefCN : link.href;
        };

        const localizedLinks = computed(() => {
            return links.value.map(item => ({
                ...item,
                text: t(`helpPage.${item.text}`),
            }));
        });

        return {
            localizedLinks,
            getHref
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/helpPage/usageGuide.scss';
</style>
