<template>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
            <div class="close-button" @click="closeModal">
                <img src="../../assets/images/icons/close-button.svg" alt="close-btn">
            </div>
            <div class="video-player">
                <video :src="videoSrc" ref="videoPlayer" controls playsinline></video>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
    name: 'ModalVideoPlayer',
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        videoSrc: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            scrollPosition: 0,
            videoStates: []
        };
    },
    methods: {
        closeModal() {
            this.$emit('update:showModal', false);
            if (this.$refs.videoPlayer) {
                this.$refs.videoPlayer.pause();
            }
            this.resumeAllVideos(); 
        },
        pauseAllVideos() {
            const videos = document.querySelectorAll('video');
            this.videoStates = []; 
            videos.forEach(video => {
                this.videoStates.push({
                    video: video,
                    playing: !video.paused
                });
                if (!video.paused) {
                    video.pause();
                }
            });
        },
        resumeAllVideos() {
            this.videoStates.forEach(({ video, playing }) => {
                if (playing) {
                    video.play();
                }
            });
        }
    },
    watch: {
        showModal(newVal) {
            if (newVal) {
                this.pauseAllVideos(); 
                
                this.scrollPosition = window.scrollY;
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
                document.body.style.width = '100%';
                document.body.style.top = `-${this.scrollPosition}px`;

                nextTick(() => {
                    if (this.$refs.videoPlayer) {
                        this.$refs.videoPlayer.play();
                    }
                });
            } else {
                document.body.style.overflow = '';
                document.body.style.position = '';
                document.body.style.width = '';
                document.body.style.top = '';
                
                window.scrollTo(0, this.scrollPosition);
            }
        }
    }
});
</script>

<style lang="scss" scoped>
@import '../../assets/style/common/modalVideoPlayer.scss';
</style>
