<template>
    <Section :title="$t('title.lucaRevards')" class="revards-section-wrapper">
        <OrdinaryContainer imageSrc="images/communityPage/lucaRewards.webp"
            :description="$t('communityPage.earnLUCA')" />
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'

export default defineComponent({
    name: 'RewardsSection',
    components: {
        Section,
        OrdinaryContainer
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/communityPage/rewardsSection.scss';
</style>