<template>
    <Section :title="$t('title.prAlgorithm')" class="algorithm-section">
        <TabSlider :slides="localizedTabs" />
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import TabSlider from '../../components/common/tabSlider.vue';
import Section from '../../components/common/section.vue';

export default defineComponent({
    name: 'AlgorithmSection',
    components: {
        TabSlider,
        Section,
    },
    data() {
        return {
            tabs: [
                {
                    id: 0,
                    buttonText: 'about',
                    title: 'pageRank',
                    description: 'pageRankDesc',
                    image: 'images/technologyPage/pageRank.webp',
                    link: 'https://example.com/tab1',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 1,
                    buttonText: 'strengths',
                    title: 'strengthsPageRank',
                    description: 'strengthsPageRankDesc',
                    image: 'images/technologyPage/strengths.webp',
                    link: 'https://example.com/tab2',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 2,
                    buttonText: 'create',
                    title: 'createPRcode',
                    description: 'createPRcodeDesc',
                    image: 'images/technologyPage/createPRnode.webp',
                    link: 'https://example.com/tab3',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
                {
                    id: 3,
                    buttonText: 'income',
                    title: 'incomePRnode',
                    description: 'incomePRnodeDesc',
                    image: 'images/technologyPage/income.webp',
                    link: 'https://example.com/tab1',
                    linkText: 'Learn more',
                    walletAdress: '',
                    wallet: '',
                },
            ],
        };
    },
    computed: {
        localizedTabs() {
            return this.tabs.map(tab => ({
                ...tab,
                buttonText: this.$t(tab.buttonText),
                title: this.$t(`prAlgorithm.${tab.title}`),
                description: this.$t(`prAlgorithm.${tab.description}`),
                walletAdress: this.$t(`${tab.walletAdress}`),
            }));
        },
    },
});
</script>
<style scoop>

@media (max-width: 1100px) {
    .algorithm-section{
        padding: 0px 15px;
        height: fit-content;
        .swiper{
            height: 900px;
        }
    }
}
</style>

