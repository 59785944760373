<template>
    <Section :title="$t('title.gamesFund')" class="ecosystem-section">
        <div class="ecosystem-container">
            <div class="ecosystem-image">
                <img src="../../assets/images/ecosystemPageImg/gamingfund.webp" alt="imageEcosystem">
            </div>
            <div class="content-ecosystem">
                <div class="ecosystem-text-content">
                    <p>{{ $t('ecosystemPage.discoverGamingFund') }}</p>
                    <p>{{ $t('ecosystemPage.developersFundingOpportunities') }}</p>
                </div>
                <div class="ecosystem-buttons-wrapper">
                    <router-link :to="{ name: 'gameRulesPage' }" class="origin-btn">
                        <p>{{ $t('ecosystemPage.learnAboutFunding') }}</p>
                    </router-link>
                    
                    <!-- <div class="active-button">
                        <p>{{ $t('ecosystemPage.submitProject') }}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';


export default defineComponent({
    name: 'gamesFundSection',
    components: {
        Section,
    }
});
</script>
<style>
@import '@/assets/style/ecosystemPage/gamesFundSection.scss';
</style>