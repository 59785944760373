<template>
    <Section :title="$t('title.proposalDecision')">
        <div class="proposal-section">
            <div class="proposal-container">
                <div class="proposal-content" v-for="(item, index) in initiateList" :key="index">
                    <div class="proposal-title">
                        <h4>{{ item.title }}</h4>
                    </div>
                    <div class="proposal-text">
                        <p>{{ item.content }}</p>
                    </div>
                    <div class="proposal-date" v-if="item.startTime && item.endTime">
                    <p>{{ formatDateTime(item.startTime) }} - {{ formatDateTime(item.endTime) }}</p>
                    </div>
                    <div class="proposal-buttons" v-if="item.status !== undefined">
                        <div class="status-wrapper">
                            <p>{{ btnText(item.status) }}</p>
                        </div>
                        <a :href="viewLink(item.id)" class="view-btn">
                            <p>{{ $t('view') }}</p>
                            <img src="@/assets/images/icons/array-right.svg" alt="array-right">
                        </a>
                    </div>
                </div>
                <div class="proposal-img">
                    <img src="@/assets/images/communityPageImg/hands-img.svg" alt="">
                </div>
            </div>
            <div>
                <a :href="viewAllLink" target="_blank" class="origin-btn">
                    <p>{{ $t('viewAll') }}</p>
                </a>
            </div>
        </div>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '@/components/common/section.vue';
import { getInitiateList } from '@/api/api';

export default defineComponent({
    name: 'ProposalDecisionSection',
    components: {
        Section,
    },
    data() {
        return {
            initiateList: [],
        };
    },
    computed: {
        hasToken() {
            return localStorage.getItem('token') !== null;
        },
        viewAllLink() {
            return this.hasToken ? '/v1/#/communityProposal' : 'v1/#/';
        }
    },
    methods: {
        viewLink(id) {
            return this.hasToken ? `/v1/#/proposalDetails?id=${id}` : 'v1/#/';
        },
        btnText(type) {
            switch (type) {
                case 1: return this.$t('communityPage.underReview');
                case 2: return this.$t('communityPage.successfulAudit');
                case 3: return this.$t('communityPage.auditFailed');
                case 4: return this.$t('communityPage.inProgress');
                case 5: return this.$t('communityPage.ended');
                case 6: return this.$t('communityPage.text8');
                default: return '';
            }
        },
        async fetchInitiateList() {
            try {
                const response = await getInitiateList();
                if (response.success) {
                    this.initiateList = response.data.initiateList;
                } else {
                    console.error('API request failed');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            this.showIfNoData();
        },
        showIfNoData() {
            if (this.initiateList.length === 0) {
                this.initiateList.push({
                    title: this.$t('communityPage.dataNoLoaded'),
                    content: this.$t('communityPage.checkBackShortly'),
                });
            }
        },
        formatDateTime(timestamp) {
            const date = new Date(timestamp * 1000); 
            return date.toLocaleString(); 
        }
    },
    created() {
        this.fetchInitiateList();
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/communityPage/proposalDecision.scss';
</style>
