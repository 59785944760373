<template>
  <div class="section-container" style="max-width: 1094px; box-sizing: border-box;">
    <div class="dialogue-section">
      <div class="dialogue-title-wrapper">
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div class="dialogue-section-title">
            <h3>Changes required  <b><span :style="{ color: '#FD8B06' , fontSize:'22px' }">&#x21;</span></b>
            </h3>
          </div>
        </div>

      </div>

      <div class="dialogue-input-wrapper">
        <div class="game-overview-description">
          <div id="viewEditor" style="font-family: 'Inter'">
            <div class="quillWrapper">
              <div class="quill-container">
                <div class="ql-editor ql-blank">
                  <div v-html="notes"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { VueEditor } from 'vue2-quill-editor'


export default {
  name: 'GameNotes',
  props: {
    notes: {
      type: String, // Assuming overview is a text
      default: ''
    },
    isAdmin:{
      type: Boolean, // Assuming videoUrl is a URL
      default: false
    },
    isEdit:{
      type: Boolean, // Assuming videoUrl is a URL
      default: false
    }
  },
  data() {
    return {
      // Your existing data properties
      gamePlayToolbar: [

      ]
    };
  },
  components: {
    VueEditor
    // Your existing components
  },
  computed: {
    // Your existing computed properties
  },
  mounted() {
    // Your existing mounted lifecycle hook
  },
  beforeMount() {
    // Your existing beforeMount lifecycle hook
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('resize', this.arrangeCardList);
  },
  methods: {
    // Your existing methods
  }
}
</script>
<style scoped>
.name-file {
  cursor: pointer;
  font-size: 14px;
  font-family: 'ProximaNova';
  color: #111;
  font-weight: 400;
  line-height: 18px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-file-btn {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  color: rgba(67, 67, 67, 1);
}

.remove-file-btn img {
  width: 100%;
  height: 100%;
}

.choose-file-btn {
  display: flex;
  width: 101px;
  height: 34px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  float: right;
  border-radius: 24px;
  background: var(--white, #FFF);
  border: 1px solid #100CD8;
  cursor: pointer;
  margin-left: 10px;
}

a {
  color: #0000ff;
}

.full {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.g-dialog {
  position: fixed;
  color: #333333;
  z-index: 9999;
}

.g-dialog-mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.g-dialog-with-background-wrap {
  position: absolute;
  width: max-content;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .g-dialog-with-background-wrap {
    width: 80% !important;
    padding: 0;
    align-items: center;
    display: flex;
  }
}

.g-dialog-wrap {
  position: absolute;
  width: 420px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: transparent !important;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0) !important;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .g-dialog-wrap {
    width: 80% !important;
    padding: 20px 0 0 0;
  }
}

.el-icon-close {
  position: absolute;
  right: 40px;
  top: 30px;
  font-size: 30px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
}

.g-dialog-title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  margin: 54px 0 30px;
}

@media screen and (max-width: 800px) {
  .g-dialog-title {
    line-height: initial;
    margin: 20px 0;
    padding-bottom: 0;
  }
}

.g-dialog-content {
  font-size: 14px;
  color: #1a1a1a;
  margin-bottom: 60px;
  padding: 0 50px;
}

@media screen and (max-width: 800px) {
  .g-dialog-content {
    margin-bottom: 30px;
  }
}

.g-dialog-btn-wrap {
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e8e8e8;
}

.g-dialog-btn {
  display: block;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  background-color: #fff;
}

.g-dialog-ok {
  width: 50%;
  color: #119b56;
  transition: 0.4s;
  border-left: 1px solid #e8e8e8;
}

.g-dialog-ok.red {
  color: #f82e4a;
}

.g-dialog-cancel {
  width: 50%;
  color: #000;
}

.section-container {
  display: flex;
  z-index: 1;
  flex-direction: column;
  border-radius: 8px;
}

.train-avatar-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.train-avatar-circle-container {
  display: none;
  box-sizing: border-box;
  border: 1px solid #100CD8;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.circle-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.circle-image img {
  width: 100%;
  height: 100%;
}

.ttitle {
  color: #000;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
}

.divider {
  width: 150px;
  height: 1px;
  margin: 15px 0px 15px 0px;
  background: #c0dadb;
}

.example-docs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.example-file {
  padding-right: 5px;
  cursor: pointer;
}

.example-file a {
  color: rgba(95, 95, 95, 1);
  border-bottom: 1px solid rgba(95, 95, 95, 1);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.dialogue-section,
.files-section {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  height: auto;
  padding: 15px;
  min-height: 180px;
  margin-bottom: 20px;
}

.dialogue-title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.dialogue-section-title,
.files-section-title {
  text-align: center;
}

.dialogue-section-title h3,
.files-section-title h3 {
  color: #1C1C1C;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dialogue-btn-wrapper,
.files-btn-wrapper {
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
  height: 34px;
  display: flex;
  padding: 7px 10px;
  flex-direction: row;
  justify-content: center;
  border-radius: 24px;
  background: linear-gradient(180deg, rgba(142, 27, 244, 0.80) 0%, rgba(16, 12, 216, 0.80) 100%);
}

.dialogue-btn-icon,
.files-upload-icon {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.dialogue-btn-icon img,
.files-upload-icon img {
  width: 100%;
  height: 100%;
}

.dialogue-btn,
.files-upload-btn {
  text-align: center;
  color: #ffffff;
}

.dialogue-section-description p,
.files-section-description p {
  color: #1C1C1C;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.files-download-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.files-download-icon img {
  width: 100%;
  height: 100%;
}

.files-dowload-link {
  cursor: pointer;
  color: #5F5F5F;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}

.files-dowload-link a {
  color: #5F5F5F;
}

.files-download-wrapper {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.dialogue-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
}

.hideBlock {
  display: none;
}

.unHideBlock {
  display: block !important;
}

.input-subtitle {
  color: #1C1C1C;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

textarea {
  width: 100%;
  border: 1px solid rgba(28, 28, 28, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-family: Inter;
  resize: none;
}

textarea:focus {
  outline: none;
}

.dialogue-btn-wrapper .remove-file-btn {
  margin-left: 2px;
}
</style>
