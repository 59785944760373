<template>
    <div  @mouseover="showText = true" @mouseleave="showText = false">

        <a :href="item.link" class="item-img" target="_blank">
            <img :src="item.imageUrl" :alt="item.imageAlt">
        </a>
        <div class="item-text" v-if="showText || displayedText">
            <p>{{ item.text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConnectItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        displayedText: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showText: false
        };
    }
};
</script>
