<template>
  <div class="countdown">
    <div class="count-desc">
      <div class="desc-text">{{ displayText }}</div>

      <router-link v-if="link" :to="{ name: link.name, params: link.params }">
        <div class="counter-link-wrapper">
          <div class="link-text">{{ $t('caunter.learnMore') }}</div>
          <div class="link-icon">
            <img src="../../assets/images/icons/array-right.svg" alt="">
          </div>
        </div>
      </router-link>
    </div>

    <div class="counter" v-show="countTime > 0 && !countdownFinished">
      <div class="item-time">
        <div class="time">{{ countdownFinished ? '0' : days }}</div>
        <div class="text">{{ $t('homePage.days') }}</div>
      </div>
      <div class="line"></div>
      <div class="item-time">
        <div class="time">{{ countdownFinished ? '0' : hours }}</div>
        <div class="text">{{ $t('homePage.hours') }}</div>
      </div>
      <div class="line"></div>
      <div class="item-time">
        <div class="time">{{ countdownFinished ? '0' : minutes }}</div>
        <div class="text">{{ $t('homePage.min') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    endDate: String,
    link: [String, Object],
    displayText: String
  },
  data() {
    return {
      countTime: 0,
      countdownFinished: false
    };
  },
  computed: {
    days() {
      return Math.floor(this.countTime / (24 * 60 * 60 * 1000));
    },
    hours() {
      return Math.floor((this.countTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    },
    minutes() {
      return Math.floor((this.countTime % (60 * 60 * 1000)) / (60 * 1000));
    },
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      this.countTime = new Date(this.endDate) - now;

      if (this.countTime <= 0) {
        this.countTime = 0;
        this.countdownFinished = true;
      } else {
        setTimeout(this.updateCountdown, 1000);
      }
    },
  },
  mounted() {
    this.updateCountdown();
  },
};
</script>

<style scoped>
@import '../../assets/style/homePageStyle/newsSection.scss';
</style>
