<template>
    <div class="component-content">
        <div class="top-text">
            <p> {{ topText }}</p>
        </div>
        <div class="main-text">
            {{ mainText }} <span class="main-span"> {{ mainSpan }}</span>{{ mainTail }}
        </div>
        <div class="bottom-text">
            <p> {{ bottomText }}</p>
        </div>
        <!-- <div class="active-button">
                <p>{{ $t('homePage.becomeKol') }}</p>
            </div> -->
    </div>
</template>

<script>
export default {
    name: 'TextComponent',
    props: {
     
        topText: {
            type: String,
            required: true
        },
        mainText: {
            type: String,
            required: true
        },
        mainSpan: {
            type: String,
            required: true
        },
        mainTail: {
            type: String,
            required: true
        },
        bottomText: {
            type: String,
            required: true
        }
    }
}
</script>
