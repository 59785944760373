import { messages, defaultLocale } from './data';
import { createI18n } from 'vue-i18n';


const i18n = createI18n({
	legacy: false,
	locale: localStorage.getItem('lang') || defaultLocale, 
	fallbackLocale: defaultLocale,
	messages 
})
export default i18n;