<template>
    <Section :title="$t('title.pandoraPlanet')">
        <OrdinaryContainer class="pandora-section"  imageSrc="images/communityPage/pandora.webp"
            :title="$t('communityPage.meetTeam')" :description="$t('communityPage.unlockExpertise')"/>
    </Section>
</template>

<script>
import { defineComponent } from 'vue';

import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'

export default defineComponent({
    name: 'PandoraSection',
    components: {
        Section,
        OrdinaryContainer
    }
});
</script>
<style lang="scss" scoop>
@import '../../assets/style/communityPage/pandoraSection.scss';
</style>

<!-- pandora button -->
<!-- :buttonText="$t('communityPage.chatWithExperts')"
:linkUrl="'https://atm.network/v1/#/shareLink/lucy?sharekey=GHBXZbMJN3lL-6cJB9CquxN2p0nRu1W7spR--ozp4H4'"
:showActiveButton="true" -->