<template>
    <router-link to="/">
    <div class="header-logo">
        
            <div class="logo">
                <img src="../../assets/images/logo/logo.svg" alt="logo">
            </div>
            <div class="atm-logo">
                <img src="../../assets/images/logo/atm-logo.svg" alt="logo">
            </div>
       
    </div>
</router-link>
</template>

<script>
export default {
    name: 'AtmLogo',
}
</script>