<template>
    <Section :title="$t('title.gameLaunch')">
        <div class="game-lunch-section">
            <OrdinaryContainer style="flex-direction: row-reverse;" :imageSrc=imageSource
                :description="$t('gamingPage.gameLaunchDescription')" />
        </div>

        <TextComponent :mainText="$t('gamingPage.fundsFullyRefunded')" :mainSpan="$t('gamingPage.usersInvestors')"
            :mainTail="$t('gamingPage.dailyFromGames')" />
    </Section>
    <div class="get-stars">
        <div class="get-stars-video">
            <video src="../../assets/images/gamingPageImg/gaming-universe.mp4" autoplay loop muted playsinline></video>
        </div>
        <div class="get-stars-content">
            <div class="large-body-text">
                <p>{{ $t('gamingPage.howGetStars') }}</p>
            </div>
            <div class="get-stars-step">
                <div class="get-stars-img">
                    <img src="../../assets/images/gamingPageImg/atmstar.gif" alt="">
                </div>
                <div class="body-text">
                    <p>{{ $t('gamingPage.creatingConnectionWithGame') }}</p>
                </div>
            </div>
            <div class="get-stars-step">
                <div class="get-stars-img">
                    <img src="../../assets/images/gamingPageImg/atmstar.gif" alt="">
                </div>
                <div class="body-text">
                    <p>{{ $t('gamingPage.winningBattle') }}</p>
                </div>
            </div>
            <div class="get-stars-description">
                <div class="body-text">
                    <p v-html="$t('gamingPage.unlockFeaturesWithStars')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Section from '../../components/common/section.vue';
import OrdinaryContainer from '../../components/common/ordinaryContainer.vue'
import useDarkMode from '@/darkMode';
import TextComponent from '../../components/common/textComponent.vue';
export default defineComponent({
    name: 'GamingLaunch',
    components: {
        Section,
        OrdinaryContainer,
        TextComponent
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        imageSource() {
            return this.isDark
                ? 'images/gamingPage/pr-dark.svg'
                : 'images/gamingPage/pr.svg';
        }
    }
});
</script>
<style lang="scss" scoped>
@import '@/assets/style/gamingPage/gamingLaunch.scss';
</style>