<template>
  <swiper class="swiper" :modules="modules" :slides-per-view="1" :slides-per-group="1" :centered-slides="true" :navigation="false"
    :pagination="{ clickable: true }" :autoplay="{ delay: 3500, disableOnInteraction: false }" loop ref="swiper"  :speed="1000" effect="slide">

    <swiper-slide :newsArray="newsArray" v-for="slide in slides" :key="slide.title" >
      <div class="slide-img">
        <img lazy="loading" :src="slide.coverImg" alt="Slide Cover Image">
      </div>
      <div class="slide-text-wrapper">

        <h3 class="slide-title">{{ slide.title }}</h3>
        <div class="slide-desc">
          <p v-html="slide.content"></p>
        </div>

        <router-link :to="{ name: 'news', params: { id: slide.id } }" class="slide-btn">
            <p>{{ $t('readMore') }}</p>
          </router-link>

      </div>
    </swiper-slide>

  </swiper>
</template>
  
<script>
import { defineComponent } from 'vue';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default defineComponent({
  name: 'NewsSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: Array,
    require: true
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
});
</script>
 
  
<style lang="scss" scoped>
@import '../../assets/style/homePageStyle/newsSlider.scss';
</style>

