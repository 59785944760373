<template>
    <div class="technology-page">
        <FirstScreen
            :imageSrc="require('@/assets/images/technologyPageImg/technology-image.webp')"
            :imageSrcMobile="require('@/assets/images/technologyPageImg/technologyimgmob.webp')"
            :titleStart="$t('technologyPage.mainTechnologyTextStart')"
            :titleSpan="$t('technologyPage.mainTechnologyTextSpan')"
            :titleEnd="$t('technologyPage.mainTechnologyTextEnd')"
        />
        <ResourcesSection/>
       <ConsensusSection/>
        <AlgorithmSection/>
        <AutonomySection/>
       <TechnologySection/>
        <DownloadSection/>
    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import ConsensusSection from '../components/technologyPage/consensusSection.vue'
import DownloadSection from '../components/homePage/downloadSection.vue';
import AlgorithmSection from '../components/technologyPage/algorithmSections.vue';
import AutonomySection from '../components/technologyPage/autonomySection.vue';
import TechnologySection from '../components/technologyPage/technologySection.vue';
import ResourcesSection from '../components/technologyPage/resourcesSection.vue'

export default {
    name: 'TechnologyPage',
    components: {
        FirstScreen,
        ResourcesSection,
        ConsensusSection,
        AlgorithmSection,
        DownloadSection,
        AutonomySection,
        TechnologySection
    }
}
</script>