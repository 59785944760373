<template>
    <div class="tabs-content">
        <div class="tab-buttons">
            <div class="tab-button" :class="{ 'active-button': currentTab === tab.id }" v-for="tab in slides"
                :key="tab.id" @click="changeTab(tab.id)">
                <p>{{ tab.buttonText }}</p>
            </div>
        </div>

        <swiper class="swiper" :modules="modules" :slides-per-view="1" :centered-slides="true" :navigation="false"
            :activeIndex="currentTab" ref="swiper" @swiper="onSwiper" @slideChange="onSlideChange" :speed="1000"
            effect="slide">
            <swiper-slide v-for="tab in slides" :key="tab.id">
                <div class="tab-container">
                    <div class="video-wrapper">
                        <template v-if="tab.video">
                            <div class="tab-video">
                                <video lazy="loading" :src="tab.video" type="video/mp4" autoplay loop muted
                                    playsinline></video>
                            </div>
                        </template>
                        <template v-else-if="tab.image">
                            <img :src="tab.image" alt="tab-image">
                        </template>
                    </div>
                    <div class="tab-content">
                        <h2>{{ tab.title }}</h2>
                        <p style="margin-top: 10px;" v-html="tab.description"></p>
                        <p class="wallet-adress">{{ tab.walletAdress }}</p>
                        <p class="wallet">{{ tab.wallet }}</p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default defineComponent({
    name: 'UniversalSlider',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        slides: {
            type: Array,
            required: true
        }
    },
    setup() {
        return {
            modules: [Pagination, Navigation, Autoplay],
        };
    },
    data() {
        return {
            currentTab: 0,
            swiper: null,
        };
    },
    methods: {
        onSwiper(swiper) {
            this.swiper = swiper;
        },
        changeTab(tabId) {
            this.currentTab = tabId;
            this.swiper.slideTo(tabId);
        },
        onSlideChange() {
            this.currentTab = this.swiper.activeIndex;
        }
    },
});
</script>

<style scoped>
@import '../../assets/style/homePageStyle/newsSlider.scss';
</style>
