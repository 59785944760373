<template>
    <div class="token-pr-section">
        <Section :title="$t('title.tokenPr')">
            <div class="token-container">
                <div class="token-content">
                    <div class="token-desc">
                        <p>{{ $t('ecosystemPage.communityEcosystem') }} <span class="token-span">
                                {{ $t('ecosystemPage.tokenIntegration') }}</span>{{ $t('ecosystemPage.dualIncentives')
                            }}
                        </p>
                    </div>

                    <div class="join-token">
                        <div class="joing-token-title">
                            <p>{{ $t('ecosystemPage.applicationProcess') }}</p>
                        </div>
                        <div class="join-token-desc">
                            <p>{{ $t('ecosystemPage.consensusRewards') }}</p>
                        </div>
                    </div>
                </div>

                <div class="token-image">
                    <img :src="imageSource">
                </div>
            </div>

            <div class="reward-container">
                <ModeCards :cardsData="dataList" :text="$t('ecosystemPage.tapNumbers')"
                    :title="$t('ecosystemPage.rewardModes')" />

                <div class="ecosystem-buttons-wrapper">
                    <div class="origin-btn" @click="visible = true">
                        <p>{{ $t('ecosystemPage.learnRewards') }}</p>
                    </div>
                    <!-- <a href="https://atm.network/v1/#/joinTokenPR" target="_blank" class="active-button">
                    <p>{{ $t('ecosystemPage.joinNow') }}</p>
                </a> -->
                </div>

                <transition name="dialog">
                    <div class="dialog" v-if="visible">
                        <div class="dialog-mask" @click="visible = false"></div>

                        <div class="dialog-wrap">
                            <div class="dialog-heading">

                                <div class="dialog-title">{{ $t('ecosystemPage.rewardExample') }}</div>
                                <div class="dialog-close-btn" @click="visible = false">
                                    <img src="../../assets/images/icons/close-icon.svg" alt="">
                                </div>
                            </div>
                            <div class="dialog-content">

                                <div class="dialog-text">
                                    <p>{{ $t('ecosystemPage.rewardDistribution') }}</p>
                                    <p v-html="$t('ecosystemPage.dualRewards')"></p>
                                    <p>{{ $t('ecosystemPage.sharedAllocation') }}</p>
                                </div>

                                <div class="active-button" @click="visible = false">
                                    <p>{{ $t('ecosystemPage.IveUnderstood') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </Section>
    </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import useDarkMode from '@/darkMode';
import Section from '../../components/common/section.vue';
import ModeCards from '../common/modeCards.vue';

export default defineComponent({
    name: 'TokenPRSection',
    components: {
        Section,
        ModeCards
    },
    data() {
        return {
            visible: false
        }
    },
    setup() {
        const { isDark } = useDarkMode();
        const { t } = useI18n();

        const cardsData = [
            { number: '1', text: 'automaticDistribution' },
            { number: '2', text: 'dualDistribution' },
            { number: '3', text: 'tokenBased' },
        ];


        const dataList = computed(() => {
            return cardsData.map(item => ({
                ...item,
                text: t(`ecosystemPage.${item.text}`),
            }));
        });




        const imageSource = computed(() => {
            const source = isDark.value
                ? 'images/ecologyPage/tokenvalue-dark.svg'
                : 'images/ecologyPage/tokenvalue.svg';
            return source;
        });


        return {
            dataList,
            imageSource,
        };
    },
});
</script>

<style>
@import '@/assets/style/ecosystemPage/tokenPRSection.scss';
@import '@/assets/style/common/dialog.scss';
</style>
