<template>
    <div class="explorer-page">

        <FirstScreen :imageSrc="require('@/assets/images/explorerPageImg/explorer.webp')"
            :imageSrcMobile="require('@/assets/images/explorerPageImg/explorer-mob.webp')"
            :titleStart="$t('explorerPage.discoverPower')" :titleSpan="$t('explorerPage.buildingStability')" />
        <ExplorerMainSection />
        <DownloadSection />

    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import DownloadSection from '../components/homePage/downloadSection.vue';
import ExplorerMainSection from '../components/explorerPage/explorerMainSection.vue'
export default {
    name: 'ExplorerPage',
    data() { return {} },
    components: {
        FirstScreen,
        DownloadSection,
        ExplorerMainSection
    },
}
</script>
