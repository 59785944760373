import CryptoJS from 'crypto-js';

const keyValue = process.env.VUE_APP_CRYPTO_SECRET_KEY;
const ivKey = process.env.VUE_APP_CRYPTO_IV_KEY;


function encryptData(data) {
    if (data) {
        const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 });
        const iv = CryptoJS.enc.Utf8.parse(ivKey);
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    }
    return null;
}


function decryptData(data) {
    if (data) {
        const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 });
        const iv = CryptoJS.enc.Utf8.parse(ivKey);
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(data) }, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
    return null;
}


export const saveToLocalStorage = (key, data, ttl) => {
    const now = new Date().getTime();
    

    const cacheData = {
        data: data, 
        expiry: now + ttl
    };


    const encryptedData = encryptData(JSON.stringify(cacheData));

    if (encryptedData) {
        localStorage.setItem(key, encryptedData);
    } else {
        console.error("Error while encrypting data");
    }
};

export const getFromLocalStorage = (key) => {
    const encryptedItem = localStorage.getItem(key);
    
    if (!encryptedItem) {
        return null;
    }

    try {
    
        const decryptedData = decryptData(encryptedItem);
        
        if (decryptedData) {
            const item = JSON.parse(decryptedData);
            const now = new Date().getTime();

            if (now > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }

            return item.data;
        } else {
            console.error("Error while decrypting data");
            localStorage.removeItem(key);
            return null;
        }
    } catch (error) {
        console.error("Error retrieving and decrypting data from localStorage:", error);
        localStorage.removeItem(key);
        return null;
    }
};


export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
};
