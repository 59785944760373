export default {
	home: '主页',
	technology: '技术',
	ecosystem: '生态',
	community: '社区',
	partners: '合作伙伴',
	governance: '治理',
	explorer: '探索',
	news: '新闻',
	help: '帮助',
	webApp: 'Web app',
	dashboard: '概览面板',
	latestNews: '最新动态',
	galaxy: 'ATm银河系',
	features: '功能',
	gaming: '游戏',
	ecologi: '生态',
	ecologiWallet: 'Ecologi钱包:',
	traveling: '旅游',
	roadmap: ' 历程',
	travel: '旅行',
	apply: '立即应用',
	downloadAtmApp: '现在下载 atm.connect',
	letsConnect: '一起连接吧',
	subscribe: '订阅',
	enterEmail: '请输入你的邮箱...',
	name: '姓名*',
	emailAddress: '邮箱*',
	message: '你的信息..',
	submit: '提交',
	walletAddress: '钱包地址',
	junkTitle: '连接世界，连接元宇宙',
	junkContent: '解锁连接的力量，轻松地从ATM网络中获得奖励!',
	downloadAndroid: '下载安卓版',
	downloadIOS: '下载iOS版',
	about: '关于',
	strengths: '优势',
	create: '创造',
	income: '收入',
	learnMore: '了解更多PageRank服务',
	train: '训练',
	comingSoon: '即将到来',
	share: '分享',
	avatars: "阿凡达",
	viewAll: "查看全部",
	ended: "已结束",
	view: "查看",

	noData: '无数据',
	readMore: '阅读更多',
	noDataAvailable: '无数据可用e',
	notice: '关注',
	all: '所有',
	caunter: {
		learnMore: '了解更多'
	},


	title: {
		prAlgorithm: 'PR算法',
		autonomy: '自制',
		theTechnology: '技术',
		consensusConnection: '共识连接',
		resources: '资源',
		gamesFund: '游戏基金',
		thirdPartyEcosystem: '第三方生态系统',
		tokenPr: '代币PR',
		avatarsAI: '人工智能/阿凡达',
		pandoraPlanet: '潘多拉星球',
		communityFoundation: '社区基金',
		lucaRevards: 'LUCA奖励',
		messagingPlatform: '聊天平台',
		socialPlatform: '社交平台',
		autonomyProposal: '自治提案',
		proposalDecision: '提案和决策',

		usageGuide: '使用说明',
		atmConnect: ' ATM Connect',
		multiverse: 'atm 多元宇宙',
		connect: '连接到ATM',
		faq: '常见问答',

		gameLaunch: '游戏启动',
rewards: '奖励',
prRank: 'ATM PR 排名',
gameDev: '筹资与游戏开发'
	},

	homePage: {
		consensusStart: '一个元社区',
		consensusSpan: '基于我们最新的相对共识概念。',
		consensusEnd: '当结合我们的 ATM 排名算法时，这些强大的工具将使 DeFi 生态系统更加稳定，健康和繁荣。',
		consensus: '什么是相对共识？',
		rank: '什么是atm排名？',

		lucaConnections: '共识连接的数量',
		lucaStaked: '共识连接中质押数量',
		totalSupply: '总供给',

		recoveryPlan: '距离恢复计划结束还有',
		deflationMechanism: 'ATM通缩机制将实现于',
		deflationMechanismEnabled: ' ATM通缩机制从2024年7月24日起正式启动',
		days: '天',
		hours: '时',
		min: '分',
		fromDecisions: '从孤立的决定 – ',
		strengthsNumbers: '到数字的力量。',
		explorationStart: '银河探索图开启了一段令人兴奋的旅程，',
		explorationMiddle: '我们的去中心化 ATM 银河空间揭开了庞大节点网络的神秘面纱，用户可以无缝穿越并发现每个人独特的PR值，',
		explorationEnd: '将每一次连接转化为令人着迷的各种可能性。',
		exploreGalaxy: '点击探索ATM银河系',
		digitalSelf: '创建你的数字形象',
		earnRewards: '赚取奖励，提升你的PR值',
		connectFriend: '通过共识合约与朋友建立连接',
		aiSupport: '获得人工智能的专业支持',
		messagingPlatform: '利用我们的动态信息平台提升参与度',
		socialExperience: '重新定义社交体验',
		unleashInfluence: '释放你的影响力',
		voiceHoldsPower: '你的声音就是力量 – ',
		becomePartCommunity: '成为我们这个充满活力的社区的一员，留下你的印记 ',
		leadTheWay: '，引领我们走向崭新的明天 ',
		stepIntoWorldInfluence: '进入一个您的影响力举足轻重的世界。成为关键意见领袖（KOL），塑造 ATM 创新的未来',
		becomeKol: '即刻成为KOL',
		gamingDescription: '踏上激动人心的未来游戏之旅，创新无止境。游戏的未来超越娱乐，融合技术、创意和社区，打造身临其境的数字景观。',
		ecologiDescription: '介绍我们的环保举措： 每通过 Ecologi 建立一个新的连接，ATM 就会承诺种植一棵树。在享受无缝安全交易的同时，与我们一起对环境产生积极影响。从今天开始使用ATM，让您的财务足迹转变为更环保的未来！',
		travelingDescription: 'LUCA提升您的旅行体验。通过与旅行社的战略合作，LUCA打开了通往无限可能世界的大门。现在，用户可以无缝使用LUCA探索全球，开启便捷灵活的旅行新时代。',
		scan: '扫描并通过钱包地址与我们连接',
		subscribeATM: '订阅 ATM，接收最新活动和社区更新信息',
		fillContactForm: '有任何问题？只需填写联系表。我们期待您的来信！',
	},
	roadMap: {
		conceptBorn: '概念的诞生',
		conceptBornDesc: '我们发现，我们可以创建一个平台，利用区块链将人们连接到一个新型的社交网络 – 一个相对共识网络，我们希望这将是一个更稳定、更强大的经济体系。',
		smartContracts: '智能合约接受审计',
		smartContractsDesc: '作为加密货币社区信任灯塔的第一步，ATM 接受了 CertiK 网络安全公司的审计。',
		transitionPRNodes: '完成所有 PR 节点分散化的过渡',
		transitionPRNodesDesc: 'PR计算结果产生的所有数据都将完全去中心化，并存储在链上，从而使数据更加安全、不可变，从而更加可靠。',
		tokenPR: 'TokenPR',
		tokenPRDesc: '推出 Token PR，ATM 的代币发布平台。',
		avatarLaunch: 'Avatar AI 上线',
		avatarLaunchDesc: '我们的目标是通过强化 Avatar购买机制来提高LUCA的价值，从而增加稀缺性。我们的策略将侧重于根据 Avatar 的受欢迎程度逐步提升LUCA的破坏程度。我们的目标是增强社区能力，允许用户自由生成Avatar链接，参与多样化互动。',
		planComplete: '恢复计划完成',
		planCompleteDesc: '截至 10 月 21 日，取得的显著成就包括重大系统安全更新、重新启动奖励系统、成功完成铸币流程以及无缝奖励检索。',
		endRecovery: '恢复计划完毕',
		endRecoveryDesc: '从之前的事件中恢复',
		upgradingWebsite: '网站升级',
		upgradingWebsiteDesc: 'ATM网站的全新外观升级了概念可视化、动画插图和多语言支持，提升了用户在ATM上探索时的体验。',
		iOSAppLive: 'iOS 应用上线',
		iOSAppLiveDesc: '“ATM connect” iOS应用已在App Store上架，解锁了ATM生态系统的独家体验。',
		webAppRedesign: 'WebApp 重新设计',
		webAppRedesignDesc: '全新的WebApp现在配备了全新的界面，并提供了通过“ATM Connect”钱包连接到ATM的清晰指南。',
		travelAgencyCollab: '旅行社合作',
		travelAgencyCollabDesc: '与旅行社合作，探索LUCA更多真实世界的应用场景，让加密货币活起来。',
		AGFsmartContract: 'AGF 智能合约部署',
		AGFsmartContractDesc: 'AGF智能合约的部署为游戏基金奠定了基础，允许用户进行众筹，开发者进行授权。',
		AGFGameRelease: 'AGF 游戏发布',
		AGFGameReleaseDesc: '启动平台，吸引更多开发者加入生态系统，丰富社区用户的游戏体验。',
		deflation: '通缩计划',
		deflationDesc: '通货紧缩机制在价格下跌时增加了LUCA的价值，将负面因素转化为正面因素，并稳定LUCA的价值，以保障ATM的代币经济和生态系统。',
		airdropsDevelopment: '空投和生态系统发展',
		airdropsDevelopmentDesc: '与其他项目合作，发起空投并将其他社区引入ATM，促进更具包容性和功能性的生态系统建设。',
		january: '十月',
		october: '十月',
		february: '二月',
		q4: 'Q4',
		july: '七月',
		recovery: '恢复',
		website: '网站',
		ios: 'iOS',
		webApp: '网页应用',
		travel: '旅行',
		agf: ' ATM游戏基金（AGF）',
		game: '游戏',
		deflation: '通货紧缩',
		airdrop: '空投',
		emptyString: '-',
	}, prAlgorithm: {
		pageRank: '什么是PageRank？',
		pageRankDesc: 'PageRank是谷歌搜索引擎的核心算法，用于评估互联网上网页的重要性。它被称为“PageRank”，是因为该算法用于对网页进行排名，并且最初由谷歌联合创始人拉里·佩奇（Larry Page）提出。我们在原始PageRank算法中使用用户之间的共识连接来代替网页之间的链接，并计算ATM网络中每个用户节点的PR值，以表示用户在社区中的影响力。',
		strengthsPageRank: 'PageRank的优势',
		strengthsPageRankDesc: '在谷歌刚成立时，网站是通过流量排名的，而不是通过智能算法。这种方法不稳定且容易被利用，导致重要网站往往被忽略。<br/>通过考察网站之间的关系，而不是它们的网络流量，PageRank解决了这个问题。该算法天然对各种作弊网站具有抵抗力，只有有影响力的网站才能被找到。',
		createPRcode: '如何创建PR节点',
		createPRcodeDesc: 'A任何用户都可以构建ATM的PageRank计算服务器，并通过质押ATM的本地货币（LUCA）选出质押最高的前11台服务器。这些服务器共同执行PageRank算法，计算并同步ATM网络上所有用户的每日PR值。',
		incomePRnode: 'PR节点的的收入',
		incomePRnodeDesc: `操作奖励<br/>参与PageRank计算服务器质押的用户和服务器运营商将获得相应的节点操作奖励。<br/>质押奖励<br/>在服务器节点中具有较高质押比例的用户可以获得更多奖励。<br/>共识奖励<br/>用户之间的共识连接强度越大，通过PR计算能力分配的收入就越高。`,
	},
	technologyPage: {
		unlockNewCommunication: '开启通信新时代',
		sayGoodbyeToControl: '告别中心化管控，拥抱安全.',
		experienceToCommunicate: '体验真正的数据所有权和通信的力量',
		withoutSurveillance: '无需担心审查或监视。',
		tryPlatformMessaging: '今天就试试我们的平台，拥抱通讯的未来吧！',
		systemIntegration: '系统集成',
		systemIntegrationDesc: '我们革命性的ATMRank算法，受PageRank的启发，将在行业中设立新标准。这一算法的进化不仅仅是承认连接，而是放大它们，以释放加密货币的真正潜力。',

		systemIntegration: '系统集成',


		systemIntegrationDesc: '通过系统集成，我们的平台利用了革命性的ATMRank算法，该算法受到了突破性PageRank的启发。Lucy AI系统和ATMRank共同构成了我们生态系统的骨干。<span class="space"></span><br/> 每种加密货币在通过社区民主投票获得认可后，便可加入我们的平台。一旦集成，ATMRank算法会实时处理数据，并将估值结果写入智能合约。每种加密货币还可以通过Lucy无缝定制其自身的奖励系统算法。这种集成确保了ATMRank系统的持续优化和提升，使其始终处于加密货币估值的前沿。',

		mainTechnologyTextStart: 'ATM带来了更',
		mainTechnologyTextSpan: '稳定且强大的去中心化共识',
		mainTechnologyTextEnd: '！',
		consensusTitle: '究竟什么是共识连接',
		consensusDesc: 'ATM提供了一种称为共识合约的智能合约，允许用户在支持智能合约的多个公共区块链上进行连接。',

		consensusIncomeTitle: '共识连接收入',
		consensusIncomeDesc: `通过共识连接，用户可以根据他们的PR值获得奖励。每个公共链上持有的LUCA将被发送到一个公共存款智能合约中，用户可以随时提取。<br/>
   为此，用户发起申请，合约向ATMRank计算组接口发出请求，PR服务器集群计算应收奖励的价值并将其写入合约。记录写入后，用户发起提取收入的请求，合约等待超过一半的PR服务器节点确认。然后评估是否有足够的余额并处理用户的提取申请。`,
		viewMyIncome: '查看我的收入',
		createConsensusConnection: '如何创建共识连接',
		createConsensusConnectionDesc: '用户A设置一个共识合约，发起与用户B建立连接的请求。如果B同意，合约将被执行，共识连接将成功。',
		usersNeedConfirm: '双方用户需要确认：',
		lockedToken: "锁定代币：",
		lockedTokenDesc: "ATM支持的任何加密货币；",
		investmentAmount: '投资金额：',
		investmentAmountDesc: '双方同意的金额 - 用户间不需要相等；',
		lockUpTime: '锁定时间：',
		lockUpTimeDesc: '投资金额将被锁定的时间长度；',
		contractCancellation: '合同取消：',
		contractCancellationDesc: '在锁定期间，任何用户都不能单方面取消合同。他们必须等到合同结束或相互达成一致提前终止。到期后，如果双方均未决定取消，合同将继续有效。',
		viewMyConnection: '查看我的连接',
		smartContracts: '我们的智能合约',
		smartContractsDesc: `共识合约是ATM的基础技术特征。通过使用这些合约，可以在用户之间建立连接，并形成相对的共识网络。<span class="space"></span><br/>
   ATM还包括以下智能合约：`,

		LUCAContract: ' LUCA合约t',
		LUCAContractDesc: '这是指LUCA代币合约。LUCA是ATM社区的本地代币，并赋予用户对项目提案的投票权。',
		factoryContract: '工厂合约',
		factoryContractDesc: '工厂合约用于创建连接，然后调用相关的智能合约，以帮助用户获得相应的社区激励。【这是什么意思？】',
		stakerContract: '质押合约',
		stakerContractDesc: '质押合约用于在ATMRank服务器操作节点中进行投票，行使用户的投票权。',
		incentiveDistributionContract: '激励分配合约',
		incentiveDistributionContractDesc: '此合约负责在ATM社区中分配奖励，如ATMRank奖励、代币质押奖励等。',
		investmentLUCAcontract: '投资LUCA合约',
		investmentLUCAcontractDesc: '500万个代币将从官方LUCA合约中分发。通过部署该合约来释放LUCA。',
		LUCApromotion: 'LUCA推广奖励合约',
		LUCApromotionDesc: '这是奖励用户邀请他人加入ATM官方合作社交网络Monkey的合约。当用户通过Monkey邀请他人时，该合约将被调用。',
		crossChain: '跨链合约',
		crossChainDesc: '用户可以通过ATM官方支持的多个公共链实现跨链货币转移',
		wormholeContract: '虫洞合约',
		wormholeContractDesc: '虫洞合约同步ATM用户在多个链上的信息，以实现多链相对共识网络。',
		aboutOpenSource: '了解更多关于开源代码的信息',
		auditReport: '查看审计报告',
		auditReportDesc: '深入了解我们项目的详细文档，探讨其复杂性和坚实基础。',
		whitePaper: '查看白皮书',
		whitePaperDesc: '如果您对项目的幕后情况和智囊感兴趣，请查看我们的白皮书 - 它概述了我们的目标、抱负和技术解决方案。',
		messagingPlatform: 'Messaging platform',
		messagingPlatformDesc: 'Messaging platform description',
		socialPlatform: 'Social platform',
		socialPlatformDesc: 'Social platform description',
	},
	ecosystemPage: {
		empowerment: '通过去中心化赋权:',
		collectiveDestiny: '每个人共同塑造我们的集体命运',
		discoverGamingFund: '发现 ATM 游戏基金，社区支持推动创新，得到我们可信 VC 合作伙伴的支持，准备开启游戏新时代。ATM 社区拥有通过承诺投票决定哪些游戏实现的权力。玩家可以赚取 ATM 星并提升他们的游戏旅程，同时解锁 LUCA 奖励。',
		developersFundingOpportunities: ' 同时，开发者设定他们的融资目标和截止日期，有机会将他们的想法变为现实。加入后，开发者有机会接触到热情的玩家群体并实现他们的愿景。',
		learnAboutFunding: '了解更多关于融资的信息',
		submitProject: '提交项目',
		ecologyContent: 'ATM 自豪地宣布与 Ecologi 合作，Ecologi 是一个全球公认的 B-Corp 认证平台，致力于加速气候行动。我们共同致力于通过积极参与造林工作实现无碳未来。作为此次合作的一部分，ATM 承诺种植 100 棵树。这一承诺强调了我们减少碳足迹并为子孙后代创造一个更绿色的星球的决心。通过 ATM 连接到 Ecologi 钱包，您不仅确保了顺畅和安全的交易，还积极参与生态系统恢复。',
		travelRevolutionized: '我们正在彻底改变旅行体验。通过与顶级旅行社的战略合作，我们打开了无限可能的大门。LUCA 无缝集成到日常交易中，简化了全球探索，提供了无与伦比的便利性和灵活性。',
		seamlessTravel: '随着 LUCA 通过战略联盟融入日常生活，这不仅仅是关于旅行——这是关于提升每一刻。无论是预订航班、酒店还是活动，LUCA 都是首选货币。加入我们，迎接便利和灵活性重新定义旅行的未来。',
		thirdParty: '呼吁所有第三方开发者和平台！',
		joinTheATM: ' 加入 ATM 生态系统社区，扩展您的服务范围。',
		utilizeOurATM: '利用我们的 ATM 智能合约和排名算法吸引更多用户并推动您的项目和代币。',
		embracingIntegration: '为了支持开发，我们选择集成众多第三方应用程序。我们的开源代码可供所有开发者使用。ATM 的愿景是扩大第三方选项，增强我们的基础设施，为开发者提供更强大和稳定的平台。',
		binance: '币安',
		utilizingBUSD: 'ATM 社区可以使用 BUSD 作为治理代币 LUCA 的交易对。它也可以用作共识合约创建的‘锁仓代币’。',
		bitGo: 'BitGo',
		WBTCpayments: '用户可以在创建共识合约时使用 WBTC 作为支付代币。',
		chainlink: 'Chainlink',
		chainlinkOracle: 'Chainlink 将用作‘预言机’来实时更新 LUCA 和其他代币的价格。',
		ETH: '以太坊',
		ETHissuance: 'ATM 将在以太坊公链上发行，活动也将在这里进行。',
		pancake: 'Pancake',
		stakingRewards: '社区可以在这里开始质押挖矿，用户可以存入 LUCA/BUSD 以获得稳定收入。',
		communityEcosystem: '代币 PR 是 ATM 的开放去中心化社区生态系统之一。 ',
		tokenIntegration: '第三方项目可以加入 Token PR 并将其项目代币添加到 ATM 生态系统中。通过访问 PR 算法，他们奖励使用项目代币进行连接的用户，',
		dualIncentives: ' 激励 ATM 用户和项目用户，并为用户提供更好的生态系统服务。',
		applicationProcess: ' 如何加入代币PR',
		consensusRewards: '第三方项目通过社区提案加入 ATM 生态系统后，用户可以使用项目方的代币创建共识连接，并通过 ATM PR 算法计算代币的 Token PR 值。ATM 将根据 Token PR 值和项目方的奖励分配比例计算并分配代币的共识收入给用户。',
		rewardModes: 'ATM 项目的奖励模式',
		tapNumbers: '点击数字查看三种奖励模式',
		learnRewards: '了解更多关于奖励的信息',
		joinNow: '立即加入',
		automaticDistribution: '所有代币奖励都是整体分配，无需计算 Token PR。奖励根据主要 PR 权重分配；',
		dualDistribution: '代币奖励由整体分配和独立分配组成。例如，整体奖励占 40%，独立奖励占 60%。需要计算 Token PR。整体奖励根据主要 PR 权重分配，独立奖励根据 Token PR 权重分配；',
		tokenBased: '代币奖励全部为独立分配，需要计算 Token PR。奖励根据 Token PR 权重分配。',
		rewardExample: '奖励示例',
		rewardDistribution: '第三方代币COOL连接ATM生态系统，并提供一百万个代币用于奖励分配，为期100天，每天10,000个代币。',
		dualRewards: '奖励分配比例：整体奖励占60%，独立奖励占40%。<br/>当用户使用COOL创建共识时，他们将从主PR计算和Token PR计算中获得奖励。',
		sharedAllocation: '换句话说，整个网络的用户每天根据主PR权重共享6,000个COOL代币，使用COOL创建共识连接的用户每天根据Token PR权重共享4,000个COOL代币。',
		IveUnderstood: '我明白了',
	},
	communityPage: {
		empowerConnectivity: '释放连接的力量：',
		decentralizedEngagement: '通过去中心化重新定义社区互动 - 社区页面的标题',
		hiLucy: '向Lucy打个招呼！',
		meetLucy: '认识Lucy，您的下一代对话代理，她经过专业训练，能够轻松回答您的问题。对LUCA、ATM-Rank或Consensus Connections感到好奇吗？<br/>Lucy为您解答。凭借先进的自然语言处理技术，她能识别您的意图并提供准确的信息。体验Lucy带来的便利，她将专业地引导您了解ATM。',
		chatLUCY: '与LUCY聊天',
		transformingInteractions: '在我们的ATM社区中，我们正在改变人与AI互动的方式。想象一个人与AI无缝共存的世界，<span style="font-weight: 600;">模糊界限并丰富每一次互动。我们的创新AI技术将这一愿景变为现实，创造一个充满活力和动态的社区，可能性无穷无尽。</span>',
		meetTeam: '认识团队',
		unlockExpertise: '为研究人员、记者和商业顾问提供的金融专家团队。无论是在探索、从事项目还是做出关键决策时，我们的AI代理都会在每一步为您提供帮助。<br/><br/>创新预言机提供全面、个性化的概览。体验我们AI代理的强大功能，开启新的可能性！',
		chatWithExperts: '与我们的专家聊天',
		autonomousGovernance: '社区基金会属于所有用户。其<span style="font-weight: 600; color: #1C1C1C;">账户是完全自治的，基金会钱包的私钥由基金会董事共同持有。</span><br/><br/> 基金管理委员会由社区内PR值最高的47名用户组成。董事会拥有执行权，但<span style="font-weight: 600; color: #1C1C1C;">最终决定权始终属于所有成员，成员通过对提案进行投票来决策。</span><br/><br/><span style="font-weight: 600; color: #1C1C1C;">资金需要超过半数董事会成员的批准。</span> 不活跃的成员将被算法替换，由PR值次高的用户接替其董事职位。',
		earnLUCA: '通过共识连接和质押LUCA赚取LUCA奖励，ATM的原生加密货币。<span style="font-weight: 600;">深入我们的奖励生态系统，85%的每日发行量用于共识连接奖励，基于您的PR值分配。剩余的15%用于质押奖励，顶级用户有资格获得发行。</span><br/><br/> 直接质押LUCA或通过共识合同质押，以提升网络安全并获取奖励。<span style="font-weight: 600;">LUCA具有多种用途，从奖励共识连接到促进无缝交易。</span> 记住，PR值越高，LUCA奖励越多！',
		engageDynamically: '我们的信息交流平台促进了ATM社区内的热烈讨论。无论您是经验丰富的爱好者还是新手，都可以在这里连接他人，分享见解，了解最新趋势。创建自定义聊天室，加入现有的聊天室，或进行直接消息交流。保持信息灵通，交换意见，建立联系。',
		seamlessInteractions: '无缝互动',
		discoverConnectEngage: '发现、连接、参与',
		connectInformatively: '我们的平台是一个动态中心，供成员分享新闻、帖子和见解，促进对ATM生态系统相关主题的深入讨论。保持更新，与爱好者互动，并在一个无缝、直观的空间中建立联系。利用互动工具自定义您的体验，打造个性化的社交体验。',
		communityGovernance: 'ATM是一个去中心化的分发机制。这意味着用户需要像管理一个社区一样管理它，以便让它能够不断发展并保持相关性。<span style="font-weight: 600;">我们希望这在经济环境不可预测变化时提供稳定性。</span> 简而言之，社区通过对提案进行投票来管理ATM的功能、算法和技术架构。',
		conditionalApproval: '不同提案在不同条件下通过',
		viewConditions: '点击数字查看条件',
		whatAvatar: '什么是AVATAR？',
		consciousnessExtension: 'AVATARS通过扩展您的意识和增强您在虚拟领域的能力来重新定义数字存在。它们不仅仅是助手，而是代表您生活各个方面的存在——从专业到个人领域。',
		craftAvatar: '打造您的AVATAR',
		creationEmpowered: '有了Avatar，创造的力量掌握在您手中。只需编写提示，观看您的愿景在眼前成真。无论您想象的是一个干练的专业人士还是一个异想天开的冒险者，Avatar都能扮演您所希望的任何角色，将您的数字梦想变为现实。',
		trainAvatar: '训练它像您自己一样',
		dynamicEvolution: '您的Avatar不仅是一个静态的创造物；它是一个随着您不断进化的动态实体。通过个性化的训练课程，您可以上传文档并进行问答，以精炼Avatar的意识。观看它从您的行为、兴趣和偏好中学习，成为您数字自我的准确反映。',
		shareAvatar: '与朋友分享您的AVATAR',
		avatarNetworking: '一旦您打造了完美的Avatar，就该与朋友分享这份魔力了。只需点击一下，您就可以生成一个AVATAR链接，邀请他人与您的数字创作互动。无论是将您的Avatar介绍给社交圈还是通过他们的Avatar与朋友建立联系，有意义的互动的可能性是无限的。',
		discoverDigitalSelf: '通过',
		agt: 'AGT',
		fullNameofAGT: 'AGT的全称是ATM治理代币。',
		receiveAGTdistributed: '用户在与LUCA建立共识连接后，可以收到ATM分发的AGT',

		dataNoLoaded: '您所需要的数据未能加载',
		checkBackShortly: '请稍后再看或刷新页面。很抱歉对您造成的不便',
		underReview: `审查中`,
		successfulAudit: `审核成功`,
		auditFailed: `审核失败`,
		inProgress: `进行中`,
		ended: `结束`,
		failure: `失败`,
	},
	newsPage: {
		stayAhead: '紧跟实事，保持领先',
		innovationNews: ' 深入最新的科技与创新新闻',
		notice: '关注',
		hotNews: '热点新闻',
	},

	helpPage: {
		wealthOfResources: '深入探索我们的丰富资源，做好准备',
		conquerTheIntricacies: ' 自信且熟练地掌握ATM的复杂性',
		understandingTechnology:
			"如果您是ATM的新用户并希望更好地了解这项技术，我们提供了一系列关于我们核心应用程序和功能的教程。从基本概念和白皮书解释到更深入的应用操作，我们的目标是让您对ATM有更全面的了解。"
		,
		ATMcourse: 'ATM课程',
		courseContains: '本课程包含涵盖ATM平台基本部分的初学者教程。',
		connectMetaMask: '连接MetaMask钱包',
		participateInPR: '如何参与PR节点质押和选举',
		howAddLiquidity: '如何添加LUCA/BUSD流动性',
		createConsensusConnection: '创建共识连接',
		queryConnectionByAddress: '按用户地址查询共识连接',
		checkIncome: '如何检查并领取总收入',
		purchaseLUCA: '如何购买LUCA',
		initiateCommunityProposal: '如何发起社区提案',
		seamlessConnectivity: '解锁终极便利，使用<span class="bold-text">ATM Connect应用 – 这是您随时随地无缝连接的首选解决方案！无缝连接</span>好友，访问您的钱包，跟踪您的连接，并进入个性化仪表板，一切尽在掌握之中。',
		getConnent: '立即下载ATM Connect应用！',
		supportedChains: '支持的公链',
		upcomingChains: '即将支持的公链',
		installingWallet: '安装您的钱包*',
		connectingATM: '连接ATM',
		fundingWallet: '为您的钱包注资',
		needConnectedWallet: '使用ATM，您需要一个连接到币安智能链的钱包。',
		downLoadMetaMask: '1. 点击下方按钮“获取MetaMask”下载并安装MetaMask。',
		followMetaMaskSetup: '2. 按照MetaMask的设置流程完成安装。',
		getMetaMask: '获取MetaMask',
		metaMask: 'MetaMask',
		onceCompleteClickButton: '3. 完成后，点击我们提供的“添加BSC网络”按钮，自动切换网络。',
		addBSCnetwork: '添加BSC网络',
		reopenWebpage: '4. 如果您使用的是移动设备，请通过MetaMask应用重新打开此网页。（菜单 -> 浏览器）',
		recommendAddCurrencies: '5. 我们还建议您将LUCA、BUSD和AGT添加到您的钱包中。您可以通过点击以下按钮来完成。',
		clickConnect: '点击“连接ATM”并签署消息 - 这将您的钱包与网站链接。',
		totallySecure: '别担心，这完全安全 - 这只是让我们知道您确实是钱包的所有者！',
		dashboardHasInformation: '• 您的仪表板包含了使用ATM所需的所有信息。',
		beginBuildingNetwork: '• 浏览仪表板后，点击“创建连接”开始构建您的社交网络！',
		beginYourJourney: '好了，就这样！点击下方按钮并签署消息，开始您的ATM之旅：',
		gotNewWallet: '如果您有一个全新的钱包，您需要为其注资才能使用币安智能链，BNB作为交易处理费用。',
		canBuyBNB: '您可以通过MetaMask使用第三方支付选项MoonPay和Transak直接购买BNB。',
		buyWithMoonpay: '通过MoonPay购买BB',
		supportsPaymentMethods: 'MoonPay支持流行的支付方式，包括Visa、Mastercard、Apple / Google / Samsung Pay，以及145多个国家的银行转账。代币将存入您的MetaMask账户。',
		buyFromBinance: '您也可以通过Binance购买并单独发送到您的钱包（我们推荐给更有经验的加密用户）。',
		swapToLuca: '一旦您拥有一些BNB，您可以通过MetaMask将其兑换为LUCA，然后可以在网络中进行质押。如果您是更有经验的用户，您也可以通过Pancakeswap购买LUCA。',
		metaMaskSuggestedWallet: '*MetaMask和币安智能链是第三方产品，并非ATM的产品。MetaMask是我们推荐的钱包，但也有其他选择。请自行斟酌使用。'
	},

	faq: {
		problemTitle: ['关于ATM', '关于LUCA', '关于社群', '关于治理'],
		qaATM: [
			{
				question: 'ATM是什么？',
				answer: 'ATM是一套基于多条区块链，利用“共识合约”构造出钱包网络（即用户共识网络）的去中心化机制。'
			},

			{
				question: '谁能使用ATM？',
				answer: '所有拥有MetaMask且成功创建BSC网络的钱包用户。'
			},
			{
				question: 'ATM是开源的吗？',
				answer: 'ATM是去中心化开源社区。'
			},
			{
				question: 'ATM的整体架构是什么？',
				answer: 'ATM机制主要由共识合约、PageRank算法、LUCA发行机制、社群自治四个部分组成。'
			},

			{
				question: '哪里能看到ATM的代码？',
				answer: '点击<a href="https://github.com/ATM-Developer/atm-contract" class="link" target="_blank">这里</a>即可查看ATM代码。'
			},
			{
				question: '如何创建连接？',
				answer: '进入【共识连接】页面，点击【创建共识】按钮，填写相关信息，即可创建共识连接。详情可查看ATM操作教程。'
			},
			{
				question: '如何取消连接?',
				answer: '进入【共识连接-已连接】页面，点击【查看详情】，在连接详情页面点击【断开连接】。详情可查看ATM操作教程。'
			},
			{
				question: '最多可以创建多少条连接？',
				answer: '连接数量没有上限，可以任意创建。'
			},
			{
				question: 'ATM可以连接多少条公链？',
				answer: '目前ATM社群支持公链：Binance。即将支持：Matic、OKExChain、Theta、Ethereum。'
			},
			{
				question: 'ATM可以连接任意公链吗？',
				answer: '除了目前已支持的公链外，社群用户可通过发布提案，增加可连接的公链。'
			},
			{
				question: 'ATM是金字塔骗局还是MLS？',
				answer: `很抱歉要让你们中那些多疑的人失望了，但不，它不是！ <br>
				ATM算法，被巧妙地命名为ATMRank，基于Google的PageRank。相似之处不仅在名称上，还在代码上 - 你可以亲自看到，它是开源的。你会说Google的
PageRank是一个金字塔计划吗？ 🙂<p class="split"> </p>
				
				ATM的奖励完全取决于你作为一个“网红”的影响力（抱歉，手头没有更合适的表达...） - 虽然早期采用者得到了显著的奖励，但这并不意味着他们会因为你加入网络而受益，就像在经典的金字塔骗局中那样。
				<p class="split"> </p>
				奖励机制是一个零和博弈，这意味着你只能增加你在蛋糕中的份额，但蛋糕本身永远不会增长 - 对于那些讨厌的金字塔顶端用户来说，这几乎不是一笔划算的交易，对吧！？<br>
				而且别忘了，你甚至不需要邀请就可以加入ATM - 这与金字塔骗局恰恰相反！`
			},
			{
				question: ' 哪里可以找到并下载ATM手机应用呢？',
				answer: '如要下载ATM手机应用，可以直接打开App Store（iOS或者Google Play商店）。在搜索栏搜索“ATM connect’”来查找应用。找到正确的应用软件后，根据相关提示下载并安装在你的设备上。'
			},
			{
				question: '怎么用MetaMask钱包连接? ',
				answer: '想要连接到MetaMask钱包，在Google Chrome浏览器上直接查找MetaMask， 并从官方网站点击【DownLoad】按钮下载。添加MetaMask到Chrome浏览器，然后点击ATM官网右上方”Web App“按钮来使用你的MetaMask钱包。连接快乐！<br> 想要连接到ATM，先确保你的钱包已经连接到Binance Smart Chain（币安智链）。安装MetaMask， 并用MetaMask登陆ATM，添加”BSC network“。为了您更好的体验，添加LUCA，USDC and AGT 到你的钱包。 你可以在任何时间点击“Web App”来访问钱包。为了方便起见，下载ATM connect 应用来获得更好体验！'
			},

		],
		qaLuca: [
			{
				question: '如何获得LUCA？',
				answer: '1.参加ATM空投活动获得空投；2. 在 Pancakeswap 中为LUCA/BUSD 交易对提供流动性获得流动性收益奖励；3.创建共识连接；4.质押挖矿。'
			},
			{
				question: '哪里能购买LUCA？',
				answer: 'LUCA空投活动结束后，可以在Pancakeswap中兑换，也可通过流动性收益、共识连接奖励等形式获得。'
			},
			{
				question: 'LUCA的发行总量是多少？',
				answer: '初始发行1500万枚，每日增发36000枚，发行1000天后，开启通缩机制。'
			},

			{
				question: '持有LUCA能带来什么收益？',
				answer: '1.使用LUCA建立的共识连接收益最大化；2.质押LUCA可获得挖矿收益；3. 持有LUCA即持有社群自治权益，可以参与提案投票。'
			},
			{
				question: 'LUCA代币有销毁机制吗？',
				answer: '当LUCA增发1000天后，如果 LUCA 当日平均价格对比前一日平均价格下跌 n%，则所有 ATM 支持的公链上的 LUCA 流通量将集体通缩 n%（共识合约中的 LUCA 不受通缩影响）。'
			},
			{
				question: '如何成为节点质押者？',
				answer: '进入【共识连接-PR节点】页面，选择一个节点，点击质押，输入金额并确定质押。详情可查看ATM操作教程。'
			},
			{
				question: '哪里查看质押收益？',
				answer: '进入【共识连接-总收益】页面，可以查看质押收益。'
			},
			{
				question: '如何将以太坊主网代币转换为币安智能链',
				answer: '跨链转移代币的能力是一个基本需求，它允许用户将资金从一个区块链网络转移到另一个。现在，多个网络都有各自的“桥梁”来帮助轻松进行资金转移。以下是支持币安智能链的所有跨链应用的列表，其中大多数提供教程来帮助您使用它们。<br>  <a  target="_blank" href="https://docs.binance.org/smart-chain/guides/cross-chain.html" class="link">https://docs.binance.org/smart-chain/guides/cross-chain.html</a>'
			},
		],
		qaCommity: [
			{
				question: '哪里查看提案进展？',
				answer: '进入【社区自治-查看提案】页面，可以查看提案进展。'
			},
			{
				question: '如何加入董事会？',
				answer: '董事会由47名社群内PR值最高的用户组成。如果某一成员长期不活跃，将被取消资格，并由下一位PR值最高的用户代替。'
			},
			{
				question: '董事会的作用是什么？',
				answer: '董事会成员共同保管基金会钱包秘钥，超过半数成员签名才可使用基金。董事会成员行使执行操作权。'
			},
			{
				question: '如何获得ATM官方动态？',
				answer: '进入ATM官网【新闻】页面，即可查看。'
			},

			{
				question: '如何联系我们？',
				answer: '用户可发送邮件至：autonomoustrustmomentum@gmail.com。'
			},
		],
		qaZhili: [
			{
				question: 'AGT有什么用途？',
				answer: '社群提案功能通过社群治理代币AGT来实现，用户持有AGT即持有投票权。'
			},
			{
				question: '普通提案跟特殊提案的区别？',
				answer: '（1） 普通提案，社群成员可投同意/反对票，投票总数超过AGT总流通量的2%即为有效提案，其中同意票数占2/3以上则提案通过，开始执行，否则视为提案失败，不执行。（2） 特殊提案，提案发起人需事先将提案的生效及执行条件在社群公示，公示期结束，则可进入提案流程，若公示期有异议，则需修改条件再次公示。'
			},
			{
				question: '如何获得AGT？',
				answer: ' 用户使用LUCA成功创建共识连接后，即可获得ATM社群发放的AGT。'
			},
			{
				question: 'AGT是社群唯一治理代币吗？',
				answer: 'AGT代币为ATM社群的唯一治理代币。'
			},
			{
				question: '提案结束，能拿回AGT吗？',
				answer: ' 每次提案投票结束后，社群用户可赎回AGT用于其他提案的投票。'
			},
			{
				question: ' 持有多少个AGT才能行使社群治理权？',
				answer: '至少持有1个AGT才能行使社群治理权。'
			},
			{
				question: '如何提案？',
				answer: '目前只能由ATM官方发起提案，如需提案，可向ATM官方发送邮件，提起申请（官方邮箱：autonomoustrustmomentum@gmail.com）。后期将逐步开放提案功能。'
			},
		],
	},
	gamingPage: {
		gamingPageDescription: '我们决定启动一个游戏基金来<span class="bold-text">支持游戏开发者，并推出我们自己的ATM游戏。每个ATM用户都可以玩游戏、享受乐趣并获得奖励。游戏开发者需要设定他们的筹资目标和截止日期</span>（例如，设定游戏发布的日期）。如果ATM用户喜欢这款游戏，他们可以承诺资金来推动游戏的开发。',
		usersWillEarnStars: '通过玩游戏，ATM用户将赚取ATM星星并提升他们的游戏等级，这将帮助他们赚取更多的<span style="color: #0DAEB9; font-weight: 700;">LUCA</span>奖励。',
		gameLaunchDescription: '当玩家与游戏建立联系时，他们的PR会大幅提升。同时，<span class="bold-text">游戏钱包每天都会从用户连接中积累PR收入</span>。每天所有ATM PR的一部分将直接用于ATM游戏基金，<span class="bold-text">确保ATM投资者获得全额退款</span>。</br></br>我们的系统确保用户与他们的游戏钱包之间建立标准化连接，完全基于他们的贡献金额和连接天数。用户的<span class="bold-text">PR值将受到他们积累的ATM星星和游戏等级进度的影响。这种透明的方法确保了所有玩家的公平性和一致性</span>，从而提升他们的游戏体验。',
		fundsFullyRefunded: '当资金全部退款时，',
		usersInvestors: '70%将返还给用户（玩家），30%将返还给ATM投资者',
		dailyFromGames: ' 每天来自所有游戏。',
		howGetStars: '如何获取ATM星星',
		creatingConnectionWithGame: '用户通过与游戏建立100%连接（例如，100LUCA持续90天）将获得ATM星星；',
		winningBattle: '通过赢得战斗。如果玩家输掉战斗，他们将失去ATM星星（即他们在战斗中投入的数量）；',
		unlockFeaturesWithStars: '<span class="bold-text-white">使用ATM星星解锁令人兴奋的功能！购买独家战斗的门票、NFT服装和物品</span>。与游戏断开连接时，游戏钱包将自动从您的账户中扣除星星，确保公平和平衡。如果您的星星余额不足，它将重置为零，让每个人都有一个新的开始。',
		getRewardsBy: 'ATM用户将通过以下方式获得奖励：',
		connectionsWithGames: '与游戏建立连接',
		playingGames: '玩游戏',
		investingInGames: '投资游戏',
		receivingRewards: '从星星池中每天获得奖励',
		performanceRanking: 'PR值将取决于玩家的ATM游戏等级和ATM星星。将有标准化的ATM XP，用于ATM排名。',
		atmXP: 'PR = PR × 标准化 [ATM XP]',
		atmGameLevel: 'ATM XP = ATM星星 + 标准化 [ATM游戏等级]',
		previewVideo: '点击播放按钮预览视频',
		crowdfundingPitch: '开发者分享他们的游戏概念，提供描述，可能包括图片，说明所需的资金，并设定筹资目标的截止日期。',
		investPreferredGames: 'ATM社区中的每个人都可以投资他们喜欢的游戏（不仅限于LUCA，还可以使用其他方式）。',
		fundsReturnedToInvestors: '如果资金未达到目标，提案将被拒绝，资金将返还给投资者。',
		fundsReachedGoal: '如果资金达到了目标，它们将转交给开发者。'
	},
	explorerPage:{
		discoverPower: '发现LUCA的力量：',
		buildingStability: '通过共识建立稳定性',
		overview: '概览',
		galaxy: '银河',
		overallRank: '总体排名',
		consensusConnection: '共识连接',
		prNode: 'PR节点',
		stakeTransaction: '质押交易',
		userInformation: '用户信息',
		contractInformation: '合约信息',
		price: 'LUCA价格',
		totalSupply: '总供应量',
		marketCirculation: '用户市场流通',
		circulatingSupply: '流通供应量',
		liquidityRewards: '剩余流动性奖励',
		stakedConnections: '在共识连接中质押的LUCA',
		stakedPRservers: '在PR服务器中质押的LUCA',
		remainingFund: '剩余社区基金',
		LUCAConsesusConnections: 'LUCA共识连接',
		prServersOperation: '运行中的PR服务器',
		LUCAoverview: 'LUCA概览',
		proportionOfLuca: '网络中LUCA发行的比例',
		statistics: '统计',
		more: '更多',
		less: '更少',
		currencyStaked: `{currency} 在共识连接中的质押`,
		numberOfCurrency: `{currency} 连接的数量`,
		currencyContractAddress: `{currency} 合约地址`,
		currencyPriceTrend: `{currency} 价格趋势`,
		price: '价格',
		noData: '数据当前不可用',
		rankingDataUpdatedDaily: '排名数据每日更新。最新更新：',
		ranking: '排名',
		userAddress: '用户地址',
		PRvalue: 'PR值',
		network: '网络',
		currency: '货币',
		connectionContractInfo: '连接合约信息',
		initiator: '发起人',
		hash: 'Hash',
		receiver: '接收者',
		connectionQuantity: '连接数量',
		time: '时间',
		action: '操作',
		more: '更多',
		PRnode: 'PR节点',
		stakeAmount: '质押金额/限制',
		stakeTransactionInfo: '质押交易信息',
		PRnodeInfo: 'PR节点信息',
		serverName: '服务器域名',
		serverIP: '服务器IP',
		serverNickname: '服务器昵称',
		stakeAmount: '质押金额',
		status: '状态',
		statusList:[
			'发起连接',
			'连接中',
			'发起人取消',
			'目标用户拒绝',
			'到期赎回未转移',
			'到期赎回已转移',
			'提前赎回未转移',
			'提前赎回已转移'
		],
		creationTime: '创建时间',
		connectionQuantity: '连接数量',
		NFTproject: 'NFT项目',
		NFThedging: 'NFT对冲',
		connectionContract: '连接合约',
		lockTime: '锁定时间',
		days: '天',
		transactions: '交易',
		contractBalance: '合约账户余额',
		lastBalanceUpdate: '最后余额更新：区块 #',
		viewAll: '查看全部',
		viewSourceCode: '查看源代码',
		viewConnections: '查看所有连接',
		viewTransactions: '查看所有交易',
		PRnodeDetails: 'PR节点详情',
		number: '{number}',
		nodeRanking: '节点排名',
		nodeStakeDetails: '节点质押交易详情',
		stakeMethod: '质押方式',
		LUCAstake: 'LUCA质押',
		consensusContract: '共识合约',
		stakeNode: '质押节点'
	}

}; 