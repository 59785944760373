<template>
    <div class="video-section" ref="sectionRef">
      <div class="video-section-title">
        <h3>
          {{$t('communityPage.discoverDigitalSelf') }} <span> {{$t('avatars') }}</span>
        </h3>
      </div>
  
      <div class="avatars-wrapper animate">
        <div class="avatars">
          <img v-for="(avatar, index) in avatars" :key="index" :src="avatar.src" :alt="`Avatar ${index + 1}`" class="avatar-image" />
        </div>
  
        <div class="play-button" @click="showModal = true">
          <img src="../../assets/images/icons/play-button.svg" alt="play-button">
        </div>
      </div>
  
      <ModalVideoPlayer :showModal.sync="showModal" :videoSrc="videoSrc" @update:showModal="updateShowModal"/>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import ModalVideoPlayer from '../../components/common/modalVideoPlayer.vue';
  import useScrollAnimation from '../../hooks/useScrollAnimation';
  
  export default defineComponent({
    name: 'VideoSection',
    components: {
      ModalVideoPlayer
    },
    data() {
      return {
        avatars: [
          { src: require('../../assets/images/communityPageImg/avatar-images/avengers.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/basketball.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/fortnitestyle.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/gamecharacter.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/green-villain.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/superwomen.webp') },
          { src: require('../../assets/images/communityPageImg/avatar-images/white-hair.webp') }
        ],
        showModal: false,
        videoSrc: '/images/communityPage/avatar-promo.mp4'
      };
    },
    setup() {
      const { elementRef } = useScrollAnimation('.video-section')
  
      return {
        elementRef
      };
    },
    methods: {
      updateShowModal(value) {
        this.showModal = value;
      }
    }
  });
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/style/communityPage/videoSection.scss';
  </style>