<template>
    <Section :title="$t('title.prRank')">
        <div class="pr-rank-section">

            <div class="pr-rank-description">
                <div class="body-text">
                    <p>{{ $t('gamingPage.performanceRanking') }}</p>
                </div>
            </div>


            <div class="pr-rank-wrapper">
                <div class="pr-rank-content">

                    <div class="pr-rank-item">
                        <div class="small-body-text">
                            <p>{{ $t('gamingPage.atmXP') }}</p>
                        </div>
                        <div class="pr-rank-content-img">
                            <img :src=prRankImage alt="">
                        </div>
                    </div>

                    <div class="pr-rank-item">
                        <div class="small-body-text">
                            <p>{{ $t('gamingPage.atmGameLevel') }}</p>
                        </div>
                        <div class="pr-rank-content-img">
                            <img :src=formulaImage alt="">
                        </div>
                    </div>

                </div>

                <div class="pr-rank-image">
                    <img src="../../assets/images/gamingPageImg/atmprrank-card.webp" alt="">
                </div>
            </div>
        </div>
    </Section>
</template>

<script>

import Section from '../../components/common/section.vue';
import useDarkMode from '@/darkMode';
export default {
    name: 'prRank',
    components: {
        Section,
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        prRankImage() {
            return this.isDark
                ? 'images/gamingPage/rank-calc-dark.gif'
                : 'images/gamingPage/rank-calc.gif';
        },
        formulaImage() {
            return this.isDark
                ? 'images/gamingPage/formula-dark.webp'
                : 'images/gamingPage/formula.webp';
        },
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/gamingPage/prRank.scss';
</style>