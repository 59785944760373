<template>
    <div ref="chart" class="pie-chart"></div>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
  import * as echarts from 'echarts';
  
  export default {
    name: 'GamePieChart',
    props: {
      chartData: {
        type: Array,
        required: true
      }
    },
    setup(props) {
      const chart = ref(null);
      let myChart = null;
  
      // Function to update the chart based on data
      const updateChart = () => {
        if (!Array.isArray(props.chartData)) {
          console.error("Expected an array for chartData");
          return;
        }
  
        const totalContribution = props.chartData.find(item => item.name === 'Total contribution')?.value || 0;
        const goal = props.chartData.find(item => item.name === 'Goal')?.value || 0;
        const percentage = goal === 0 ? 0 : ((totalContribution / goal) * 100).toFixed(0);
  
        const screenWidth = window.innerWidth;
  
        // Set text size based on screen width
        const fontSize = screenWidth < 768 ? 14 : 20;  // Smaller font for mobile devices
  
        // Set line height based on font size
        const lineHeight = fontSize * 1.5;  // Adjust line height for readability
  
        const option = {
          color: ['#3E688C', '#D4E3EF'],
          tooltip: {
            trigger: 'item',
            formatter: (params) => params.name
          },
          series: [
            {
              name: 'Outer Ring',
              type: 'pie',
              radius: ['50%', '70%'],
              data: [
                { value: totalContribution, name: 'Total contribution' },
                { value: goal - totalContribution, name: 'Remaining' }
              ],
              minAngle: 2,
              itemStyle: {
                borderRadius: 10,  // Round the edges of each pie slice
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: false // No labels on the chart itself
              }
            }
          ],
          // Custom center text for percentage
          graphic: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                text: `${percentage}%\nof the funds`, // Text with new line
                textAlign: 'center',
                fill: '#3E688C',
                fontSize: fontSize,
                fontWeight: 'bold',
                lineHeight: lineHeight
              }
            }
          ]
        };
  
        if (myChart) {
          myChart.setOption(option);
        }
      };
  
      // Resize the chart on window resize
      const resizeChart = () => {
        if (myChart) {
          myChart.resize();
        }
      };
  
      // Initialize the chart and set up resize listener
      onMounted(() => {
        if (chart.value) {
          myChart = echarts.init(chart.value);
          updateChart(); // Initial chart setup
          window.addEventListener('resize', resizeChart); // Add window resize listener
        }
      });
  
      // Cleanup before unmount
      onBeforeUnmount(() => {
        if (myChart) {
          window.removeEventListener('resize', resizeChart); // Clean up resize listener
          myChart.dispose(); // Dispose chart to free resources
        }
      });
  
      // Watch for changes in chartData
      watch(() => props.chartData, () => {
        nextTick(() => {
          updateChart(); // Update chart whenever chartData changes
        });
      });
  
      return {
        chart
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .pie-chart {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 500px) {
    .pie-chart {
      height: 250px;
    }
  }
  </style>
  