import en from './langData/en.js' 
import cn from './langData/cn.js' 
import ru from './langData/ru.js'


export const defaultLocale = 'en'
export const messages = {
  en, 
  cn,
  ru
}
