   <template>
    <Section :title="$t('title.resources')" class="resources-section">
      <div class="buttons-wrapper">
        <div class="button-left" @click="switchElement('left')" :class="{ 'button-disabled': disableLeft }"
          :disabled="disableLeft">
          <img v-if="!disableLeft" src="../../assets/images/icons/array-right.svg" alt="Active Image" />
          <img v-else src="../../assets/images/icons/array-right-disabled.svg" alt="Inactive Image" />
        </div>
        <div class="button-right" @click="switchElement('right')" :class="{ 'button-disabled': disableRight }"
          :disabled="disableRight">
          <img v-if="!disableRight" src="../../assets/images/icons/array-right.svg" alt="Active Image" />
          <img v-else src="../../assets/images/icons/array-right-disabled.svg" alt="Inactive Image" />
        </div>
      </div>
    </Section>
  
    <div class="resources-container">
      <div class="resources-images">
        <div class="resourse-img" :class="{ active: activeIndex === 0 }" @click="switchElement('left')"
          :style="{ order: getOrder(0) }">
          <img src="../../assets/images/technologyPageImg/certik.webp" alt="resourse">
        </div>
        <div class="resourse-img" :class="{ active: activeIndex === 1 }" @click="switchElement('right')"
          :style="{ order: getOrder(1) }">
          <img src="../../assets/images/technologyPageImg/whitepaper.webp" alt="resourse">
        </div>
      </div>

      <div class="resources-content">
        <div class="resources-desc">
          <p>{{ activeContent.desc }}</p>
        </div>
        <a :href="activeContent.linkCN" class="active-button" target="_blank" v-if="$i18n.locale === 'cn'">
          <p>{{ activeContent.buttonText }}</p>
        </a>
        <a :href="activeContent.link" class="active-button" target="_blank" v-else>
          <p>{{ activeContent.buttonText }}</p>
        </a>
      </div>
      
    </div>
  </template>
  <script>
  import { defineComponent, watch, ref, computed, onMounted, nextTick } from 'vue';
  import { useI18n } from 'vue-i18n';
  import Section from '../../components/common/section.vue';
  
  export default defineComponent({
    name: 'ResourcesSection',
    components: {
      Section
    },
    setup() {
      const { t, locale } = useI18n();
      const activeIndex = ref(1);
  
      const contents = ref([
        {
          desc: '',
          buttonText: '',
          link: 'https://skynet.certik.com/projects/atm',
          linkCN:'https://skynet.certik.com/projects/atm'
        },
        {
          desc: '',
          buttonText: '',
          link: '/pdf/ATMWhitePaper.pdf',
          linkCN:'/pdf/ATMWhitePaper-cn.pdf'
        }
      ]);
  
      const updateContents = () => {
        contents.value[0].desc = t('technologyPage.auditReportDesc');
        contents.value[0].buttonText = t('technologyPage.auditReport');
        contents.value[1].desc = t('technologyPage.whitePaperDesc');
        contents.value[1].buttonText = t('technologyPage.whitePaper');
      };
  
      watch(locale, updateContents, { immediate: true });
  
      const activeContent = computed(() => contents.value[activeIndex.value]);
  
      const disableLeft = computed(() => activeIndex.value === 0);
      const disableRight = computed(() => activeIndex.value === 1);
  
      const switchElement = (direction) => {
        if (direction === 'left' && activeIndex.value > 0) {
          activeIndex.value--;
        } else if (direction === 'right' && activeIndex.value < 1) {
          activeIndex.value++;
        }
        updateOrder();
      };
  
      const getOrder = (index) => {
        if (window.innerWidth < 430) {
          return activeIndex.value === index ? 1 : 2;
        }
        return 'initial';
      };
  
      const updateOrder = () => {
        nextTick(() => {
          const images = document.querySelectorAll('.resources-images .resourse-img');
          images.forEach((img, index) => {
            img.style.order = getOrder(index);
          });
        });
      };
  
      onMounted(() => {
        window.addEventListener('resize', updateOrder);
        updateOrder(); 
      });
  
      return {
        activeIndex,
        activeContent,
        disableLeft,
        disableRight,
        switchElement,
        getOrder
      };
    }
  });
</script>
  
<style scoop>
@import '@/assets/style/technologyPage/resourcesSection.scss';
</style>