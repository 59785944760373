<template>
  <div class="mode-selector">
    <div class="mode-button"  @click="toggleDarkMode">
      <img :src="isDark ?  'images/icons/btn-lightmode.svg' : 'images/icons/btn-darkmode.svg'" alt="icon">
    </div>
  </div>
</template>
  
<script>
import { useDark, useToggle } from '@vueuse/core';

export default {
  name: 'ModeSelector',
  
  setup() {
    const isDark = useDark();
    const toggleDark = useToggle(isDark);

    const toggleDarkMode = () => {
      toggleDark();
      document.body.classList.toggle('dark-mode', isDark.value);
      document.body.classList.toggle('light-mode', !isDark.value);
    };

    return { isDark, toggleDarkMode };
  },
};

</script>
  