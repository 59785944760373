<template>
    <div class="loader-mask" v-if="visible">
      <div class="loader"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Loader',
    props: {
      visible: {
        type: Boolean,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .loader-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid rgba(46, 168, 175, 1);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  