<template>
    <Section :title="$t('title.gameDev')">
        <div class="game-dev-section">
            <div class="game-dev-top">

                <div class="first-block">
                    <div class="first-block-img">
                        <image-changer :image-src="'/images/gamingPage/note.webp'"
                        :image-src-mobile="'/images/gamingPage/note-mob.webp'" alt-text="note" />
                    </div>
                    <div class="first-block-text">
                        <div class="body-text">
                            <p>{{ $t('gamingPage.crowdfundingPitch') }}</p>
                        </div>
                    </div>
                </div>

                <div class="video-block">
                    <div class="video-block-img">
                        <img src="../../assets/images/gamingPageImg/videoimg.webp" alt="video-player">
                        <div class="play-button" @click="showModal = true">
                            <img src="../../assets/images/icons/play-button.svg" alt="play-button">
                        </div>
                    </div>
                    <div class="video-block-text">
                        <div class="text-xs">
                            <p>{{ $t('gamingPage.previewVideo') }}</p>
                        </div>
                    </div>
                </div>

            </div>


            <div class="game-dev-bottom">

                <div class="main-block">

                    <div class="main-block-img">
                        <div class="main-block-text-top">
                            <div class="body-text">
                                <p>{{ $t('gamingPage.investPreferredGames') }}</p>
                            </div>
                        </div>

                        <image-changer :image-src="imageSource"
                        :image-src-mobile="imageSourceMob" alt-text="community" />
                  
                        <div class="main-block-text-bottom">
                            <div class="body-text">
                                <p>{{ $t('gamingPage.fundsReturnedToInvestors') }}</p>
                            </div>
                        </div>
                    </div>

                </div>


                <div class="last-block">
                    <div class="last-block-img">
                        <image-changer :image-src="'/images/gamingPage/devimg.webp'"
                            :image-src-mobile="'/images/gamingPage/devimg-mob.webp'" alt-text="gevimg" />
                    </div>
                    <div class="last-block-text">
                        <div class="body-text">
                            <p>{{ $t('gamingPage.fundsReachedGoal') }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ModalVideoPlayer :showModal.sync="showModal" :videoSrc="videoSrc" @update:showModal="updateShowModal" />
    </Section>
</template>

<script>

import Section from '../../components/common/section.vue';
import ModalVideoPlayer from '../../components/common/modalVideoPlayer.vue';
import ImageChanger from '../../components/common/imageChanger.vue';
import useDarkMode from '@/darkMode';
export default {
    name: 'gameDev',
    components: {
        Section,
        ModalVideoPlayer,
        ImageChanger
    },
    data() {
        return {
            showModal: false,
            videoSrc: '/images/gamingPage/game-video.mov'
        };
    },

    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        imageSource() {
            return this.isDark
                ? '/images/gamingPage/community-dark.gif'
                : '/images/gamingPage/community.gif';
        },
        imageSourceMob() {
            return this.isDark
                ? '/images/gamingPage/community-mob-dark.gif'
                : '/images/gamingPage/community-mob.gif';
        }
    },
    methods: {
        updateShowModal(value) {
            this.showModal = value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/gamingPage/gameDev.scss';
</style>
