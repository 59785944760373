<template>
    <div class="galaxy-section">
        <div class="galaxy-section-top">
            <div class="main-text-wrapper">
                <div class="main-text">
                    {{ $t('homePage.fromDecisions') }}<span class="main-span"> {{ $t('homePage.strengthsNumbers') }}</span>.
                </div>
            </div>
            <div class="section-title">
                {{ $t('galaxy') }}
            </div>
            <div class="section-desc">
                <p> {{ $t('homePage.explorationStart') }} <span>{{ $t('homePage.explorationMiddle') }}</span>{{ $t('homePage.explorationEnd') }} </p>
            </div>

        </div>
        <div class="galaxy-wrapper">
            <GalaxyComponent></GalaxyComponent>
        </div>
    </div>
</template>
<script>
import GalaxyComponent from './galaxyComponent.vue'
export default {
    name: 'GalaxySection',
    data() { return {} },
    components: {
        GalaxyComponent
    },
}
</script>
<style scoped>
@import '../../assets/style/homePageStyle/galaxySection.scss';
</style>