import qs from "qs";
import axios from "axios";
import { messages } from "@/i18n/data";
// Configure env files .
// Test server
// const baseURL = "http://atmapi.5544444455.com";
// Local Server
// const baseURL = 'http://localhost:8080/atm';
// Production
const baseURL = 'https://webapi.atm.network';

// axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//news
export const fetchNewsList = async (
  pageIndex = null,
  pageSize = null,
  type = ""
) => {
  try {
    const params = new URLSearchParams({
      pageIndex,
      pageSize,
      type,
    });
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cssg-Language": "en",
      },
      body: params,
    };

    const response = await fetch(`${baseURL}/atm/getNewsList`, requestOptions);
    const result = await response.json();

    // let result;

    // // Check if type is empty; if so, fetch twice with type 1 and type 2
    // if (type === "") {
    //   // Fetch with type 1 and type 2 and wait for both requests to complete
    //   const [result1, result2] = await Promise.all([
    //     fetchWithType(1 ,pageIndex, pageSize),
    //     fetchWithType(2 ,pageIndex, pageSize),
    //   ]);

    //   // Merge the newsList arrays from both results
    //   const mergedNewsList = [
    //     ...result1.data.newsList,
    //     ...result2.data.newsList,
    //   ];

    //   // Construct a new result with the merged newsList and other data from the first response
    //   result = {
    //     ...result1,
    //     data: {
    //       ...result1.data,
    //       newsList: mergedNewsList,
    //       totalCount: result1.data.totalCount + result2.data.totalCount, // Sum the total counts
    //     },
    //   };
    // } else {
    //   result = await fetchWithType(type ,pageIndex, pageSize); // Fetch once with provided type
    // }

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// async function fetchWithType(type , pageIndex , pageSize) {
//   const params = new URLSearchParams({
//     pageIndex,
//     pageSize,
//     type,
//   });
//   const requestOptions = {
//     method: "POST",
//     credentials: "include",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       "Cssg-Language": "en",
//     },
//     body: params,
//   };

//   const response = await fetch(`${baseURL}/atm/getNewsList`, requestOptions);
//   return await response.json();
// }

// export const showDefaultImageIfEmpty = (news) => {
//   if (!news.coverImg || news.coverImg.trim() === "") {
//     news.coverImg = "images/junkNews.png";
//   }
// };

//proposal

export const getInitiateList = async () => {
  try {
    const params = new URLSearchParams({
      pageIndex: 1,
      pageSize: 1,
    });

    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cssg-Language": "en",
      },
      body: params.toString(),
    };

    const response = await fetch(
      `${baseURL}/community/getInitiateList`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

//overview
export const getOverviewData = async () => {
  try {
    const response = await axios.get(`${baseURL}/atm/overview`, {
      withCredentials: true,
    });

    return response.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//subscribe
export const subscribe = async (email) => {
  try {
    const response = await axios.post(
      `${baseURL}/atm/emailSubscription`,
      { email },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//auth apis
export const getSignMessage = async (address) => {
  try {
    const response = await axios.post(
      `${baseURL}/user/getSignMessage`,
      { address: address },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cssg-Language": "en",
        },
      }
    );

    return {
      data: response.data.data.signMessage,
      isError: false,
      message: "success",
    };
  } catch (error) {
    throw {
      data: response.data.signMessage,
      isError: true,
      message: error,
    };
  }
};

export const getLoginToken = async (address, sign) => {
  try {
    const response = await axios.post(
      `${baseURL}/user/appLogin`,
      {
        address: address,
        sign,
        deviceId: 112233,
        clientType: 6, //	string	1:IOS App Store版本 2:IOS企业版 3:Android Google Play版本 4:Android 普通版本 5:PC端 6官网端(必填)
        clientVersion: 1,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cssg-Language": "en",
        },
      }
    );
    return {
      data: response.data.data,
      isError: false,
      message: "success",
    };
  } catch (error) {
    throw {
      data: response.data.signMessage,
      isError: true,
      message: error,
    };
  }
};

//start API's for explorer page 
//overview

export const getCoinCurrency = async () => {
  try {
    const response = await axios.post(`${baseURL}/site/getCoinCurrencyList`, {
      withCredentials: true,
    });
    const responseData = response.data && response.data.data;

    if (responseData && Array.isArray(responseData.coinCurrencyPairList)) {
    
      return responseData.coinCurrencyPairList.filter(item => item.aloneCalculateFlag === 1);
    } else {
      console.warn('coinCurrencyPairList not found or is not an array:', responseData);
      return []; 
    }
  } catch (error) {
    console.error("Error fetching coin currency data:", error);
    throw error;
  }
};

export const getUserLinkData = async () => {
  try {
    const response = await axios.get(`${baseURL}/site/getUserLinkCount`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user link data:", error);
    throw error;
  }
};


//consensusContractList
export const fetchConsensusContractList = async ({
  pageNo,
  pageSize = 10,
  linkCurrency,
  chainId,
  consensusType,
  searchType,
  searchKey,
}) => {
  try {
    const response = await axios.get(`${baseURL}/atm/consensusContractList`, {
      params: {
        pageNo,
        pageSize,
        linkCurrency,
        chainId: chainId === 'null' ? null : chainId,
        consensusType,
        [searchType]: searchKey,
      },
      withCredentials: true,
    });

    if (response.data.errorcode === "INTERNAL_ERROR") {
      throw new Error(response.data.msg || "Internal server error");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching consensus contract list:", error);
    throw error;
  }
};
//coinPriceTrend for line chart exporer
export const fetchCoinPriceTrend = async (coinCurrency, type) => {
  try {
    const response = await axios.get(`${baseURL}/atm/coinPriceTrend`, {
      params: {
        coinCurrency,
        type,
      },
    });

    return response.data?.data;
  } catch (error) {
    console.error("Error fetching coin price trend:", error);
    throw error;
  }
};
//system time 
export const fetchSystemTime = async () => {
  try {
    const response = await axios.post(`${baseURL}/site/getCoinCurrencyList`);
    return response.data?.data?.systemTime || null;
  } catch (error) {
    console.error('Error fetching system time:', error);
    throw error;
  }
};
//overal ranking 
export const fetchRankList = async (pageNo, pageSize = 10, type = 1) => {
  try {
    const response = await axios.get(`${baseURL}/atm/allNetworkRanking`, {
      params: { pageNo, pageSize, type },
    });

    if (response.data && Array.isArray(response.data.data)) {
      return {
        list: response.data.data,
        total: response.data.total,
      };
    } else {
      console.error('Invalid response format:', response.data);
      throw new Error('Invalid response format');
    }
  } catch (error) {
    console.error('Error fetching rank list:', error);
    throw error;
  }
};
//PRNodes
export const fetchPRNodes = async (pageNo, pageSize = 10, searchKey, searchType) => {
  try {
    const response = await axios.get(`${baseURL}/atm/prList`, {
      params: {
        pageNo,
        pageSize,
        [searchType]: searchKey,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching PR nodes:', error);
    throw error;
  }
};
//StakeTransactions 
export const fetchStakeTransactions = async (pageNo, pageSize = 10, chainId = null, searchKey, searchType) => {
  try {
    const response = await axios.get(`${baseURL}/atm/treatyList`, {
      params: {
        pageNo,
        pageSize,
        chainId: chainId || undefined,
        [searchType]: searchKey,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching stake transactions:', error);
    throw error;
  }
};

//contractInfo
export const fetchContractInfo = async () => {
  try {
    const response = await axios.get(`${baseURL}/atm/contractInfo`, {
      withCredentials: true,
    });
    if (!response.data || !response.data.data) {
      throw new Error("Invalid response data");
    }
    return {
      data: response.data.data,
      total: response.data.total || 0
    };
  } catch (error) {
    console.error("Error fetching contract info:", error);
    throw error;
  }
};



//end API's for explorer page 