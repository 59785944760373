<template>
    <div>
        <div style="height:100vh; padding-top: 10%; background:white;">
            <h1>I am in Dashboard</h1>

            <div class="origin-btn" @click="blockchainRead">
                <p>Read Blockchain</p>
            </div>
            <div class="origin-btn" @click="blockchainWithParamsRead">
                <p>Read Blockchain with params</p>
            </div>
            <div class="origin-btn" @click="blockchainAllowance">
                <p>Read Allowance with params</p>
            </div>
            <div class="origin-btn" @click="blockchainInAllowance">
                <p>Read Increase Allowance with params</p>
            </div>


            <div class="origin-btn" @click="blockchainWithParamsWrites">
                <p>write Blockchain with params</p>
            </div>

            <div class="origin-btn" @click="getGamesProps">
                <p>read Blockchain with getGamesProps</p>
            </div>



        </div>
    </div>
</template>
<script>
import Erc20 from '@/api/blockchain/wallet/abi/erc20'
import factoryABI from "@/api/blockchain/wallet/abi/factory.json";
import Web3 from 'web3'

import { createPublicClient, http } from 'viem';
import { bscTestnet, bsc } from 'viem/chains';
import { getAccount, writeContract } from '@wagmi/core';
import { formatEther } from 'viem';
import { parseGwei, parseEther } from 'viem';
import { createConfig } from '@wagmi/core'

const abi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_luca",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_reward",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "idB",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "rewardIn",
                "type": "uint256"
            }
        ],
        "name": "BuyEvent",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "gamePropsNFT",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "uri",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isLimited",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            }
        ],
        "name": "addGameProp",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "gamePropId",
                "type": "uint256"
            }
        ],
        "name": "buyGamePros",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "gameProps",
        "outputs": [
            {
                "internalType": "address",
                "name": "gamePropsNFT",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "uri",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isLimited",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "maxSupply",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "total",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "tamp",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "gameReward",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "luca",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "propsCounter",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "uri",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isLimited",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            }
        ],
        "name": "updateGameProp",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];
// const config = createConfig({
//   chains: [bscTestnet, bsc],
//   transports: {
//     [bsc]: http(),
//   },
// })

// https://viem.sh/docs/contract/readContract.html
// const abi = [
//     {
//         type: 'function',
//         name: 'balanceOf',
//         stateMutability: 'view',
//         inputs: [{ name: 'account', type: 'address' }],
//         outputs: [{ type: 'uint256' }],
//     },
//     {
//         type: 'function',
//         name: 'totalSupply',
//         stateMutability: 'view',
//         inputs: [],
//         outputs: [{ name: 'supply', type: 'uint256' }],
//     },
// ];

const publicClient = createPublicClient({
    chain: bscTestnet,
    transport: http()
})
const FACTORY_ADDRESS = '0x8f827eCe944A0d54cfcD94D8775b23A980F37cdA'; // 工厂合约
const TRADER_ADDRESS = '0x7b749e4d4C23556a772Aca4E00E283BEFd575b9B'; // 交易员合约


export default {
    name: 'Dashboard',
    data() { return {} },
    components: {},
    computed: {
    },
    methods: {
        async blockchainRead() {
            console.log('i am reading  ');

            const data = await publicClient.readContract({
                abi: Erc20,
                address: '0x51e6ac1533032e72e92094867fd5921e3ea1bfa0',
                functionName: 'totalSupply'
            });
            console.log(formatEther(data));
        },
       
        async blockchainWithParamsRead() {
            console.log('i am reading  ');
            const { address } = getAccount();
            const data = await publicClient.readContract({
                abi: Erc20,
                address: '0x51e6ac1533032e72e92094867fd5921e3ea1bfa0',
                functionName: 'balanceOf',
                args: [address]
            });
            console.log(formatEther(data));
        },
        async blockchainAllowance() {
            //   const isAllow = await isAllowanceV2(_symbol, TRADER_ADDRESS, myAmount);

            const { address } = getAccount();

            const data = await publicClient.readContract({
                abi: Erc20,
                address: '0x51e6ac1533032e72e92094867fd5921e3ea1bfa0',
                functionName: 'allowance',
                args: [
                    address,
                    TRADER_ADDRESS,
                ]
            });
            console.log(formatEther(data));
        },

    

       

        async blockchainInAllowance() {
            console.log('i am writeContract  ', parseEther('0.01'));
            //   const isAllow = await isAllowanceV2(_symbol, TRADER_ADDRESS, myAmount);

            const { address } = getAccount();

            const result = await writeContract({
                abi: Erc20,
                address: '0x51e6ac1533032e72e92094867fd5921e3ea1bfa0',
                functionName: 'approve',
                args: [
                    TRADER_ADDRESS,
                    parseGwei('1'),

                ]
            })
            console.log(result);
        },
        async blockchainWithParamsWrites2() {
            console.log('i am writeContract  ', parseEther('0.01'));
            //   const isAllow = await isAllowanceV2(_symbol, TRADER_ADDRESS, myAmount);

            const { address } = getAccount();
            const result = await writeContract({
                abi: factoryABI,
                address: FACTORY_ADDRESS,
                functionName: 'createLink',
                args: [
                    '0x8963b195aBCAeb9700503F5C95BDb4f220ce3639',
                    'LUCA',
                    1n,
                    '100',
                    '5'
                ]
            })
            // console.log(result);
        }
    }
}
</script>