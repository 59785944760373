import { defineStore } from "pinia";
import Web3 from "web3";
import { connect, getAccount } from "@wagmi/core";
import UserManagerABI from "@/api/blockchain/wallet/abi/userManager.json";

import GameManager from "@/api/blockchain/wallet/abi/gameManager.json";
import FactoryABI from "@/api/blockchain/wallet/abi/factory.json";
import LinkABI from "@/api/blockchain/wallet/abi/link.json";
import GamePropsLink from "@/api/blockchain/wallet/abi/gamePropsLink.json";
import Erc20 from "@/api/blockchain/wallet/abi/erc20";
import AtmStarToken from "@/api/blockchain/wallet/abi/atmStarToken.json";

import AGFManagerABI from "@/api/blockchain/wallet/abi/AGFManagerABI.json";

export const useBlockChainStore = defineStore("blockchain", {
  state: () => ({
    web3: null,
    account: null,
    contract: null,
    web3ModelState: null,
  }),
  getters: {
    isConnected: (state) =>
      state.account != null ? state.account.isConnected : false,
    getAccount: (state) => state.account.address,
  },
  actions: {
    async init() {
      const rpcUrls = {
        "0x61": "https://data-seed-prebsc-1-s1.binance.org:8545", // BSC Testnet
        "0x38": "https://binance.llamarpc.com"
        // Add other chain RPC URLs if needed
      };
      this.web3 = new Web3(window.ethereum);

      this.account = getAccount();
      let chainId = "0x38";
      // console.log("currentChainId ==>", this.account.address);

      // // Check if account address is defined
      if (this.account && this.account.address) {
        // Use window.ethereum for wallet connection (MetaMask)
        this.web3 = new Web3(window.ethereum);
         try {
          // Get the current chain ID from the wallet
          chainId = await window.ethereum.request({ method: "eth_chainId" });

          // If the chain ID is not '0x61', request network switch
          if (chainId !== "0x38") {
            try {
              // Request to switch to Binance Smart Chain Testnet
              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x38" }],
              });
              console.log("Switched to Binance Smart Chain Testnet");
              chainId = "0x38"; // Set the chainId to 0x61 after switching
            } catch (switchError) {
              if (switchError.code === 4902) {
                // If the chain is not added to MetaMask, add it
                try {
                  await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                      {
                        chainId: "0x38",
                        chainName: "Binance Smart Chain",
                        nativeCurrency: {
                          name: "BNB",
                          symbol: "BNB",
                          decimals: 18,
                        },
                        rpcUrls: [
                          "https://binance.llamarpc.com",
                        ],
                        blockExplorerUrls: ["https://bscscan.com/"],
                      },
                    ],
                  });
                  console.log("Binance Smart Chain Testnet added and switched");
                  chainId = "0x38"; // Set the chainId to 0x61 after adding and switching
                } catch (addError) {
                  console.error(
                    "Failed to add Binance Smart Chain Testnet:",
                    addError
                  );
                  return null;
                }
              } else {
                console.error(
                  "Failed to switch to Binance Smart Chain Testnet:",
                  switchError
                );
                return null;
              }
            }
          } else {
            console.log(
              `Already connected to Binance Smart Chain Testnet with chain ID: ${chainId}`
            );
          }
        } catch (error) {
          console.error("Error getting chain ID from wallet:", error);
          return null;
        }
      } else {
        // Fall back to a specific chain (for example, BSC Testnet)
        // Default to BSC Testnet if no wallet is connected
        this.web3 = new Web3(new Web3.providers.HttpProvider(rpcUrls[chainId]));
        console.log(
          `No wallet connected. Falling back to chain ID: ${chainId}`
        );
      }
    },

    async getTokenContractByName(tokenName) {
      // let web3 = new Web3(window.ethereum);
      let contract = null;
      let chainId = "97"; //await this.web3.eth.getChainId();
      console.log(chainId);
      switch (tokenName) {
        case "LUCA":
          contract = new this.web3.eth.Contract(
            Erc20,
            this.getContractAddress(chainId, tokenName)
          );
          break;
        case "USDC":
          contract = new this.web3.eth.Contract(
            Erc20,
            this.getContractAddress(chainId, tokenName)
          );
          break;
        case "AtmStar":
          contract = new this.web3.eth.Contract(
            AtmStarToken,
            this.getContractAddress(chainId, tokenName)
          );
          break;
        default:
          break;
      }
      return contract;
    },
    getTokenContractByAddress(tokenAddress) {
      // let web3 = new Web3(window.ethereum);
      let contract = new this.web3.eth.Contract(Erc20, tokenAddress);
      return contract;
    },

    async getContract(contractName) {
      // let web3 = new Web3(window.ethereum);
      // get the chain form web3
      let chainId = 97; //await this.web3.eth.getChainId();
      let contract = null;
      switch (contractName) {
        case "GameProps":
          contract = new this.web3.eth.Contract(
            GamePropsLink,
            this.getContractAddress(chainId, contractName)
          );
          break;
        case "GameManager":
          contract = new this.web3.eth.Contract(
            GameManager,
            this.getContractAddress(chainId, contractName)
          );
          break;
        case "CreateConnectionFactory":
          contract = new this.web3.eth.Contract(
            FactoryABI,
            this.getContractAddress(chainId, contractName)
          );
          break;
        case "UserManager":
          contract = new this.web3.eth.Contract(
            UserManagerABI,
            this.getContractAddress(chainId, contractName)
          );
          break;
        case "AGFManager":
          contract = new this.web3.eth.Contract(
            AGFManagerABI,
            this.getContractAddress(chainId, contractName)
          );
          break;
        default:
          break;
      }

      return contract;
    },

    getContractAddress(chainId, contractName) {
      this.init();
      let address = null;

      // switch case for contractName
      switch (contractName) {
        case "GameProps":
          address = "0x0393490721e52155b84915b8e2a2a286e8d053c5"; // 交易员合约
          break;
        case "GameManager":
          address = "0xB51015C85d7D46EC2d844B22B58D41a799C7b112"; // 交易员合约
          break;
        case "CreateConnectionFactory":
          address = "0x50249e6dB2d98b314e2A843D5b642F162DA2099F";
          break;
        case "TraderAddress":
          address = "0x7386F0d65C3690C67b9852eE7DFFBB2B7F7F5Fd0";
          break;
        case "UserManager":
          address = "0x2251ac7072611aD6d08A6ED201a1045b1E69e31F";
          break;
        case "AtmStar":
          address = "0xa1Bf6208846E38EF40B185A849b3000ac5dc71cE";
          break;
        case "AGFManager":
          address = "0x172b5D9Afd4f411d201621718269A9BeFE9370e2";
          break;
        case "LUCA":
          address = "0x51E6Ac1533032E72e92094867fD5921e3ea1bfa0";
          break;
        case "USDC":
          address = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
          break;
        default:
          break;
      }

      return address;
    },

    // Check if a user is logged in by ensuring they have an active account connected
    isLoggedIn() {
      this.init();
      try {
        return this.account == null ? false : this.account.isConnected;
      } catch (error) {
        console.error("Failed to fetch accounts", error);
        return false;
      }
    },

    async getPersonalSign(address, message) {
      // let web3 = new Web3(window.ethereum);
      console.log(" address ", address);

      console.log(" message ", message);

      try {
        const signature = await this.web3.eth.personal.sign(
          message,
          address,
          ""
        );
        return {
          data: signature,
          isError: false,
          message: "Signature successful",
        };
      } catch (err) {
        console.error("Error signing data:", err);
        return {
          data: null,
          isError: true,
          message: err,
        };
      }
    },

    async updateWeb3State(state) {
      this.web3ModelState = state;
    },
  },
});
