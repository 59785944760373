<template>
    <div class="language-selector" v-show="isDesk" ref="dropdownContainer">
        <div class="selector-wrap" @click="toggleDropdown">
            <div class="selector-flag">
                <img :src="selectedLanguage.flag" alt="lang" class="selected-flag">
            </div>
            <!-- <div class="selector-img">
                <img :src="isDropdownOpen ? 'images/icons/array-right.svg' : 'images/icons/array-right-disabled.svg'"
                    alt="">
            </div> -->
        </div>
        <ul v-if="isDropdownOpen" class="language-dropdown">
            <li class="lang-elem" v-for="language in languages" :key="language.code" @click="changeLanguage(language.code)">
                <div class="selector-flag">
                    <img :src="language.flag" alt="lang">
                </div>
                <div class="lang-name" :class="{ selected: language.code === selectedLanguage.code }">{{ language.name }}</div>
            </li>
        </ul>
    </div>

    <div class="mob-language-selector" v-show="!isDesk">
        <ul>
            <li class="lang-elem" v-for="language in languages" :key="language.code" @click="changeLanguage(language.code)">
                <div class="selector-flag">
                    <img :src="language.flag" alt="lang">
                </div>
                <div class="lang-name" :class="{ selected: language.code === selectedLanguage.code }">{{ language.name }}</div>
            </li>
        </ul>
    </div>
</template>
  
<script>
export default {
    name: 'LangSelector',
    data() {
        return {
            isDesk: window.innerWidth >= 1150,
            isDropdownOpen: false,
            selectedLanguage: JSON.parse(localStorage.getItem('selectedLanguage')) || {
                code: 'en',
                name: 'English',
                flag: 'images/lang/en-lang.png'
            },
            languages: [
                { code: 'en', name: 'EN', flag: 'images/lang/en-lang.png' },
                { code: 'cn', name: 'CN', flag: 'images/lang/cn-lang.png' },
            ]
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    created() {
        window.addEventListener('resize', this.updateScreenSize);
        const storedLanguage = localStorage.getItem('lang');
        if (storedLanguage) {
            this.$i18n.locale = storedLanguage;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
    methods: {
        updateScreenSize() {
            this.isDesk = window.innerWidth >= 1150;
        },


        toggleDropdown() {
            console.log('toggleDropdown executed');
            this.isDropdownOpen = !this.isDropdownOpen;
            console.log('isDropdownOpen:', this.isDropdownOpen);

        },

        handleClickOutside(event) {
            const dropdownContainer = this.$refs.dropdownContainer;
            if (dropdownContainer && !dropdownContainer.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        },


        changeLanguage(language) {
            console.log('Selected language is', language);
            this.$i18n.locale = language;

            localStorage.setItem('lang', language);

            this.selectedLanguage = this.languages.find(lang => lang.code === language);
            this.isDropdownOpen = false;


            localStorage.setItem('selectedLanguage', JSON.stringify(this.selectedLanguage));
        }

    }
};
</script>
  