<template>
  <div class="game-landing-page-header">
  </div>
  <div class="section">
    <!-- Main content (70% width) -->
    <div class="center-content">
      <!-- Show loader while fetching game data -->
      <div v-if="loading" class="loader">Loading...</div>
      <!-- Show game details once data is fetched -->
      <div style="width: 100%;" class="game-container" v-else>
        <div v-for="(game, index) in gameArray" :key="game.id" class="game-card" @click="goToGameDetail(game)">
          <div class="card-tag">{{ this.getGameStatus(game) }}</div>
          <div class="game-card-content">
            <div class="game-card-details">
              <h2>{{ game.title }}</h2>
              <div class="game-card-footer">

                <span>{{ game.likes }} ♥</span>
                <!-- <span>167 👤</span> -->

              </div>
            </div>
            <div class="card-image">
              <img v-if="game.gamesMediaModelList && game.gamesMediaModelList.length > 0"
                :src="game.gamesMediaModelList[0].link">
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import GameApplicationProposalDetails from './gameApplicationProposalDetails.vue';
import { getGameById, gameRating, gameContributed, getAllGame } from "@/api/agfAPI";
import GamePieChart from './gameProposalComponents/gamePieChart.vue';
import { useBlockChainStore } from '@/store/blockchain'
import { formatEther, parseGwei, parseEther } from 'viem';
import ConnectButton from "@/components/common/connectButton.vue"

export default {
  name: 'Game',
  props: {
    web3Model: Object,
    authStore: Object,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
  },
  data() {
    return {
      loading: true,
      gameArray: [],
    };
  },
  components: {
    GameApplicationProposalDetails,
    GamePieChart,
    ConnectButton
  },
  computed: {
    isAccount() {
      const blockChainStore = useBlockChainStore();
      console.log("blockChainStore ", blockChainStore.isLoggedIn())
      return blockChainStore.isLoggedIn();
    }
  },
  created() {
    this.fetchAllGame();
  },
  mounted() {

  },
  methods: {
    goToGameDetail(game) {
      console.log(game.id)
      this.$router.push({
        name: 'gameDetail',
        params: { gameId: game.id } // Use 'gameId' in params now
      });
    },
    getGameStatus(game) {
      let type = game.status.toString();

      const listOfStatus = [
        { id: '0', name: 'Inactive' },
        { id: '1', name: 'Under review' },
        { id: '2', name: 'Changes required' },
        { id: '3', name: 'Approved' },
        { id: '4', name: 'Rejected' },
        { id: '5', name: 'Contribution stage' },
        { id: '6', name: 'Released' }
      ];
      const status = listOfStatus.find(statusItem => statusItem.id === type);
      return status ? status.name : 'Unknown Status';
    },
    async fetchAllGame() {
      const gameKey = 'cachedGameData'; // Define a key for localStorage
      this.loading = true;
      // Check if cached data exists in localStorage
      const cachedData = localStorage.getItem(gameKey);
      if (cachedData) {
        try {
          this.gameArray = JSON.parse(cachedData); // Parse cached data
          if (this.gameArray.length == 1) {
            console.log('Only one game'); // Debugging: log cache operation
            this.goToGameDetail(this.gameArray[0]);
          }
          console.log('Loaded game data from cache:', this.gameArray); // Debugging: log cached data
          this.loading = false;
        } catch (e) {
          console.error('Error parsing cached data:', e);
        }
      }

      // If no cached data, fetch it from the API

      console.log('No cached data found, fetching from API...'); // Debugging: log fetch start
      try {
        const response = await getAllGame();
        if (response.isSuccess) {
          this.gameArray = response.data;
          localStorage.setItem(gameKey, JSON.stringify(response.data)); // Cache the data
          if (this.gameArray.length == 1) {
            console.log('Only one game'); // Debugging: log cache operation
            this.goToGameDetail(this.gameArray[0]);
          }


        }
      } catch (error) {
        console.error('Error fetching game data:', error);
      } finally {
        this.loading = false;
      }

    }
  }
}
</script>

<style >
/* Existing Styles */
.section {
  display: flex;
  justify-content: space-between;
  margin: 80px auto 150px;
  padding: 50px;
  height: 100%;
}

.center-content {
  flex: 8;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.loader {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* Game Landing Page Header */
.game-landing-page-header {
  width: 100%;
  height: 400px;
  background-image: url('@/assets/images/game/game_header.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
}

/* General Card Styles */

.game-container {
  display: flex;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.game-card {
  position: relative;
  background: var(--purple-liner, linear-gradient(136deg, #02012B 0%, #6D6AFF 98.28%));
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


}

/* Tag at the top left corner */
.card-tag {
  border-radius: 40px;
  border: 1px solid #FFF;
  background: #B4B3D5;
  backdrop-filter: blur(5px);
  color: #1C1C1C;
  font-size: 14px;
  padding: 4px 10px;

  position: absolute;
  top: 15px;
  left: 15px;
}

/* Card Content Layout */
.game-card-content {
  width: 420px;
  height: 220px;
  display: flex;

  align-items: center;
  flex-direction: row
}

.game-card-footer {
  display: flex;
}

/* Text Details */
.game-card-details {
  display: flex;
  height: 220px;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin: 20px;



}

.card-details h2 {
  font-size: 24px;
  margin: 10px 0;
  color: white;
}

.card-stats {
  font-size: 14px;
  color: #a1a4bc;
  display: flex;
  gap: 10px;
}

/* Tilted Image */
.card-image img {
  width: 487px;
  height: 258px;
  transform: rotate(25.233deg);
  flex-shrink: 0;
  /* Tilt the image */
  border-radius: 15px;

  position: relative;
  bottom: 260px;
  left: 300px;

}

/* Media Queries for Responsive Design */
@media (max-width: 900px) {
  .section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .game-landing-page-header {
    height: 300px;
    background-size: contain;
  }

  .right-sidebar {
    order: -1;
    width: 100%;
  }
}

@media (max-width: 500px) {


  .game-landing-page-header {
    display: none;
  }

  .section {
    padding: 0px;
  }

  .game-card-details {
    h2 {
      font-size: 18px;
      width:200px;
    }
  }

  .card-image img {
    width: 487px;
    height: 258px;
    transform: rotate(25.233deg);
    flex-shrink: 0;
    border-radius: 15px;
    position: relative;
    bottom: 250px;
    left: 220px;

  }

}
</style>
