<template>
    <div class="firstscreen">
        <div class="firstscreen-main">

            <div class="main-image">
                <img lazy="loading" src="../../assets/images/homePageImg/atm-logo.png" alt="homePageImg">
                <div class="plane-gif">
                    <img lazy="loading" src="../../assets/images/homePageImg/plane-luca.gif" alt="homePageImg">
                </div>
            </div>
            <div class="main-content">
                <div class="contant-wrapper">
                    <h1>
                        {{ $t('homePage.consensusStart') }}<span>{{ $t('homePage.consensusSpan') }}</span>{{
                            $t('homePage.consensusEnd') }}
                    </h1>
                    <div class="firstscreen-links">
                        <div class="link-wrap">
                            <div class="link-img">
                                <img src="../../assets/images/icons/play-icon.svg" alt="homePageImg">
                            </div>
                            <div class="link-text">
                                <a target="_blank" href="https://youtu.be/0lMDHekLqCs?si=PpXXk0gEogOy2JeX">{{
                            $t('homePage.consensus') }}</a>
                            </div>
                        </div>
                        <div class="link-wrap">
                            <div class="link-img">
                                <img lazy="loading" src="../../assets/images/icons/play-icon.svg" alt="homePageImg">
                            </div>
                            <div class="link-text">
                                <a target="_blank" href="https://youtu.be/b5g6gzFlthA">{{ $t('homePage.rank') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="firstscreen-bottom">
            <img src="../../assets/images/homePageImg/landscape-city.gif" alt="homePageImg">
        </div>
    </div>
</template>

<script>
export default {
    name: 'FirstScreen',
};
</script>
<style lang="scss" scoop>
@import '../../assets/style/homePageStyle/firstScreen.scss';
</style>
