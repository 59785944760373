<template>
    <Section :title="$t('title.multiverse')" class="multiverse-section">
        <div class="public-chains-wrapper">
            <div class="public-chain-item">

                <div class="large-body-text">
                    <p>{{ $t('helpPage.supportedChains') }}</p>
                </div>

                <div class="chains-list">
                    <ConnectItem :displayedText="true" v-for="item in supportedChains" :key="item.id" :item="item"
                        class="chain" />
                </div>
            </div>

            <div class="public-chain-item">

                <div class="large-body-text">
                    <p>{{ $t('helpPage.upcomingChains') }}</p>
                </div>


                <div class="chains-list">
                    <ConnectItem :displayedText="true" v-for="item in upcomingChains" :key="item.id" :item="item"
                        class="chain" />
                </div>
            </div>

        </div>

    </Section>
    <div class="chain-container">
        <ConnectItem v-for="item in chains" :key="item.id" :item="item" :displayedText="false"
            class="hovered-chain" />
    </div>

</template>

<script>
import Section from '../../components/common/section.vue';
import ConnectItem from '../../components/common/connectItem.vue'

export default {
    name: 'Multiverse',
    components: {
        Section,
        ConnectItem

    },
    data() {
        return {
            supportedChains: [
                { id: 1, imageUrl: "images/helpPage/icons/binance.svg", text: "Binance", link: 'https://coinmarketcap.com/exchanges/binance/' },
                { id: 2, imageUrl: "images/helpPage/icons/polygon.svg", text: "Polygon", link: 'https://coinmarketcap.com/currencies/polygon-ecosystem-token/' },
                { id: 3, imageUrl: "images/helpPage/icons/ethereum.svg", text: "Ethereum", link: 'https://coinmarketcap.com/currencies/ethereum/' },
                { id: 4, imageUrl: "images/helpPage/icons/avalanche.svg", text: "AVALANCHE", link: 'https://coinmarketcap.com/currencies/avalanche/' },
                { id: 5, imageUrl: "images/helpPage/icons/bitkub.svg", text: "Bitkub", link: 'https://coinmarketcap.com/currencies/bitkub-coin/ ' },
            ],
            upcomingChains: [
                { id: 1, imageUrl: "images/helpPage/icons/OKExChain.svg", text: "OKExChain", link: 'https://www.worldcoinindex.com/coin/okexchain ' },
                { id: 2, imageUrl: "images/helpPage/icons/tron.svg", text: "TRON", link: 'https://coinmarketcap.com/currencies/tron/' },
                { id: 3, imageUrl: "images/helpPage/icons/theta.svg", text: "Theta", link: 'https://coinmarketcap.com/currencies/theta-network/ ' },
                { id: 4, imageUrl: "images/helpPage/icons/heco.svg", text: "HECO", link: 'https://www.rootdata.com/Projects/detail/HECO%20Chain?k=NjA5' },

            ],
            chains: [
                { id: 1, imageUrl: "images/helpPage/icons/ada.svg", text: "ADA", link: ' https://coinmarketcap.com/currencies/cardano/' },
                { id: 2, imageUrl: "images/helpPage/icons/ape.svg", text: "APE", link: ' https://coinmarketcap.com/currencies/apecoin-ape/' },
                { id: 3, imageUrl: "images/helpPage/icons/atom.svg", text: "ATOM", link: 'https://coinmarketcap.com/currencies/cosmos/' },
                { id: 4, imageUrl: "images/helpPage/icons/avax.svg", text: "AVAX", link: ' https://coinmarketcap.com/currencies/avalanche/' },
                { id: 5, imageUrl: "images/helpPage/icons/bch.svg", text: "BCH", link: 'https://coinmarketcap.com/currencies/bitcoin-cash/' },
                { id: 6, imageUrl: "images/helpPage/icons/cake.svg", text: "CAKE", link: 'https://coinmarketcap.com/currencies/pancakeswap/' },
                { id: 7, imageUrl: "images/helpPage/icons/dai.svg", text: "DAI", link: ' https://coinmarketcap.com/currencies/multi-collateral-dai/' },
                { id: 8, imageUrl: "images/helpPage/icons/doge.svg", text: "DOGE", link: 'https://coinmarketcap.com/currencies/dogecoin/' },
                { id: 9, imageUrl: "images/helpPage/icons/dot.svg", text: "DOT", link: 'https://coinmarketcap.com/currencies/polkadot-new/' },
                { id: 10, imageUrl: "images/helpPage/icons/etc.svg", text: "ETC", link: ' https://coinmarketcap.com/currencies/ethereum-classic/' },
                { id: 11, imageUrl: "images/helpPage/icons/fil.svg", text: "FIL", link: 'https://coinmarketcap.com/currencies/filecoin/' },
                { id: 12, imageUrl: "images/helpPage/icons/link.svg", text: "LINK", link: 'https://coinmarketcap.com/currencies/chainlink/' },
                { id: 13, imageUrl: "images/helpPage/icons/ltc.svg", text: "LTC", link: 'https://coinmarketcap.com/currencies/litecoin/' },
                { id: 14, imageUrl: "images/helpPage/icons/luca.svg", text: "LUCA", link: 'https://coinmarketcap.com/currencies/luca/' },
                { id: 15, imageUrl: "images/helpPage/icons/scrt.svg", text: "SCRT", link: 'https://coinmarketcap.com/currencies/secret/' },
                { id: 16, imageUrl: "images/helpPage/icons/pol.svg", text: "POL", link: 'https://coinmarketcap.com/currencies/polygon-ecosystem-token/' },
                { id: 17, imageUrl: "images/helpPage/icons/shib.svg", text: "SHIB", link: 'https://coinmarketcap.com/currencies/shiba-inu/' },
                { id: 18, imageUrl: "images/helpPage/icons/sol.svg", text: "SOL", link: ' https://coinmarketcap.com/currencies/solana/' },
                { id: 19, imageUrl: "images/helpPage/icons/trx.svg", text: "TRX", link: 'https://coinmarketcap.com/currencies/tron/' },
                { id: 20, imageUrl: "images/helpPage/icons/uni.svg", text: "UNI", link: 'https://coinmarketcap.com/currencies/uniswap/' },
                { id: 21, imageUrl: "images/helpPage/icons/vet.svg", text: "VET", link: 'https://coinmarketcap.com/currencies/vechain/' },
                { id: 22, imageUrl: "images/helpPage/icons/wbtc.svg", text: "WBTC", link: ' https://coinmarketcap.com/currencies/wrapped-bitcoin/' },
                { id: 23, imageUrl: "images/helpPage/icons/weth.svg", text: "WETH", link: 'https://coinmarketcap.com/currencies/weth/' },
                { id: 24, imageUrl: "images/helpPage/icons/xlm.svg", text: "XLM", link: 'https://coinmarketcap.com/currencies/stellar/' },
                { id: 25, imageUrl: "images/helpPage/icons/xrp.svg", text: "XRP", link: 'https://coinmarketcap.com/currencies/xrp/' },
                { id: 26, imageUrl: "images/helpPage/icons/hgbp.svg", text: "HGBP", link: 'https://coinmarketcap.com/currencies/' },
                { id: 27, imageUrl: "images/helpPage/icons/bnb.svg", text: "BNB", link: 'https://www.coindesk.com/price/binance-coin' }
                // { id: 27, imageUrl: "images/helpPage/icons/busd.svg", text: "BUSD", link: 'https://www.coinbase.com/en-gb/price/binance-usd' },

               

            ]
        }
    },

};
</script>

<style lang="scss" scoped>
@import '@/assets/style/helpPage/multiverse.scss';
</style>
