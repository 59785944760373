import { createStore } from 'vuex';

const store = createStore({
  state: {
    currentRoute: null,
  },
  mutations: {
    setCurrentRoute(state, route) {
      state.currentRoute = route;
    },
  },
});

export default store;

