<template>
  <div>
    <Header :web3Model="web3Model" :authStore="blockChainStore"> </Header>
    <router-view :web3Model="web3Model" :authStore="blockChainStore"></router-view>
    <Footer></Footer>
  </div>
</template>
  
  
<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
import { bscTestnet, bsc } from 'viem/chains'
import useDarkMode from '@/darkMode';
import { useRouter } from 'vue-router';
import { useWeb3ModalEvents } from "@web3modal/wagmi/vue";
import { useBlockChainStore } from '@/store/blockchain'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import { useAuthStore } from '@/store/auth'

const projectId = '2f6d81f7d85081ec3dd40277f896d5b6'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'ATM',
  url: 'https://web3modal.com',
  icons: ['https://atm.network/img/logo.678fd0c9.svg']
}

const chains = [bsc, bscTestnet]
// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
export const wagmiConfig = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: false, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
  enableEmail: false // Optional - false by default
})


// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableOnramp: true,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // tokens: {
  //   56: {
  //     address: '0x51e6ac1533032e72e92094867fd5921e3ea1bfa0',
  //     image: 'https://atmspace.blob.core.windows.net/svg/LUCA.svg'
  //   },
  //   97: {
  //     address: '0xD7a1cA21D73ff98Cc64A81153eD8eF89C2a1EfEF',
  //     image: 'https://atmspace.blob.core.windows.net/svg/LUCA.svg'
  //   }
  // },
  enableOnramp: false
})

// reconnect(wagmiConfig)


export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const { isDark } = useDarkMode();
    const router = useRouter();



    // return {
    //   isDark,
    // };
  },
  // useWeb3ModalEvents print the events

  data() {
    return {
      isConnected: false,
      events: useWeb3ModalEvents(),
      blockChainStore: useBlockChainStore(),
      web3Model : useWeb3Modal(),
      authStore : useAuthStore(),
    };

  },
  // watch the events
  watch: {
    events: {
      handler: function (newEvents) {
        this.blockChainStore.updateWeb3State(newEvents.data.event);
        if (newEvents.data.event === 'CONNECT_SUCCESS') {
          this.blockChainStore.init();
          this.authStore.connect();
          // this.$router.push('/gameManager')
        } else if (newEvents.data.event === 'DISCONNECT_SUCCESS') {
          this.authStore.disconnect()
          localStorage.clear();
          // this.$router.push('/')
        } else if (newEvents.data.event === 'MODAL_CLOSE') {
          let isConnected = this.blockChainStore.isLoggedIn();
          if (isConnected) {
            this.authStore.connect();
            // this.$router.push('/gameManager')
          } else {
            // this.$router.push('/')
          }
        }else{
          this.authStore.disconnect()
          // localStorage.clear();
          // this.$router.push('/')
        }

      },
      deep: true
    }
  },

  async mounted() {

  },
  computed: {

  },
}
</script>
  
  
  
<style >
@import '@/assets/style/main.scss';
</style>