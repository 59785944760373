<template>
    <div class="ecosystem-page">
        <FirstScreen :imageSrc="require('@/assets/images/ecosystemPageImg/ecology-main.webp')"
            :imageSrcMobile="require('@/assets/images/ecosystemPageImg/main-ecosystem-main-mob.webp')"
            :titleSpan="$t('ecosystemPage.empowerment')" :titleEnd="$t('ecosystemPage.collectiveDestiny')" />
        <GameFund />
        <EcologySection />
        <TravelSection />
        <TokenPRSection />
        <ThirdPartySection />

        <ApplySection />
        <DownloadSection />
    </div>
</template>
<script>
import FirstScreen from '../components/common/firstscreenSection.vue';
import GameFund from '../components/ecosystemPage/gamesFundSection.vue'
import EcologySection from '../components/ecosystemPage/ecologySection.vue'
import TravelSection from '../components/ecosystemPage/travelSection.vue'
import ApplySection from '../components/ecosystemPage/applySection.vue'
import DownloadSection from '../components/homePage/downloadSection.vue';
import ThirdPartySection from '../components/ecosystemPage/thirdPartySection.vue'
import TokenPRSection from '../components/ecosystemPage/tokenPRSection.vue'
export default {
    name: 'EcosystemPage',
    components: {
        FirstScreen,
        GameFund,
        EcologySection,
        TravelSection,
        ApplySection,
        DownloadSection,
        ThirdPartySection,
        TokenPRSection
    }
}
</script>