<template>
  <div class="news-main-section">
    <div class="news-container">
      <div class="news-buttons">
        <div
          v-for="(item, index) in translatedDataArr"
          :key="index"
          class="news-button"
          :class="{ 'news-active-btn': activeIndex === index }"
          @click="changeTab(index)"
        >
          <p>{{ item }}</p>
        </div>
      </div>

      <Loader v-if="isLoading" :visible="isLoading" class="loading" />

      <div v-if="total >= 0">
        <div v-if="!newsArr.length">{{ noDataMessage }}</div>
        <div v-else class="news-grid">
          <div v-for="(item, index) in newsArr" :key="index" class="news-item">
            <div class="news-image" @click="openModal(item)">
              <img v-lazy="{ src: item.coverImg, loading: '/images/blurredNews.webp', error: '/images/junkNews.png' }">
            </div>

            <div class="news-content">
              <div class="news-title">
                <h5>{{ item.title }}</h5>
              </div>

              <div class="news-meta">
                <div class="meta-text">
                  <p>{{ $t('news') }}</p>
                </div>
                <div class="meta-date">
                  <p>{{ formatDate(item.createTime) }}</p>
                </div>
              </div>

              <div class="news-description" v-html="sanitizeContent(item.content)"></div>

              <div @click="openModal(item)" class="news-read-more">{{ $t('readMore') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="total > 0" class="pagination">
      <div
        class="pagination-button"
        @click="changePage(pageIndex - 1)"
        :disabled="pageIndex === 1"
        :class="{ 'disabled': pageIndex === 1 }"
      >
        <img class="pagination-icon" src="../../assets/images/icons/array-right.svg" alt="Previous" />
      </div>

      <div
        v-for="page in pages"
        :key="page"
        class="page-number"
        :class="{ 'active-page': page === pageIndex }"
        @click="changePage(page)"
      >
        {{ page }}
      </div>

      <div
        class="pagination-button"
        @click="changePage(pageIndex + 1)"
        :disabled="pageIndex === pageCount"
        :class="{ 'disabled': pageIndex === pageCount }"
      >
        <img class="pagination-icon" src="../../assets/images/icons/array-right.svg" alt="Next" />
      </div>
    </div>

    <NewsModal v-model:visible="modalVisible" :news="selectedNews" />
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { fetchNewsList } from '../../api/api';
import Loader from '../common/loader.vue';
import NewsModal from './newsModal.vue';
import { useI18n } from 'vue-i18n';

import { saveToLocalStorage, getFromLocalStorage } from '../../utils/localStorage';

export default {
  name: 'NewsMainSection',
  components: {
    Loader,
    NewsModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const pageIndex = ref(1);
    const activeIndex = ref(0);
    const dataArr = ref(['ALL', 'NOTICE', 'NEWS']);
    const newsArr = ref([]);

    const total = ref(0); 

    const pageSize = ref(12);
    const noDataMessage = computed(() => t('noDataAvailable'));
    const isLoading = ref(false);
    const filterType = ref('');
    const modalVisible = ref(false);
    const selectedNews = ref({});

    const pageCount = computed(() => Math.ceil(total.value / pageSize.value));  

    const pages = computed(() => {
      const pagerCount = window.innerWidth <= 1200 ? 5 : 7;
      const halfPagerCount = Math.floor(pagerCount / 2);
      let start = Math.max(1, pageIndex.value - halfPagerCount);
      let end = Math.min(pageCount.value, pageIndex.value + halfPagerCount);

      if (start === 1) {
        end = Math.min(pageCount.value, pagerCount);
      } else if (end === pageCount.value) {
        start = Math.max(1, pageCount.value - pagerCount + 1);
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    });

    const translatedDataArr = computed(() => dataArr.value.map(item => t(item.toLowerCase())));

    const changePage = (index) => {
      if (index > 0 && index <= pageCount.value) {
        pageIndex.value = index;
        fetchNews();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const sanitizeContent = (str) => str ? str.replace(/=1=/g, '😉') : '';

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const fetchNews = async () => {
      isLoading.value = true;

      const localStorageKey = 'newsData';
      
      const ttl = parseInt(process.env.VUE_APP_NEWS_CACHE_TTL, 10);

      const cachedData = getFromLocalStorage(localStorageKey); 
      if (cachedData && cachedData[`${filterType.value}_${pageIndex.value}`]) {
        const data = cachedData[`${filterType.value}_${pageIndex.value}`];
        newsArr.value = data.newsList;
        total.value = data.totalCount;
        handleModalFromUrl();
        isLoading.value = false;
        return;
      }

      try {
        const res = await fetchNewsList(pageIndex.value, pageSize.value, filterType.value);
        if (res.success) {
          newsArr.value = res.data.newsList;

          total.value = res.data.totalCount;  

          const dataToSave = {
            ...cachedData,
            [`${filterType.value}_${pageIndex.value}`]: {
              newsList: res.data.newsList,
              totalCount: res.data.totalCount, 
            },
          };
          saveToLocalStorage(localStorageKey, dataToSave, ttl); 
          handleModalFromUrl();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const handleModalFromUrl = () => {
      const newsId = route.params.id;
      if (newsId) {
        const selectedNewsItem = newsArr.value.find(news => news.id == newsId);
        if (selectedNewsItem) {
          openModal(selectedNewsItem);
        }
      }
    };

    const changeTab = (index) => {
      activeIndex.value = index;
      pageIndex.value = 1;
      filterType.value = dataArr.value[index] === 'ALL' ? '' : dataArr.value[index] === 'NOTICE' ? '1' : '2';
      fetchNews();
    };

    const openModal = (news) => {
      selectedNews.value = news;
      modalVisible.value = true;
      resetUrl();
    };

    const resetUrl = () => {
      router.replace({ name: 'news', params: { id: undefined } });
    };

    onMounted(() => {
      fetchNews();
      handleModalFromUrl();
    });

    watch(
      () => route.params.id,
      (newId) => {
        if (newId) {
          const selectedNewsItem = newsArr.value.find(news => news.id == newId);
          if (selectedNewsItem) {
            openModal(selectedNewsItem);
          }
        }
      },
      { immediate: true }
    );

    return {
      pageIndex,
      activeIndex,
      newsArr,
      total,  
      noDataMessage,
      isLoading,
      pageCount,
      pages,
      changePage,
      sanitizeContent,
      formatDate,
      changeTab,
      openModal,
      modalVisible,
      selectedNews,
      translatedDataArr,
    };
  },
};
</script>


<style lang="scss" scoped>
@import '../../assets/style/newsPage/newsMainSection.scss';
</style>
