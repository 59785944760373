<template>
    <header>
        <div class="header-container">
            <div class="header-item">
                <AtmLogo></AtmLogo>
            </div>
            <div class="header-item">
                <HeaderNavList></HeaderNavList>
            </div>

            <div class="header-item">
                <ConnectButton :web3Model =web3Model :authStore = authStore></ConnectButton>
                <LangSelector v-if="showLangModeSelectors"></LangSelector>
                <ModeSelector v-if="showLangModeSelectors"></ModeSelector>
            </div>
        </div>
    </header>
</template>

<script>

import HeaderNavList from '@/components/headerNavListV1.vue'
import ConnectButton from "@/components/common/connectButton.vue"
import LangSelector from '@/components/common/langSelector.vue'
import AtmLogo from '@/components/common/atmLogo.vue'
import ModeSelector from '@/components/common/modeSelector.vue'
export default {
    name: 'Header',
    props: {
        web3Model: Object,
        authStore:Object,
    },

    data() {
        return {
            showLangModeSelectors: window.innerWidth >= 1150,
        }
    },
    components: {
        HeaderNavList,
        AtmLogo,
        ConnectButton,
        LangSelector,
        ModeSelector
    },
    mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      this.showLangModeSelectors = window.innerWidth >= 1151;
    }
  }
    
}
</script>
