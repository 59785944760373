<template>
    <div class="container">
        <h1>i'm Partners page</h1>
    </div>
</template>
<script>
export default {
    name: 'PartnersPage',
    data() { return {} },
    components: {},
}
</script>