<template>
    <div class="network">
        <div v-if="showMask" class="overlay">
            <p>{{ $t('homePage.exploreGalaxy') }}</p>
            <div @click="exploreGalaxy" class="galaxy-btn"></div>
        </div>
        <iframe  src="https://visual.atm.network" allowfullscreen="true" webkitallowfullscreen="true"
            mozallowfullscreen="true" oallowfullscreen="true" frameborder="0"></iframe>
    </div>
</template>
  
<script>
export default {
    name: 'GalaxyComponent',
    data() {
        return {
            showMask: true
        };
    },
    methods: {
        exploreGalaxy() {
            console.log(' exploreGalaxy');
            this.showMask = false;
        }

    }
};
</script>

  