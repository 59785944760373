<template>
    <Section :title="$t('title.atmConnect')" class="atm-connect-section">
        <div class="bg-image">
            <img :src=imageSource alt="bgImg" loading="lazy">
        </div>
        <div class="atm-connect-container">
            <div class="atm-connect-content">
                <div class="large-body-text">
                    <p v-html="$t('helpPage.seamlessConnectivity')"></p>
                </div>

                <div class="download-app-wrapper">
                    <div class="large-body-text">
                        <p>{{ $t('helpPage.getConnent') }}</p>
                    </div>

                    <div class="dapp-button-wrapper">
                        <DappBadgeButton class="google-link" v-for="(badgeButton, index) in buttons" :key="index"
                            :button="badgeButton" />
                    </div>

                </div>

            </div>
            <div class="atm-connect-img">
                <img src="../../assets/images/helpPageImg/atmconnect-img.png" alt="">
            </div>
        </div>
    </Section>
</template>

<script>
import Section from '../../components/common/section.vue';
import DappBadgeButton from '@/components/common/dappBadge.vue'
import useDarkMode from '@/darkMode';
export default {
    name: 'ATMconnect',
    components: {
        Section,
        DappBadgeButton
    },
    data() {
        return {
            buttons: [
                { key: 'downloadAndroid', image: 'images/app/google-badge.png', href: 'https://play.google.com/store/apps/details?id=network.atm.atmconnect&hl=en_GB&gl=US' },
                { key: 'downloadIOS', image: 'images/app/apple-badge.svg', href: 'https://apps.apple.com/gb/app/atm-connect/id6463245714 ' }
            ],
        }
    },
    setup() {
        const { isDark } = useDarkMode();
        return {
            isDark,
        };
    },
    computed: {
        imageSource() {
            return this.isDark
                ? '/images/helpPage/atmconnect-dark.svg'
                : '/images/helpPage/atmconnect-bg.svg';
        }
    }

};
</script>

<style lang="scss" scoped>
@import '@/assets/style/helpPage/atmConnect.scss';
</style>
