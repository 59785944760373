import { useDark, useToggle } from '@vueuse/core';

export default function () {
  const isDark = useDark();
  const toggleDark = useToggle(isDark);

  return { isDark, toggleDark};
}

// Detect if dark mode is enabled
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
const darkModeToggle = document.querySelector('.dark-mode');

function handleDarkModeChange(e) {
  if (e.matches) {
    document.body.classList.add('dark-mode');
  } else {
    document.body.classList.remove('dark-mode');
  }
}

handleDarkModeChange(darkModeMediaQuery);
darkModeMediaQuery.addListener(handleDarkModeChange);
