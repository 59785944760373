<template>
  <div>
    <component :is="layoutComponent"></component>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/AppLayoutDefault";
import { useWeb3ModalEvents } from "@web3modal/wagmi/vue";
import { useBlockChainStore } from '@/store/blockchain'

import { useAuthStore } from '@/store/auth'


export default {
  name: "AppLayout",
  computed: {
    layoutComponent() {
      // const url = window.location.hash;
      // console.log("layoutComponent from ...", this.$route.meta)
      // console.log("layoutComponent url from ...", url)
      // // get the router object form hash

      // if (this.$route.meta.layoutComponent === undefined) {
      //   return DefaultLayout;
      // }

      return this.$route.meta.layoutComponent;
    }
  },
  data() {
    return {
      isConnected: false,
      events: useWeb3ModalEvents(),
      blockChainStore: useBlockChainStore(),
      // authStore:useAuthStore()
    };

  },
  watch: {
    '$route.meta.layoutComponent': {
      handler(newValue, oldValue) {
        console.log(`Layout component changed from ${oldValue} to ${newValue}`);
        // console.log(events)
        // console.log(" this.authStore.isConnected");
        // console.log( this.authStore.isConnected);

        if (newValue === undefined) {
          console.log("undefined")
        }
        // Perform any additional logic you need when layoutComponent changes
      },
      immediate: true
    }
  },
}
</script>

<style scoped></style>
