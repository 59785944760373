<template>
    <Section :title="$t('title.faq')" class="faq-section">
        <div class="faq-container">
            <div class="sidebar">

                <div class="body-text" v-for="(title, index) in localizedFAQTitles" :key="index"
                    @click="selectTitle(index)" >
                    <p :class="{ selected: index === selectedTitleIndex }"> {{ title }}</p>
                </div>

            </div>

            <div class="content">
                <div v-if="localizedSelectedFAQ.length > 0">
                    <div v-for="(qa, index) in localizedSelectedFAQ" :key="index" class="faq-item">
                        <div class="regular-body-text">
                            <p class="question-label">Q:</p>
                            <p>{{ qa.question }}</p>
                        </div>
                        <div class="small-body-text">
                            <p class="answer-label">A:</p>
                            <p v-html="qa.answer"></p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>{{ $t('faq.selectCategory') }}</p>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import Section from '../../components/common/section.vue';

export default {
    name: 'Connect',
    components: {
        Section,
    },
    data() {
        return {
            selectedTitleIndex: 0,
        };
    },
    computed: {
        faqData() {
            return this.$tm('faq');
        },
        localizedFAQTitles() {
            return this.faqData.problemTitle.map(titleKey => this.$t(titleKey));
        },
        localizedSelectedFAQ() {
            const keys = ['qaATM', 'qaLuca', 'qaCommity', 'qaZhili'];
            return this.faqData[keys[this.selectedTitleIndex]].map(qa => ({
                question: this.$t(qa.question),
                answer: this.$t(qa.answer)
            }));
        },
    },
    methods: {
        selectTitle(index) {
            this.selectedTitleIndex = index;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/helpPage/faq.scss';
</style>