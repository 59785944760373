<template>
    <Section :title="$t('title.connect')" class="connect-section">

        <div class="cards-buttons">
            <ControlButtons v-show="mobile" :disableLeft="isListAtStart" :disableRight="isListAtEnd"
                @switch-element="switchElement" />
        </div>
        <div class="cards-outer" ref="connectCards" @scroll="checkListPosition">
            <div class="connect-container">
                <div class="step-card">
                    <div class="step-title">
                        <div class="large-body-text">
                            <p class="number">1</p>
                            <p>{{ $t('helpPage.installingWallet') }}</p>
                        </div>
                    </div>

                    <div class="step-card-content">
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.needConnectedWallet') }}</p>
                            <p>{{ $t('helpPage.downLoadMetaMask') }}</p>
                            <p>{{ $t('helpPage.followMetaMaskSetup') }}</p>
                        </div>
                        <DappButton :button="metaMaskBtn" />

                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.onceCompleteClickButton') }}</p>
                        </div>
                        <DappButton :button="BSCnetworkBtn" />
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.reopenWebpage') }}</p>
                            <p>{{ $t('helpPage.recommendAddCurrencies') }}</p>
                        </div>
                        <div class="currencies">
                            <DappButton v-for="(stepButton, index) in localizedDataList" :key="index"
                                :button="stepButton" />
                        </div>
                    </div>
                </div>


                <div class="step-card">
                    <div class="step-title">
                        <div class="large-body-text">
                            <p class="number">2</p>
                            <p>{{ $t('helpPage.connectingATM') }}</p>
                        </div>
                    </div>
                    <div class="step-card-content">
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.clickConnect') }}</p>
                            <p>{{ $t('helpPage.totallySecure') }}</p>
                        </div>
                        <div class="body-text">
                            <p>{{ $t('helpPage.dashboardHasInformation') }}</p>
                            <p>{{ $t('helpPage.beginBuildingNetwork') }}</p>
                        </div>
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.beginYourJourney') }}</p>
                        </div>
                        <ConnectButton />
                    </div>
                </div>


                <div class="step-card">
                    <div class="step-title">
                        <div class="large-body-text">
                            <p class="number">3</p>
                            <p>{{ $t('helpPage.fundingWallet') }}</p>
                        </div>
                    </div>
                    <div class="step-card-content">
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.gotNewWallet') }}</p>
                            <p>{{ $t('helpPage.canBuyBNB') }}</p>
                            
                        </div>
                        <div class="moonpay-wrapper">
                            <div class="moonpay-img">
                                <img src="/images/helpPage/icons/moonpay.svg" alt="">
                            </div>
                            <div class="body-text">
                                <a href="https://www.moonpay.com/en-gb" target="_blank"><p>{{ $t('helpPage.buyWithMoonpay') }}</p></a>
                            </div>
                            <div class="small-body-text">
                                <p>{{ $t('helpPage.supportsPaymentMethods') }}</p>
                            </div>
                        </div>
                        <div class="regular-body-text">
                            <p>{{ $t('helpPage.buyFromBinance') }}</p>
                            <p>{{ $t('helpPage.swapToLuca') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="connect-text">
            <div class="text-xs">
                <p>{{ $t('helpPage.metaMaskSuggestedWallet') }}</p>
            </div>
        </div>
    </Section>
</template>

<script>
import Section from '../../components/common/section.vue';
import DappButton from '../common/dappButton.vue';
import ConnectButton from '../common/connectButton.vue';
import ControlButtons from '../homePage/controlButtons.vue';
export default {

    name: 'Connect',
    components: {
        Section,
        DappButton,
        ConnectButton,
        ControlButtons,
    },
    data() {
        return {
            isListAtStart: true,
            isListAtEnd: false,
            mobile: null,
            metaMaskBtn: {
                href: 'https://metamask.io/download.html',
                image: 'images/helpPage/icons/metamask.svg',
                label: this.$t('helpPage.getMetaMask')
            },
            BSCnetworkBtn: {
                href: 'v1/#/',
                image: 'images/helpPage/icons/binance-icon.svg',
                label: this.$t('helpPage.addBSCnetwork')
            },
            buttons: [
                { key: 'LUCA', image: 'images/helpPage/icons/luca-icon.svg', href: 'v1/#/' },
                { key: 'AGT', image: 'images/helpPage/icons/coin-icon.svg', href: 'v1/#/' },
                { key: 'USDC', image: 'images/helpPage/icons/usd-coin.svg', href: 'v1/#/' }
            ],
        }
    },

    computed: {
        localizedDataList() {
            return this.buttons.map(item => ({
                ...item,
                label: this.$t(item.key),
            }));
        },
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },

    methods: {
        checkMetaMask() {
            if (typeof window.ethereum !== 'undefined') {
                console.log('MetaMask is installed!');
                this.metaMaskBtn.href = 'https://metamask.io';
                this.metaMaskBtn.label = this.$t('helpPage.metaMask');
            }
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            this.mobile = this.windowWidth <= 1250;
        },
        checkListPosition() {
            const connectCards = this.$refs.connectCards;
            this.isListAtStart = connectCards.scrollLeft === 0;
            this.isListAtEnd = connectCards.scrollLeft + connectCards.clientWidth >= connectCards.scrollWidth;
        },
        switchElement(direction) {
            if (direction === 'left') {
                this.scrollLeft();
            } else {
                this.scrollRight();
            }
        },
        scrollLeft() {
            const connectCards = this.$refs.connectCards;
            connectCards.scrollBy({
                left: -440,
                behavior: 'smooth',
            });
            setTimeout(this.checkListPosition, 300);
        },
        scrollRight() {
            const connectCards = this.$refs.connectCards;
            connectCards.scrollBy({
                left: 440,
                behavior: 'smooth',
            });
            setTimeout(this.checkListPosition, 300);
        },


    },
    mounted() {
        this.checkListPosition();
        this.checkMetaMask();
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/helpPage/connect.scss';
</style>